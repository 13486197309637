import React, { useEffect, useState } from 'react';
import { FaArrowCircleRight } from 'react-icons/fa';
import { string } from 'yup';
import { useModal } from '../../../../../../hooks/Modal';
import { useSearch } from '../../../../../../hooks/Search';
import { useToast } from '../../../../../../hooks/Toast';
import api from '../../../../../../services/api';
import { Container } from './style';
interface IProps {
    data: Record<string, any>
}

const ReviewAvaliadores: React.FC<IProps> = ({ data }) => {
    const { addToast } = useToast();
    const { removeModal } = useModal();
    const { reloadSearchAll } = useSearch();
    const [submission, setSubmission] = useState<Record<string, any>>();
    const [submissionData, setSubmissionData] = useState<Record<string, any>>();
    const categoriesSession = submission?.available_blocks?.find(block => block?.hash === 'categories');
    const extraCategoriesSession = submission?.available_blocks?.find(block => block?.hash === 'extra_categories');
    const loadSubmission = async () => {


        const response = await api.get(`/cientific-selection/${data?.cientific_selective_id_response?._id}`);

        if (response?.data) {
            setSubmission(response?.data);

        }

        const avaliator = await api.get(`/cientific-avaliators/${data?._id?.toString()}`);

        if (avaliator?.data) {
            setSubmissionData(avaliator?.data);

        }

    }

    useEffect(() => {
        loadSubmission();
    }, [data])


    const changeStatus = async (id, status) => {
        try {
            const response = await api.put(`/cientific-avaliators/${id}/status`, { status });

            if (response) {

                reloadSearchAll();
                addToast({ title: 'Atualizado com sucesso', type: 'success' });
                return removeModal('avaliator');
            }
            return;
        }
        catch (err) {
            console.log(err);
            return addToast({ title: err?.response?.data?.message || err?.response?.data?.error || 'Erro ao atualizar', type: 'success' });
        }
    }

    const box = (dataInfo, key = '') => {
        if (!dataInfo) {
            return <></>
        }
        return <>
            {dataInfo.map(d => <aside style={{ background: '#eee', padding: '5px', margin: '5px', color: '#333', width: 'auto' }}>{key === 'self' ? d || '' : d?.[key] || ''}</aside>)}
        </>
    }

    return <Container>
        <h1>Revisão de dados</h1>

        <h2>1 - Dados do Avaliador</h2>
        <strong>Nome completo</strong><p>{submissionData?.name}</p>
        <strong>CPF</strong><p>{submissionData?.document_number}</p>


        <h2>1.2 - Dados de acesso</h2>
        <strong>Email</strong><p>{data?.email}</p>
        {submission?.avaliator_is_orientating ? <>
            <h2>Informações adicionais</h2>
            <strong>{submission?.avaliator_is_orientating}</strong><p>{submissionData?.is_orientating}</p>
        </> : <></>}

        {data?.category ? <><h2>Categoria</h2>
            <strong>Categoria</strong><p>{submissionData?.category}</p> </> : <></>}


        {submission?.avaliator_ask_lattes === 'yes' ? <><strong>Link do Currículo Lattes</strong><p>{submissionData?.lattes}</p></> : <></>}


        {submissionData?.categories ? <>
            <h2>{categoriesSession?.avaliator_title || categoriesSession?.title}</h2>
            {submissionData?.categories?.map(categoryValue => {
                console.log('valor da categoria', categoryValue)
                return <aside style={{ background: '#eee', padding: '5px', margin: '5px', color: '#333', width: 'auto' }}>{categoryValue}</aside>
            })}
        </> : <></>
        }

        {submissionData?.extra_categories?.length > 0 ? <>
            <h2>{extraCategoriesSession?.avaliator_title || extraCategoriesSession?.title}</h2>
            {submissionData?.extra_categories?.map(extraCategoryValue => {
                return <aside style={{ background: '#eee', padding: '5px', margin: '5px', color: '#333', width: 'auto' }}>{extraCategoryValue}</aside>
            })}
        </> : <></>
        }

        <h2>Horários</h2>

        {submissionData?.hours?.map(hourValue => {
            return <aside style={{ background: '#eee', padding: '5px', margin: '5px', color: '#333', width: 'auto' }}>{hourValue}</aside>
        })}


        <br />
        <button onClick={() => changeStatus(data._id, 'Confirmado')} style={{ width: '150px', margin: '10px auto' }} className="defaultButton">Aprovar</button> <button style={{ width: '150px' }} className="defaultButtonRed" onClick={() => changeStatus(data._id, 'Não aprovado')}>Rejeitar</button>
    </Container>
}

export default ReviewAvaliadores;