import React from 'react';
import { FaEye, FaIntercom, FaRadiationAlt, FaReceipt } from 'react-icons/fa';

import { useModal } from '../../../../../hooks/Modal';

import api from '../../../../../services/api';

interface ICreateModule {
  lineData: Record<string, any>;
}

export default function ReviewModule({ lineData }): JSX.Element {

  const { addModal } = useModal();


  const openReviewComplete = async () => {

    const items = await api.get('/cientific-submissions', { params: { where: { orientator_approval: 'Sim', cientific_selective_id: lineData?._id?.toString() }, limitItems: 10000 } })
    items?.data?.rows?.sort((a, b) => a?.title < b.title ? -1 : a?.title > b?.title ? 1 : 0)
    const contentInfo: Array<JSX.Element> = [];
    items.data?.rows?.map(item => {

      let autors = `${item?.author}`;

      item?.co_authors?.map(coauthor => {

        const index = autors.indexOf(coauthor?.name);
        if (index < 0) {
          autors = `${autors}, ${coauthor?.name}`;
        }

      })

      if (autors?.indexOf(item?.orientator) < 0) {
        autors = `${autors}, ${item?.orientator}`;
      }



      let extra_categories = ``;
      item?.extra_categories?.map(cat => {
        if (cat) {
          if (extra_categories?.length > 1) {
            extra_categories = `${extra_categories}, ${cat}`;
          }
          else {
            extra_categories = `${cat}`;
          }
        }
      })

      let keywords = ``;
      item?.keywords?.map(cat => {
        if (cat?.keyword) {
          if (keywords?.length > 1) {
            keywords = `${keywords}, ${cat?.keyword}`;
          }
          else {
            keywords = `${cat?.keyword}`;
          }
        }
      })

      contentInfo.push(<div style={{ padding: '30px 30px', breakAfter: 'always', marginBottom: '15px' }}>

        <div style={{ padding: '10px', marginTop: '10px', width: '100%' }}>
          <h2  >{item?.title}</h2>


          <h2 style={{ color: "#000" }}>Processo: </h2><p>
            {item.cientific_selective_id_response?.title}   </p>
          {item.theme ? <> <h2 style={{ color: "#000" }}>Categoria:</h2><p>
            {item.theme}    </p>  </> : <></>}
          {item.category ? <> <h2 style={{ color: "#000" }}>Categoria:</h2><p>
            {item.category}     </p></> : <></>}
          {item.area ? <><h2 style={{ color: "#000" }}>Área:</h2><p>
            {item.area}     </p></> : <></>}
          {item.subarea ? <> <h2 style={{ color: "#000" }}>Subárea:</h2><p>
            {item.subarea}    </p> </> : <></>}
          {extra_categories ? <><h2 style={{ color: "#000" }}>ODS:</h2><p>
            {extra_categories} </p>   </> : <></>}

          <h2 style={{ color: "#000" }}>Autores: </h2><p>{autors}</p>
          <h2 style={{ color: "#000" }}>Instituição do autor</h2> <p>{item?.author_institution}</p>



          <h2 style={{ color: "#000" }}>Palavras-chave:</h2>
          <p> {keywords}</p>

          <h2 style={{ color: "#000" }}>Resumo:</h2>
          <p dangerouslySetInnerHTML={{ __html: item.short_description }} />
          <br /><br />

        </div>
      </div >)


    })

    addModal({ title: '', content: <>{contentInfo}</>, theme: 'whiteModal' })


  }

  const moduleCreate = (): JSX.Element => {

    return <>

      <div onClick={() => openReviewComplete()} title="Anais com autores" style={{ cursor: 'pointer', width: '30px', height: '30px', display: 'flex', alignItems: 'center', justifyContent: 'center', borderRadius: '50%', border: '1px solid #333', margin: '5px' }}>
        <FaReceipt className="hover" style={{ cursor: 'pointer', color: 'rgb(0,100,150)' }} size={20} /></div>
    </>
  }



  return moduleCreate();
}
