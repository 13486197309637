import React, { useCallback, useRef, useEffect, useState } from 'react';

import { IconType } from 'react-icons/lib';
import { Link, useParams } from 'react-router-dom';
import { FiChevronRight, FiChevronLeft } from 'react-icons/fi';
import api from '../../services/api';
import { date, hour, dateLanguage, simpleDate } from '../../utils/date';
import { Container } from './styles';
import { addHours } from 'date-fns';
import { useToast } from '../../hooks/Toast';
import { Form } from '@unform/web';

import { string } from 'yup';

import { useLanguage } from '../../hooks/Language';
import { FaArrowDown, FaArrowUp } from 'react-icons/fa';
import { MdKeyboardArrowDown, MdKeyboardArrowUp } from 'react-icons/md';
import Favorite from '../../components/ClassRoomContainer/modules/Favorite';
import { useAuth } from '../../hooks/Auth';
import { useConfiguration } from '../../hooks/Configuration';
import { defaultProjectHash, hash, urlWebsite } from '../../config';
import { loadFromJson } from '../../utils/loadFromJson';
import Input from '../../components/Forms/Input';
import VisualizePDF from './modules/VisualizePDF';

interface IOption {
  value: string;
  label: string;
}

interface IProgram {
  date: Date;
  formatted_date?: string;
  start_hour: Date;
  formatted_start_hour?: string;
  end_date: Date;
  formatted_end_date?: string;
  title: string;
  category: string;
  room_reference_id_response: Record<string, any>;
  description: string;
}

interface IProgramFilter {
  date: Date;
  formatted_date: string;
  program: Array<IProgram>;
  simpleDate: string;
  day_reference?: string;
  category?: string;
  room_name?: string;
}

interface IProps {
  filterCategory?: string;
  removeCategory?: string;
}
const PaperRoomVisualization: React.FC<IProps> = () => {
  const { projectHash = defaultProjectHash } = useParams<Record<string, any>>();
  const [paperStorage, setPaperStorage] = useState<Array<Record<string, any>>>([]);
  const [search, setSearch] = useState('');

  const [currentPaperId, setCurrentPaperId] = useState<string>('');

  const currentPaper = paperStorage?.find(item => item?._id?.toString() === currentPaperId);


  const papers = search === '' ? paperStorage : paperStorage?.filter(item => {

    const searchItem = `${item?.title} ${item?.author} ${item?.nested_co_authors} ${item?.date} ${item?.hour} ${item?.room} ${item?.section} ${item?.orientator} ${item?.theme} ${item?.totem} ${item?.poster_id}`.toLowerCase();

    return searchItem?.indexOf(search?.trim()?.toLowerCase()) >= 0;

  })



  const load = async () => {
    const response = await api.get(`/search-paper-room/${projectHash}`);


    const items: Array<Record<string, any>> = [];

    response?.data?.rows?.map(paper => {
      if (paper?.presentation_image) {
        paper.title = paper?.title?.trim();
        items.push(paper);
      }

    })

    items.sort((a, b) => a?.title > b?.title ? 1 : a?.title < b?.title ? -1 : 0)


    if (items) {
      setPaperStorage(items);
    }
  }

  useEffect(() => {

    if (projectHash) {
      load();
    }


  }, [projectHash])

  const handleSearch = (data) => {

    setSearch(data?.search);

  }

  const visualize = (paper) => {
    setCurrentPaperId(paper?._id?.toString());



  }

  return (
    <>
      {currentPaper?._id?.toString() ? <VisualizePDF image={`${urlWebsite}/${currentPaper?.presentation_image_converted}`} setCallBack={() => { setCurrentPaperId('') }} /> : <></>}

      <Container id="news" style={{ background: '#fff' }}>
        <h1 style={{ color: '#1d436f', fontSize: '24px', textAlign: 'center', marginTop: '40px', marginBottom: '20px' }}>{'Pôsteres'} </h1>

        <Form onSubmit={handleSearch} style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '15px' }}>

          <Input
            style={{ borderRadius: '10px', minWidth: '200px' }}
            name="search"
            placeholder="Pesquise Aqui"
            type="text"
          />

          <button className="buttonBigGreen" style={{ borderRadius: '10px', maxWidth: '120px' }} type="submit">Pesquisar</button>

        </Form>

        <div >
          <table className='table'>

            <tbody>
              {papers?.map(paper => {
                return <tr key={paper?._id?.toString()}>
                  <td>
                    <strong title={paper?.cientific_selective_id_response?.title} style={{ color: '#333', fontSize: '12px', marginBottom: '5px', display: 'block' }}>{paper?.cientific_selective_id_response?.title}</strong>
                    <strong title={'Título'} style={{ color: '#1c9284', marginBottom: '5px', display: 'block' }}>{paper?.title}</strong>
                    {paper?.author ? <p title='Autor' style={{ color: '#333' }}><strong style={{ color: '#333' }}>Autor:</strong> {paper?.author}</p> : <></>}
                    {paper?.nested_co_authors ? <p title="Coautores" style={{ color: '#333' }}><strong style={{ color: '#333' }}>Coautores:</strong> {paper?.nested_co_authors}</p> : <></>}
                    {paper?.orientator ? <p title='Orientador' style={{ color: '#333' }}><strong style={{ color: '#333' }}>Orientador:</strong> {paper?.orientator}</p> : <></>}
                    {paper?.room ? <p title='Sala e Seção' style={{ color: '#333' }}><strong style={{ color: '#333' }}>Sala - Prédio:</strong> {paper?.room} | <strong style={{ color: '#333' }}>Seção:</strong> {paper?.section}</p> : <></>}
                    {paper?.date ? <p style={{ color: '#333' }}><strong style={{ color: '#333' }}>Data:</strong> {paper?.date} - {paper?.hour}</p> : <></>}
                    {paper?.theme ? <p style={{ color: '#333' }}><strong style={{ color: '#333' }}>Temática:</strong> {paper?.theme}</p> : <></>}
                    {paper?.totem ? <p style={{ color: '#333' }}><strong style={{ color: '#333' }}>Totem:</strong> {paper?.totem}</p> : <></>}
                    {paper?.poster_id ? <p style={{ color: '#333' }}><strong style={{ color: '#333' }}>ID do Pôster:</strong> {paper?.poster_id}</p> : <></>}
                    <button className='buttonBigGreen' type='button' onClick={() => visualize(paper)}>Visualizar</button>
                  </td>
                </tr>
              })}


            </tbody>
          </table>
        </div>



      </Container >

    </>
  );
};
export default PaperRoomVisualization;
