import React from 'react';

import {
  SearchComponent,
  ISearchComponent,
} from '../../../../core/components/SearchComponent';

import lineModules from '../../../../core/components/lineModules'
import { Container } from './styles';
import { useSocket } from '../../../../../../hooks/Socket';
import { Button } from '../../../../core/components/Forms/Button/styles';

import sendPoster from './components/UpdateUnique';
import sendCorrection from './components/Update';

import review from '../../dashboardPages/CientificSubmission/modules/Review';
import editPaper from '../../dashboardPages/CientificSubmission/modules/EditPaper';
import update from '../../dashboardPages/CientificSubmission/modules/Update';
import { FaImage } from 'react-icons/fa';

interface ICreateModule {
  lineData: Record<string, any>;



}

interface ValidationRules {
  type: 'email' | 'required' | 'min' | 'max' | 'length';
  message?: string;
  quantity?: number;
}

interface ValidationSchema {
  name: string;
  type: 'string' | 'number' | 'boolean';
  label: string;
  rules: Array<ValidationRules>;
}

interface ILine {
  [key: string]: string;
}

const MyPapers: React.FC = () => {
  const endpoint = '/my-cientific-papers';
  const { socket } = useSocket();
  const title = 'Meus trabalhos';


  const schema: Record<string, any> = {
    _id: { ref: '_id', column: '_id', label: 'ID' },
    project_id: { ref: 'project_id', column: 'project_id', label: 'Projeto', show: false },
    hash: { ref: 'hash', column: 'hash', label: 'Hash', show: false },
    cientific_selective_id: {
      ref: 'cientific_selective_id',
      column: 'cientific_selective_id_response',
      includeColumn: 'title',
      label: 'Projeto',
    },

    room: { ref: 'room', column: 'room', label: 'Sala' },
    section: { ref: 'section', column: 'section', label: 'Seção' },
    date: { ref: 'date', column: 'date', label: 'Data' },
    hour: { ref: 'hour', column: 'hour', label: 'Hora' },


    author: { ref: 'author', column: 'author', label: 'Autor' },
    author_institution: { ref: 'author_institution', column: 'author_institution', label: 'Instituição do autor', show: false },
    author_email: { ref: 'author_email', column: 'author_email', label: 'Email do autor', show: false },
    author_document_number: { ref: 'author_document_number', column: 'author_document_number', label: 'CPF do autor', show: false },
    co_authors: { ref: 'co_authors', column: 'co_authors', label: 'Coautores', show: false },
    nested_co_authors: { ref: 'nested_co_authors', column: 'nested_co_authors', label: 'Coautores', show: false },
    orientator: { ref: 'orientator', column: 'orientator', label: 'Orientador', show: false },
    orientator_institution: { ref: 'orientator_institution', column: 'orientator_institution', label: 'Instituição do Orientador', show: false },
    orientator_email: { ref: 'orientator_email', column: 'orientator_email', label: 'Email do Orientador', show: false },
    orientator_document_number: { ref: 'orientator_document_number', column: 'orientator_document_number', label: 'CPF do Orientador', show: false },
    title: { ref: 'title', column: 'title', label: 'Título' },
    short_description: { ref: 'short_description', column: 'short_description', label: 'Resumo', show: false },
    area: { ref: 'area', column: 'area', label: 'Área', show: false },
    category: { ref: 'category', column: 'category', label: 'Categoria' },
    subarea: { ref: 'subarea', column: 'subarea', label: 'SubÁrea', show: false },
    keywords: { ref: 'keywords', column: 'keywords', label: 'Palavras-chave', show: false },
    presentation_time: { ref: 'presentation_time', column: 'presentation_time', label: 'Turno de apresentação', show: false },
    have_agency: { ref: 'have_agency', column: 'have_agency', label: 'O trabalho tem financiamento de agência de fomento?', show: false },
    agency: { ref: 'agency', column: 'agency', label: 'Agência de fomento', show: false },
    observations: { ref: 'observations', column: 'observations', label: 'Correção', show: false },
    status: { ref: 'status', column: 'status', label: 'Status', show: false },
    status_name: { ref: 'status_name', column: 'status_name', label: 'Status', show: false },
    approval_notes: { ref: 'approval_notes', column: 'approval_notes', label: 'Observações', show: false },
    presentation_image: { ref: 'presentation_image', column: 'presentation_image', label: 'Pôster', show: false },
    orientator_approval: { ref: 'orientator_approval', column: 'orientator_approval', label: 'Trabalho aprovado?' },
    orientator_correction: { ref: 'orientator_correction', column: 'orientator_correction', label: 'Correção solicitada pelo orientador', show: false },

    createdAt: {
      ref: 'created_at',
      column: 'createdAt',
      label: 'Data de Criação',
      mask: 'date',
    },
  };

  /*
   (lineData: Record<string, any>) =>
            update({
              lineData,
           
            }),
  
            */
  const endpoint2 = '/cientific_upload_poster';

  const formSchema = {

    presentation_image: {
      model: 'uploadImage',
      type: 'upload',
      name: 'presentation_image',
      label: 'Pôster (Max. 1mb)',

      endpoint: endpoint2,
    },

  };

  const formSchemaCorrection = {

    title: {
      model: 'input',
      name: 'title',
      label: 'Título',
      defaultValue: '',
    },
    short_description: {
      model: 'richText2',
      name: 'short_description',
      label: 'Resumo',
      defaultValue: '',
    },
    keywords: {
      model: 'jsonListMultiple',
      type: 'text',
      name: 'keywords',
      label: 'Palavras-chave',
      list: {
        title: { label: 'Palavra-chave', name: 'title' },

      },
    },

  };

  const config2: ISearchComponent = {
    idKey: endpoint,
    title: 'Meus trabalhos',
    endpoint: endpoint,
    schema: schema,
    autoStart: true,
    headModules: [],
    bodyModules: [
      (lineData: Record<string, any>) =>
        review({
          lineData,

        }),
      (lineData: Record<string, any>) =>
        editPaper({
          lineData,

        }),

      (lineData: Record<string, any>) =>
        sendCorrection({
          lineData,
          title: 'Enviar correção',
          formSchema: formSchemaCorrection,
          validationSchema: {},
          endpoint: endpoint2,

        }),

      /*   (lineData: Record<string, any>) =>
          sendPoster({
            lineData,
            title: 'Adicionar PDF do Pôster',
            formSchema: formSchema,
            validationSchema: {},
            endpoint: endpoint2,
            Icon: FaImage,
            moduleTitle: 'Adicionar PDF do Pôster'
          }), */


    ],

  };

  {/*, */ }

  return (
    <Container>

      <SearchComponent {...config2} />
    </Container>
  );
};

export default MyPapers;
