import React from 'react';

import {
  SearchComponent,
  ISearchComponent,
} from '../../../../core/components/SearchComponent';

import lineModules from '../../../../core/components/lineModules'
import { Container } from './styles';
import { useSocket } from '../../../../../../hooks/Socket';
import { Button } from '../../../../core/components/Forms/Button/styles';

import review from './modules/Review';
import update from './modules/Update';

interface ICreateModule {
  lineData: Record<string, any>;



}

interface ValidationRules {
  type: 'email' | 'required' | 'min' | 'max' | 'length';
  message?: string;
  quantity?: number;
}

interface ValidationSchema {
  name: string;
  type: 'string' | 'number' | 'boolean';
  label: string;
  rules: Array<ValidationRules>;
}

interface ILine {
  [key: string]: string;
}

const CientificMonitor: React.FC = () => {
  const endpoint = '/cientific-monitors';
  const { socket } = useSocket();
  const title = 'Monitores';


  const schema: Record<string, any> = {
    _id: { ref: '_id', column: '_id', label: 'ID' },
    cientific_selective_id: {
      ref: 'cientific_selective_id',
      column: 'cientific_selective_id_response',
      includeColumn: 'title',
      label: 'Processo',
    },
    project_id: { ref: 'project_id', column: 'project_id', label: 'Projeto', show: false },
    name: { ref: 'name', column: 'name', label: 'Nome' },
    email: { ref: 'email', column: 'email', label: 'Email' },
    document_number: { ref: 'document_number', column: 'document_number', label: 'CPF' },
    company: { ref: 'company', column: 'company', label: 'Instituição' },
    nested_hours: { ref: 'nested_hours', column: 'nested_hours', label: 'Horários' },
    createdAt: {
      ref: 'created_at',
      column: 'createdAt',
      label: 'Data de Criação',
      mask: 'date',
    },
  };


  const formSchema = {

    status: {
      alias: 'status',
      model: 'selectApi',
      name: 'status',
      label: 'Status',

      endpoint,
    },

    name: {
      model: 'input',
      name: 'name',
      label: 'Nome',
    },

    email: {
      model: 'input',
      name: 'email',
      label: 'Email',
    },
    document_number: {
      model: 'input',
      name: 'document_number',
      label: 'CPF',
    },

  };

  const formSchemaUpdate = {
    ...formSchema,

  };



  const formValidation: Record<string, ValidationSchema> = {

  };


  function updateLessonModule({

    lineData,


  }: ICreateModule): JSX.Element {

    const clearLessonCache = () => {
      socket?.emit('clearChatCache', { room_id: lineData.id });
    }

    return <Button style={{ padding: '5px' }} onClick={() => clearLessonCache()}>Limpar Cache</Button>

  }




  const config2: ISearchComponent = {
    idKey: endpoint,
    title: 'Monitores',
    endpoint: endpoint,
    schema: schema,
    autoStart: true,
    headModules: [

      () =>
        lineModules.create({
          title,
          formSchema,
          validationSchema: formValidation,
          endpoint,
        }),
    ],
    bodyModules: [
      (lineData: Record<string, any>) =>
        lineModules.updateUnique({
          lineData,
          title,
          formSchema,
          validationSchema: formValidation,
          endpoint,

        }),



      (lineData: Record<string, any>) =>
        lineModules.delete({
          lineData,
          title,
          formSchema,
          validationSchema: formValidation,
          endpoint,
        }),
    ],
  };

  return (
    <Container>

      <SearchComponent {...config2} />
    </Container>
  );
};

export default CientificMonitor;
