import React from 'react';
import * as Yup from 'yup';
import { FormHandles } from '@unform/core';
import uuid from '../../../core/components/UniqueHash';
import { useModal } from '../../../../../hooks/Modal';

import api from '../../../../../services/api';
import { useToast } from '../../../../../hooks/Toast';
import { useSearch } from '../../../../../hooks/Search';

import { FaCopy, FaPlus, FaUpload } from 'react-icons/fa';
import { useParams } from 'react-router-dom';

interface ICreateModule {
    lineData: Record<string, any>;
}

interface IParams {
    projectHash: string;
    eventHash: string;
}

export default function CreateModule({
    lineData
}: ICreateModule): JSX.Element {
    const { reloadSearchAll } = useSearch();
    const { addModal, removeModal } = useModal();
    const { addToast } = useToast();
    const { projectHash, eventHash } = useParams<IParams>()
    const key = uuid();

    const handleCreate = async () => {

        const currentValue = await api.get(`/course-products/${lineData?._id}`);

        const newData = { ...currentValue?.data }

        delete newData._id;
        delete newData.hash;

        await api.post(`/course-products`, newData);
        reloadSearchAll();
        addToast({ title: 'Duplicado com sucesso', type: 'success' })


    };

    const moduleCreate = (): JSX.Element => (
        <button className="lineIcon" title='Duplicar' type="button" onClick={handleCreate}>
            <FaCopy />
        </button>
    );

    return moduleCreate();
}
