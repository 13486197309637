import React from 'react';

import {
  SearchComponent,
  ISearchComponent,
} from '../../../../core/components/SearchComponent';

import lineModules from '../../../../core/components/lineModules'
import { Container } from './styles';
import { useSocket } from '../../../../../../hooks/Socket';
import { Button } from '../../../../core/components/Forms/Button/styles';

import review from '../../dashboardPages/CientificSubmission/modules/Review';
import update from '../../dashboardPages/CientificSubmission/modules/Update';

interface ICreateModule {
  lineData: Record<string, any>;



}

interface ValidationRules {
  type: 'email' | 'required' | 'min' | 'max' | 'length';
  message?: string;
  quantity?: number;
}

interface ValidationSchema {
  name: string;
  type: 'string' | 'number' | 'boolean';
  label: string;
  rules: Array<ValidationRules>;
}

interface ILine {
  [key: string]: string;
}

const MyAvaliationDates: React.FC = () => {
  const endpoint = '/list-my-avaliator-dates';
  const { socket } = useSocket();
  const title = 'Meus horários de avaliação';


  const schema: Record<string, any> = {
    id: { ref: '_id', column: '_id', label: 'ID' },
    date: { ref: 'date', column: 'date', label: 'Data' },
    hour: { ref: 'hour', column: 'hour', label: 'Horário' },
    room: { ref: 'room', column: 'room', label: 'Sala' },
    section: { ref: 'section', column: 'section', label: 'Seção' },
    theme: { ref: 'theme', column: 'theme', label: 'Temática' },
    totem: { ref: 'totem', column: 'totem', label: 'Tótem' },


  };


  const config2: ISearchComponent = {
    idKey: endpoint,
    title: 'Meus horários de avaliação',
    endpoint: endpoint,
    schema: schema,
    autoStart: true,
    headModules: [],
    bodyModules: [



    ],
  };

  return (
    <Container>

      <SearchComponent {...config2} />
    </Container>
  );
};

export default MyAvaliationDates;
