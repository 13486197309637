import React from 'react';
import * as Yup from 'yup';
import { FormHandles } from '@unform/core';
import uuid from '../../../../../../core/components/UniqueHash';
import { useModal } from '../../../../../../../../hooks/Modal';
import FormComponent, { sanitizeDataObject } from '../../../../../../core/components/FormComponent';
import { createSchemaByForm, IYup } from '../../../../../../../../utils/SchemaCreator';
import getValidationErrors from '../../../../../../../../utils/getValidationErrors';
import api from '../../../../../../../../services/api';
import { useToast } from '../../../../../../../../hooks/Toast';
import { useSearch } from '../../../../../../../../hooks/Search';
import { AxiosInstance } from 'axios';
import { FaEdit } from 'react-icons/fa';
import { IconType } from 'react-icons/lib';


interface ICreateModule {
  lineData: Record<string, any>;
  title: string;
  formSchema: Record<string, any>;
  endpoint: string;
  validationSchema: Record<string, IYup>;
  apiRef?: AxiosInstance,
}

export default function UpdateModule({
  apiRef = api,
  lineData,
  title,
  formSchema,
  validationSchema,
  endpoint,
}: ICreateModule): JSX.Element {
  const { reloadSearchAll } = useSearch();
  const { addModal, removeModal } = useModal();
  const { addToast } = useToast();
  const key = uuid();

  const setCallback = async (
    data: Record<string, any>,
    formRef: React.RefObject<FormHandles>,
  ): Promise<void> => {
    try {
      const newData = sanitizeDataObject(data, formSchema);
      const schema = createSchemaByForm(validationSchema);
      await schema.validate(newData, { abortEarly: false });


      const response = await apiRef.put(`/updatePaperAfterObservations/${lineData._id}`, newData);

      if (response.status !== 200) {
        return addToast({ type: 'error', title: 'Erro ao atualizar' });
      }

      removeModal(key);
      reloadSearchAll();

      addToast({ type: 'success', title: 'Atualizado com sucesso' });
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const errors = getValidationErrors(err);

        formRef.current?.setErrors(errors);
        return;
      }
      console.log(err);

      return addToast({ type: 'error', title: err.response.data.error });
    }
  };

  const handleUpdate = async (id: string): Promise<void> => {
    const response = await api.get(`/getPaper/${id}`);
    const { data } = response;

    const content = (
      <>
        {lineData.observations && <> <h2 style={{ color: '#333' }}>Observações dos avaliadores</h2>
          <p style={{ color: '#333' }} dangerouslySetInnerHTML={{ __html: lineData.observations }} /><br /></>}
        <FormComponent
          dataInfo={data}
          schema={formSchema}
          setCallback={setCallback}
        />
      </>
    );
    if (content) {
      const keyModal = addModal({ title: '', content, key, theme: "whiteModal" });
    }
  };

  const moduleUpdate = (idUser: string): JSX.Element => (
    lineData.status === 'Correção solicitada' ? <button style={{ background: '#e46218' }} className="defaultButtonMini" title="Enviar correção" type="button" onClick={() => handleUpdate(idUser)}>
      Corrigir
    </button> : <></>
  );

  return moduleUpdate(lineData._id);
}
