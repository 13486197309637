import React, { useCallback, useRef, useEffect, useState } from 'react';
import { FiLogIn, FiMail, FiLock, FiUser } from 'react-icons/fi';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import { Link, useHistory, useParams } from 'react-router-dom';
// import logo from '../../assets/logo-compressed.png';

import { supportNumber, authTitle, defaultProjectHash } from '../../../../../../config';
import { Container, Content, AnimatedDiv2, ErrorContainer } from './styles';
import Input from '../../../../../../components/Forms/Input';
import Button from '../../../../../../components/Button';
import getValidationErrors from '../../../../../../utils/getValidationErrors';

import { useAuth } from '../../../../../../hooks/Auth';
import { useToast } from '../../../../../../hooks/Toast';
import Whatsapp from '../../../../../../components/Whatsapp';
import { useLanguage } from '../../../../../../hooks/Language';
import Loader from '../../../../../../components/Loader';
import { FaWhatsapp } from 'react-icons/fa';


interface SignInData {
  email: string;
  password: string;
  name: string;
}

interface IParams {
  redirect?: string;
}

const SignIn: React.FC<IParams> = ({ redirect = '/' }) => {
  const history = useHistory();
  const [withPassword, setWithPassword] = useState(true);
  const { signInAdmin } = useAuth();
  const { projectHash = defaultProjectHash, hash } = useParams<Record<string, any>>();
  const { addToast } = useToast();
  const formRef = useRef<FormHandles>(null);
  const [loading, setLoading] = useState(false);
  const { translate } = useLanguage();
  const [errorMessage, setErrorMessage] = useState<Record<string, string>>({});
  const [manutencao, setManutencao] = useState(false);
  const handleSubmit = useCallback(
    async (data: SignInData) => {
      setLoading(true);
      try {
        formRef.current?.setErrors({});

        const shape = withPassword
          ? {
            email: Yup.string()
              .required(`${translate('Email')} ${translate('obrigatório')}`),
            password: Yup.string().required(`${translate('Senha')} ${translate('obrigatória')}`),
          }
          : {
            email: Yup.string()
              .required(`${translate('Email')} ${translate('obrigatório')}`)

          };

        const schema = Yup.object().shape(shape);
        const { email: email_trim } = data;
        data.email = email_trim.trim();

        await schema.validate(data, { abortEarly: false });

        const { email, name, password } = data;

        await signInAdmin({ email: `av-${email}`, password });

        window.location.href = `/app/${projectHash}/area-avaliador/${hash}`;
        return;
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          setLoading(false);
          const errors = getValidationErrors(err);
          formRef.current?.setErrors(errors);
          return;
        }
        setLoading(false);

        const { status } = err?.response;

        if (status >= 500) {

          setErrorMessage({ title: 'Um momento...', description: 'Estamos com alta demanda de pedidos de acesso. <br/><br/>Por favor refaça seu login em 1 minuto para acessar.' })
          setTimeout(() => {
            setErrorMessage({});
          }, 60000)

        }
        else {

          setErrorMessage({ title: 'Usuário não identificado', description: 'Verifique se há alguma divergência em seus dados.' })
          setTimeout(() => {
            setErrorMessage({});
          }, 5000)

        }




      }
    },
    [signInAdmin, addToast, history],
  );

  return (<>
    {loading && <Loader message="Autenticando seu acesso ..." />}
    <Container>
      <Content>
        <AnimatedDiv2>

          {manutencao ? <div className="onlineMessage ">

            <h2  ><div />{translate('Etapa de avaliação não iniciada')}</h2>

            <Link to="/"> <button className='buttonBigGreen'>Voltar a capa</button></Link>
          </div> : <Form className="form" ref={formRef} onSubmit={handleSubmit}>

            <img src="/assets/logo.png" style={{ width: '100%', maxWidth: '200px', margin: '20px auto' }} />
            <h2 style={{ margin: '20px auto' }} className="titleElement">{translate('LOGIN DE AVALIADOR')}</h2>

            <Input name="email" placeholder={translate('E-mail')} />
            {withPassword && <Input

              name="password"
              type="password"
              placeholder={translate('Senha')}
            />}

            {errorMessage && errorMessage.title && <ErrorContainer>
              <h2>{errorMessage.title}</h2>
              <p dangerouslySetInnerHTML={{ __html: errorMessage.description }} />
            </ErrorContainer>}
            <aside style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
              <Button style={{ margin: '0px auto', marginTop: '40px' }} type="submit">{translate('Entrar')}</Button>
            </aside>
            {withPassword && <> <p style={{ margin: '10px 0px', color: '#333' }}>Caso tenha dificuldade em logar, solicite uma nova senha.</p>
              <Link style={{ color: '#fff', margin: '10px auto', display: 'flex', justifyContent: 'center' }} to={`/app/${projectHash}/recuperar_senha_avaliador`}><Button>{translate('Esqueci minha senha')}</Button>

              </Link></>}

            <aside style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%' }}><Whatsapp
              number={supportNumber}
              text={`${translate(`Olá, estou no evento ${authTitle}`)}`}
              message={translate('Suporte Técnico')}
            />

            </aside>
          </Form>}
        </AnimatedDiv2>
      </Content>
    </Container></>
  );
};
export default SignIn;
