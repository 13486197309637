import React from 'react';
import { useConfiguration } from '../../hooks/Configuration';
import Signup from '../SignUp';
import Products from '../Products'


const Subscription: React.FC = () => {

    const { configuration } = useConfiguration();


    return <Signup />


}

export default Subscription;