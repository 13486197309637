import styled, { keyframes } from 'styled-components';


import { background, defaultTheme } from '../../../../../config';


const fadeFromLeft = keyframes`
from {
  opacity: 0;
  transform:translateX(50) ;
}
to{
  opacity: 1;
  transform:translateX(0);
}
`;

const blur = keyframes`
0% {
 filter: blur(40px);
 transform: rotate(0deg);
}
50%{
  filter: blur(50px);
  transform: rotate(5deg);
}
100% {
 filter: blur(40px);
 transform: rotate(0deg);
}
`;

const fly = keyframes`
from {

  transform:translateX(0%) translateY(0%);
}
to{

  transform:translateX(0%) translateY(-5%);
}
`;

const round = keyframes`
from {

  transform:rotate(0deg);
  width: 95%;
  height: 95%;
}
to{

  transform:rotate(360deg);
  width: 105%;
  height: 105%;
}
`;

const round2 = keyframes`
from {

  transform:rotate(0deg);
  width: 100%;
  height:100%;
}
to{

  transform:rotate(360deg);
  width: 85%;
  height: 85%;
}
`;

const rotateSmoth = keyframes`
from {

  transform: rotateX(0deg) ;
}
to{
  transform: rotateX(20deg) ;
}
`;

interface IBackground {
  background?: string;
}



export const Container = styled.div<IBackground>`
  min-height: 70vh;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background: none;
  color: #555;
  padding: 50px 10px;
  width: 100%;
  max-width: 1400px;
  margin: 0px auto;

  @media (max-width: 750px) {
    flex-direction: column;

    img{
      max-width: 80% !important;
    }

     div,a {
       display: flex;
      align-items: center !important;
      text-align:center;
      justify-content: center;
    }
  }


.subtitle{
  font-size: 24px;
 
  
  margin-bottom: 10px;

}

  .white{
      font-size: 38px;
      color: #fff;

      @media (max-width: 750px) {
         font-size: 24px;
  }
    }


  .gray{
      font-size: 38px;
      color: #5c5c5c;

      @media (max-width: 750px) {
         font-size: 24px;
  }
    }
 

  @media (max-width: 750px) {
    padding: 0px 0px;
  }

  .marginTop40{
    margin-top: 40px;
    @media (max-width: 750px) {
      margin-top: 0px;
  }
}

.localDiv{
  display: flex ;
  align-items: center;
  justify-content: flex-start;
  gap: 30px;
  margin-top: 30px;
}


  .turnRight{
div{
    align-items: flex-end !important;

    @media (max-width: 750px) {
      align-items: center !important;
      padding: 0px;
  }
    
}
    p,h2{
      text-align: right;


      @media (max-width: 750px) {
        text-align: center;
  }
    }


  }


  .titleSkewRight{
    display: flex;
    border-radius: 10px;
    background: rgb(0, 102, 178);
    transition: 0.5s all;
    padding: 40px 20px;
    justify-content: center;
    color: #fff;
    font-size: 18px;
    margin-bottom: 30px;
    transition: 0.4s all;

    box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.3);

&:hover {
  box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.7);
  transform: translateY(-10px) translateX(-10px);
}

    > h2, > button{
      transform: skewX(10deg);
      color: #fff;

    }

    &:hover {
        background: rgb(0, 102, 178);
        > h2, > button{
          background: rgb(0, 102, 178);
        }
      }

  }

  .textBox{
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;

    >a {  text-decoration: none;
      >h3 {

        box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.3);
        transition: 0.4s all;
&:hover {
  box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.7);
  transform: translateY(-10px) translateX(-10px);
}
        width: 200px;
        padding: 20px;
        background: #fff;
        color: rgb(0, 102, 178);
        text-decoration: none;
        border-radius: 10px;
        font-size: 18px;
        margin: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        min-height: 100px;
      }
    }

  }

  .sponsors {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: flex-start;
    margin: 30px 0;
    flex-wrap: wrap;
    @media (max-width: 750px) {
      align-items: center;
      flex-direction: center;
  }

    > div {
      width: auto;
      max-width: 400px;
      margin: 20px;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;

      @media (max-width: 750px) {
        justify-content: center;
        flex-direction: center;
  }

      > h2 {
        font-size: 16px;

        color: rgb(50, 50, 50);
      }

      >div{
        position: relative;
        z-index:200;
        @media (max-width: 750px) {
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
      > img {
        width: 100%;
        margin: 20px;
      }

      > img.logoGerenciamento {
        margin-top: 50px;
        width: 70%;
      }

      >a {
        > img {
        width: 100%;
        margin: 20px;
      }

      > img.logoGerenciamento {
        margin-top: 50px;
        width: 70%;
      }
      }

      }
    }

    @media (max-width: 750px) {
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  }

  > .blocoLogo {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    z-index: 2;

    .logoEvento {
      width: 100%;
      max-width: 500px;
      height: auto;
      transform: translateX(-5%);
    }

    .logoEventoMini {
      width: 100px;
      margin-top: 50px;
    }
  }

  .bloco {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    margin: 30px 0;
    flex-wrap: wrap;

    > a {
      margin: 10px;
    }

    @media (max-width: 750px) {
      flex-direction: column;
    }

    > a {
      > button {
        width: 200px;
        padding: 20px;
        text-align: center;
        background: none
        color: #fff;
        transition: background 0.5s;
        border: 0;
        border-radius: 8px;

        &:hover {
          background: none
        }
      }
    }
  }

  .separator {
    width: 100%;
    max-width: 200px;
    height: 2px;
    background: rgb(0, 102, 178);
    margin: 20px 0;
  }

  .borderedBottom {
    border: 2px solid #fff;
    padding: 10px;
    color: #fff;
    background: none;
    opacity: 0.8;
    transition: opacity 0.5s;

    &:hover {
      opacity: 1;
    }
  }

  .logoEvento {
    width: 400px;
    max-width: 80%;
    z-index: 200;
  }

  .palestrantes {
    max-width: 200px;
    min-width: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    @media (max-width: 750px) {
      margin: 20px 0;
    }

    > .palestranteDiv {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      > .palestrante {
        width: 100px;
        max-width: 100px;
        min-width: 100px;
        height: 100px;
        margin: 10px;
        border: 2px solid rgb(0, 102, 178);
        border-radius: 50%;
        overflow: hidden;
        > img {
          width: 100%;
        }
      }

      > h2 {
        font-size: 16px;
        font-weight: bold;
        color: rgb(0, 102, 178);
      }

      > p {
        font-size: 14px;

        color: rgb(0, 102, 178);
      }
    }
  }

  .paragraph {
    max-width: 530px;
    width: 100%;

    font-size: 18px;

p{
  font-size: 18px !important;
}

    > strong {
      font-weight: bold;
      color: rgb(0, 102, 178);
    }
  }
`;

export const ContainerWhite = styled(Container)`
  min-height: 90vh;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background: #fff;
  color: rgb(56,56,56);
  padding: 100px 0;
  width: 100%;
  max-width: 100%;
  margin: auto;

  >h2{

  font-size: 18px;
  font-weight: bolder;
  line-height: 18px;
  margin: 0 50px;

  @media (max-width: 1200px) {
    font-size: 18px;
    line-height: 18px;
    text-align: center;
  }

}

  @media (max-width: 1200px) {
    padding: 0px 20px;
    flex-direction: column;
  }

  > .p50 {
    z-index: 1;
    width: 50%;
    min-width: 50%;
    margin: 30px 0;

    @media (max-width: 1200px) {
      width: 100%;
      min-width: 100%;
    }

    > h2 {
      font-size: 1.5em;
    }

    > p {
      text-align: justify;
    }
  }

  .p600 {
    max-width: 600px;
    min-width: auto;
    width: 100%;

    font-size: 16px;

    > strong {
      font-weight: bold;
      color: rgb(0, 102, 178);
    }

    > a {
      z-index: 2;
       }; button {
         margin-top: 10px;
      width: 200px;
      padding: 20px;
      text-align: center;
      background: rgb(0, 102, 178);
      color: #fff;
      transition: background 0.5s;
      border: 0;
      border-radius: 8px;

      &:hover {
        background: rgb(0, 102, 178);
      }
    }
  }
  }
`;

export const GreyContainer = styled.div`
  background: rgb(235, 235, 235);
  min-height: auto;
  width: 100%;
  padding: 100px 0;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  > table {
    width: 100%;
    table-layout: fixed;
    border-collapse: collapse;

    > thead {
      background: ${defaultTheme.menu.backgroundSticky};

      > tr {
        > th {
          padding: 20px;
          border: 0;
          text-align: left;
        }
      }
    }

    > tbody {
      > tr {
        border-bottom: 1px solid rgb(185, 185, 185);
        > td {
          padding: 20px 20px;

          text-align: left;
          color: rgb(56, 56, 56);
        }
      }
    }
  }

  > div.paragraph {
    max-width: 1100px;
    margin: auto;
    text-align: center;
    > h2,
    > p {
      color: rgb(56, 56, 56);

      @media (max-width: 1200px) {
        text-align: justify;
      }
    }
    > p {
      max-width: 750px;
      margin: auto;
    }

    > h2 {
      margin-bottom: 50px;
      font-size: 32px;

      @media (max-width: 1200px) {
        font-size: 16px;
      }
    }
  }
  > h2,
  > p {
    color: #fff;

    @media (max-width: 1200px) {
      text-align: justify;
    }
  }

  > h2 {
    font-size: 18px;
    font-weight: bolder;
    line-height: 18px;

    @media (max-width: 1200px) {
      font-size: 16px;
    }
  }
`;

export const SecondaryContainer = styled.div`
  background: rgb(88, 46, 145);
  min-height: auto;
  width: 100%;
  padding: 100px;

  display: flex;
  flex-direction: column;
  justify-content: center;

  @media (max-width: 1200px) {
    padding: 100px 20px;
  }

  > div.paragraph {
    max-width: 1100px;
    margin: auto;
    text-align: center;
    > h2,
    > p {
      color: #fff;

      @media (max-width: 1200px) {
        text-align: justify;
      }
    }
    > p {
      max-width: 750px;
      margin: auto;
    }

    > h2 {
      margin-bottom: 50px;
      font-size: 32px;

      @media (max-width: 1200px) {
        font-size: 16px;
      }
    }
  }
  > h2,
  > p {
    color: #fff;

    @media (max-width: 1200px) {
      text-align: justify;
    }
  }
`;

export const Capa = styled.div<IBackground>`
  min-height: auto;

  display: flex;
  flex-direction: column;

  justify-content: space-between;
  align-items: flex-start;
  background: none !important;
  background-size: cover;
  color: #fff;
  padding: 0px 20px;
  width: 100%;
  max-width: 600px;
 
  position: relative;

  gap: 20px;
  

  @media (max-width: 750px) {
       flex-direction: column !important;
  }

  .displayBlock {
    display: flex ;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    gap: 15px;
  }
 


  .mainMenu{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

 
      

    button{
      padding: 20px;
      border-radius: 10px;
      background: #fff;
      color:  rgba(0,0,150);
      border: 0px;
      box-shadow: 2px 2px 5px rgba(0,0,0,0.3);
      width: 250px;
      margin: 5px 0px;
      transition: 0.5s all;

      &:hover{
        background: rgb(0, 100, 200);
        color: #fff;
      }
    }
  
  }

.mainTitle{
  text-align: left;
  max-width: 500px  !important;
  min-width: 300px !important;
  align-items: flex-start !important;
  display: flex;
  margin-bottom: 40px !important;
  >div{
position: relative;
width: 100%;
 
  >h1 {
    font-size: 44px;
    display: inline;
    padding: 10px;
    padding: 0px;
    z-index: 1;
    text-shadow: 2px 2px 5px rgba(0,0,0,0.3);

    @media (max-width: 750px) {
      font-size: 32px;
  }
  }
  >h2{
    background: #fff;
    color: rgb(0,14,64);
    padding: 10px;
    font-size: 18px;

    width: auto;
    display: inline-block;
    margin-top: 10px;
    z-index: 1;
    text-shadow: 2px 2px 5px rgba(0,0,0,0.3);


    @media (max-width: 750px) {
      font-size: 14px;
  }
 
  }

  

  .backgroundBlur { 
    background: rgba(0,0,200,0.6);
    position: absolute;
    top: 0px;
    left:0px;
    bottom: 0px;
    right: 0px;
    filter: blur(20px);
    transform: 1s all;
    animation: ${blur} 4s infinite reverse;
    z-index: -1;
  
  }

}
}


  >div{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
  }

  @media (max-width: 1200px) {
    min-height: 10vh;
    flex-direction: column;
  }

  > div {
    margin: 0 10px;
    @media (max-width: 1200px) {
        width: 100%;
        margin: 0px;
      }
  }

  .titleCapa {
    max-width: 600px;

    > h2 {
      font-size: 32px;
      margin: 10px 0;
      text-align: center;
    }

    >p{
      font-size: 18px;
      text-align: center;
    }
  }

  > .p50 {
      z-index: 1;
      width: 50%;
      min-width: 50%;
      margin: 10px 0;
      justify-content: center;
      align-items: center;
      display: flex;
      flex-direction: column;

      @media (max-width: 1200px) {
        width: 100%;
      }

      > h2 {
        font-size: 18px;

        @media (max-width: 1200px) {
          text-align: center;
        }
      }

      > p {
        text-align: justify;
      }
    }

.imgCapa {
  margin-top: 80px;
  max-width: 60%;
  width: 100%;

  z-index: 1;



  @media (max-width: 1200px) {
    max-width: 100%;

  }
}

.sideColor{
  position: absolute;
  width: 20%;
  height: 100%;
  top: 0px;
  left: 0px;
  background: rgb(185,185,185);
  z-index: 0;

  @media (max-width: 1200px) {
    display: none;
  }
}

  .fundoFlutuante {
    position: absolute;
    top: 0;
    right: 0px;
    width: 50vh;
    height: 50vh;

    border-radius: 50% 50% 0 50%;


>div.imagemFlutuante {
  width: 100%;
  height: 100%;
  border-radius: 0 50%;
  overflow: hidden;
    >div{
    overflow: hidden;

    background: url('/assets/evento/fundo.jpeg') no-repeat center;
    background-size: cover;
    width: 90%;
    height: 100vh;
    transform: translateX(0%) ;
    animation : ${fly} 15s infinite alternate-reverse;

    }

  }


    >div.arcoFlutuante1{

border: 2px solid rgb(251,178,22);
border-radius: 0 50%;
width: 100%;
height: 100%;
position: absolute;
top: 0px;
left: 0px;

    animation : ${fly} infinite alternate-reverse 10s;
    }

    >div.arcoFlutuante2{

border: 2px solid rgb(251,198,22);
border-radius: 0 50%;
width: 100%;
height: 100%;
position: absolute;
top: -20px;
left: 0px;

    animation : ${fly} infinite alternate-reverse 7s;
    }

  }

  .noMargin {
    margin: 0px !important;
  }

  .logoText {

    font-size: 50px;
    color: ${defaultTheme.menu.logoColor};

    @media (max-width: 750px) {
      font-size: 40px;
  }

  }

  .justifyStart {
    justify-content: flex-start !important;

    @media (max-width: 750px) {
      justify-content: center !important;
  }
  }

  @media (max-width: 750px) {
   // padding: 10px 0px;
  }

  .separate {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    margin: 10px;
  }

  .titleSkewRight{
    display: flex;
    border-radius: 10px;
    background: rgb(0, 102, 178);
    transition: 0.5s all;
    padding: 10px 30px;
    justify-content: center;
    > h2, > button{
      transform: skewX(10deg);
      color: #fff;
      font-size: 18px;
    }

    &:hover {
        background: rgb(0, 102, 178);
        > h2, > button{
          background: rgb(0, 102, 178);
        }
      }

  }

  .sponsors {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    margin: 5px 0;
    flex-wrap: wrap;
    color: rgb(0, 102, 178);
    position: relative;
    z-index:200;
    background: #fff;

    @media (max-width: 750px) {
      align-items: center;
      flex-direction: row;
      flex-wrap: wrap;
  }

    > div {
      width: auto;
      max-width: 400px;
      margin: 5px;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;

      @media (max-width: 750px) {
        justify-content: center;
        flex-direction: row;
        flex-wrap: wrap;
  }
      > h2 {
        font-size: 16px;

        color: rgb(50, 50, 50);
      }
      > div {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: center;
        min-height: 100px;

        @media (max-width: 750px) {
      flex-direction: row;
      justify-content: center;
      align-items: center;
      flex-wrap: wrap;
    }

        > img {
          width: 150px;
          min-width: 150px;
          margin: 10px;
        }

        > img.organizacao {
          width: 100px;
        }

        >a{
          > img {
          width: 150px;
          min-width: 150px;
          margin: 10px;
        }

        > img.organizacao {
          width: 100px;
        }
        }


      }
    }

    @media (max-width: 750px) {
      flex-direction: row;
      justify-content: center;
      flex-wrap: wrap;
      align-items: center;
    }

    .embrapa { width: 100px; >img {max-width: 100%;}}
        .unipampa {width: 100px;  >img {max-width: 100%;}}
        .gov{ width: 200px;  >img {max-width: 100%;}

        }
        .juntos { width: 500px;  >img {max-width: 100%;}}
  }

  > .blocoLogo {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    z-index: 2;

    @media (max-width: 750px) {
      flex-direction: column;
    }

    > .p50 {
      z-index: 1;
      width: 50%;
      min-width: 50%;
      margin: 10px 0;
      justify-content: center;
      align-items: center;
      display: flex;
      flex-direction: column;

      @media (max-width: 1200px) {
        width: 100%;
      }

      > h2 {
        font-size: 1.5em;
      }

      > p {
        text-align: justify;
      }
    }

    .p600 {
    max-width: 600px;
    min-width: auto;
    width: 100%;

    font-size: 16px;

    > strong {
      font-weight: bold;
      color: rgb(0, 102, 178);
    }

    > a {
      z-index: 2;
       >} button {
         margin-top: 10px;
      width: 200px;
      padding: 20px;
      text-align: center;
      background: rgb(0, 102, 178);
      color: #fff;
      transition: background 0.5s;
      border: 0;
      border-radius: 8px;

      &:hover {
        background: rgb(0, 102, 178);
      }
    }
  }

  .titleSkewRight, .titleSkewRightDark{
    display: flex;border-radius: 10px,

    background: rgb(0, 102, 178);;
    padding: 10px 30px;
    justify-content: center;
    text-decoration: none;
    transition: 0.5s all;
    > h2, > button{
      transform: skewX(10deg);
      color: #fff;
    }

    &:hover {
        background: rgb(0, 102, 178);
        > h2, > button{
          background: rgb(0, 102, 178);
        }
      }

  }



  .titleSkewRightDark{
border-radius: 10px;
    background: rgb(0,148,217);
    align-items: flex-start;
    > h2, > button{
          background: rgb(0,148,217);
        }



  }

    .logoEvento {
      width: 100%;
      max-width: 500px;
      height: auto;
      transform: translateX(-5%);
    }

    .logoEventoMini {
      width: 100px;
      margin-top: 50px;
    }
  }

  .bloco {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    margin: 30px 0;
    flex-wrap: wrap;

    > a {
      margin: 10px;
    }

    @media (max-width: 750px) {
      flex-direction: column;
    }

    > a {
      > button {
        width: 200px;
        padding: 20px;
        text-align: center;
        background: rgb(0, 102, 178);
        color: #fff;
        transition: background 0.5s;
        border: 0;
        border-radius: 8px;

        &:hover {
          background: rgb(0, 102, 178);
        }
      }
    }
  }

  .separator {
    width: 100%;
    max-width: 200px;
    height: 2px;
    background: rgb(0, 102, 178);
    margin: 20px 0;
  }

  .borderedBottom {
    border: 2px solid #fff;
    padding: 10px;
    color: #fff;
    background: none;
    opacity: 0.8;
    transition: opacity 0.5s;

    &:hover {
      opacity: 1;
    }
  }

  .logoEvento {
    width: 400px;
    max-width: 80%;
    z-index: 200;
  }

  .palestrantes {
    max-width: 200px;
    min-width: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    @media (max-width: 750px) {
      margin: 20px 0;
    }

    > .palestranteDiv {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      > .palestrante {
        width: 100px;
        max-width: 100px;
        min-width: 100px;
        height: 100px;
        margin: 10px;
        border: 2px solid rgb(0, 102, 178);
        border-radius: 50%;
        overflow: hidden;
        > img {
          width: 100%;
        }
      }

      > h2 {
        font-size: 16px;
        font-weight: bold;
        color: rgb(0, 102, 178);
      }

      > p {
        font-size: 14px;

        color: rgb(0, 102, 178);
      }
    }
  }

  .paragraph {
    max-width: 530px;
    width: 100%;

    font-size: 16px;

    > strong {
      font-weight: bold;
      color: rgb(0, 102, 178);
    }
  }
`;

export const Sponsors = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  margin: 5px 0;
  flex-wrap: wrap;
  color: rgb(0, 102, 178);
  position: relative;
  z-index: 200;
  background: #fff;

  @media (max-width: 750px) {
    align-items: center;
    flex-direction: row;
    flex-wrap: wrap;
  }

  > div {
    width: auto;
    max-width: 400px;
    margin: 5px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    @media (max-width: 750px) {
      justify-content: center;
      flex-direction: row;
      flex-wrap: wrap;
    }
    > h2 {
      font-size: 16px;

      color: rgb(50, 50, 50);
    }
    > div {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      align-items: center;
      min-height: 100px;

      @media (max-width: 750px) {
        flex-direction: row;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
      }

      > img {
        width: 150px;
        min-width: 150px;
        margin: 10px;
      }

      > img.organizacao {
        width: 100px;
      }

      > a {
        > img {
          width: 150px;
          min-width: 150px;
          margin: 10px;
        }

        > img.organizacao {
          width: 100px;
        }
      }
    }
  }

  @media (max-width: 750px) {
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
    align-items: center;
  }

  .embrapa {
    width: 100px;
    > img {
      max-width: 100%;
    }
  }
  .unipampa {
    width: 100px;
    > img {
      max-width: 100%;
    }
  }
  .gov {
    width: 200px;
    > img {
      max-width: 100%;
    }
  }
  .juntos {
    width: 500px;
    max-width: 100%;
    > img {
      max-width: 100%;
    }
  }
`;

export const Photo = styled.div<IBackground>`
  width: 200px;
  margin: 10px;

  height: 200px;
  background: url(${props => props.background}) no-repeat;
  background-size: cover;
  background-position: center;
transition: 0.2s all;
box-shadow: 0px 2px 5px rgba(0,0,0,0.3);
border: 0px;
  &:hover{
    transform: scale(1.1);
    box-shadow: 0px 3px 15px rgba(0,0,0,0.3);
    border: 3px solid #fff;
  }
`;

export const ProgramContainer = styled.div`
  display: flex;
  flex-direction: column;

  width: auto;
  align-items: center;
  justify-content: center;
  z-index: 2000;
  transition: 0.5s all;
  width: 100%;

  > div {
    overflow-y: auto;
    padding: 40px;
    width: 100%;
    max-width: 500px;
    display: flex;
    flex-direction: column;
    color: #5b5c65;
    background: #fff;
    margin: 0;

    @media (max-width: 750px) {
      max-width: 100%;
      width: 100%;
    }

    > h2 {
      color: ${defaultTheme.defaultBackground};
      margin-bottom: 10px;
      text-align: center;
      justify-content: center;
      display: flex;
    }

    > p {
      color: #5b5c65;
      font-size: 12px;
    }

    > button {
      padding: 10px;
      margin: 5px auto;
      width: 200px;
      border-radius: 8px;
      background: ${defaultTheme.defaultBackground};
      color: #fff;
      border: 0px;
    }

    > div {
      color: #5b5c65;
      height: auto;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      margin-top: 10px;

      > h2 {
        color: ${defaultTheme.defaultBackground};
        margin-bottom: 10px;
        text-align: left;
        width: 100%;
      }

      > p {
        color: #5b5c65;
        font-size: 12px;
      }

      > button {
        padding: 10px;
        margin: 5px auto;
        width: 200px;
        border-radius: 8px;
        background: ${defaultTheme.defaultBackground};
        color: #fff;
        border: 0px;
      }
    }
  }
`;
