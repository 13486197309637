import React from 'react';
import { FaArrowCircleRight } from 'react-icons/fa';
import { Separator } from '../../pages/Submission/styles';

import { Container } from './style';
interface IProps {
    data: Record<string, any>;
    submission: Record<string, any>;
}

const Review: React.FC<IProps> = ({ data, submission }) => {

    const renderField = (block, sub) => {

        const hashField = block?.hash;
        if (hashField === 'categories') {

            //  return <CheckboxIncluder category={category} area={area} subarea={subarea} setSubarea={(value) => setSubarea(value)} setArea={(value) => setArea(value)} setCategory={(value) => setCategory(value)} name="category" options={sub?.categories} placeholder="Categorias" />
            return <>
                <tr><td>Categoria</td><td>{data.category}</td></tr>
                <tr><td>Área</td><td>{data.area}</td></tr>
                <tr><td>SubÁrea</td><td>{data.subarea}</td></tr>
            </>


        }
        else if (hashField === 'resume') {

            return <>
                <tr><td>Título</td><td>{data?.title}</td></tr>
                <tr><td>Resumo</td><td><p dangerouslySetInnerHTML={{ __html: data?.short_description }} /></td></tr>
                <tr><td>Palavras-chave</td><td>{data?.nested_keywords}</td></tr>
            </>

        }
        else if (hashField === 'extra_categories') {

            return <tr><td>{block?.title}</td><td>{data?.extra_categories?.join(', ')}</td></tr>

        }
        else if (hashField === 'language') {

            return <tr><td>{block?.title}</td><td>{data?.language ? 'Sim' : 'Não'}</td></tr>


        }
        else if (hashField === 'coauthor') {

            return <tr><td>{block?.title}</td><td>{data?.nested_co_authors}</td></tr>


        }
        else if (sub?.[`${hashField}_fields`]) {
            const items: Array<JSX.Element> = [];
            sub?.[`${hashField}_fields`]?.map(field => {
                items.push(<tr><td>{field.title}</td><td>{data?.[field?.hash]}</td></tr>)
            });

            return <>{items}</>;

        }



    }

    const renderBlock = (block, number) => {


        return <Separator style={{ width: '100%' }}>
            <label>{number} - {block?.title}</label>

            <table style={{ width: '100%' }}>
                <tbody>
                    {renderField(block, submission)}
                </tbody>
            </table>


        </Separator>


    }


    const box = (dataInfo, key = '') => {
        if (!dataInfo) {
            return <></>
        }
        return <>
            {dataInfo.map(d => <aside style={{ background: '#eee', padding: '5px', margin: '5px', color: '#333', width: 'auto' }}>{key === 'self' ? d || '' : d?.[key] || ''}</aside>)}
        </>
    }

    return <Container>
        {submission?.available_blocks?.map((available, index) => {
            return renderBlock(available, index + 1);
        })}

    </Container>
}

export default Review;