import React, { useEffect, useRef, useState } from 'react';
import { Form } from '@unform/web';
import { FiMail, FiUser, FiLock, FiMap, FiArrowRight } from 'react-icons/fi';
import { Link, useParams } from 'react-router-dom';
import {
  FaWhatsapp,
  FaFileMedical,
  FaNotesMedical,
  FaHospital,
  FaSimplybuilt,
  FaBuilding,
  FaIdBadge,
  FaClinicMedical,
  FaPhone,
} from 'react-icons/fa';
import { ISigla, readSigla, siglas as siglasRef } from './siglas';
import { MdError, MdDateRange, MdChat } from 'react-icons/md';
import { universidades } from './universidades';
import * as Yup from 'yup';
import ReCAPTCHA from 'react-google-recaptcha';
import { FormHandles } from '@unform/core';
import Input from '../../../components/Forms/Input';
import Button from '../../../components/Forms/Button';
import Loader from '../../../components/Loader';
import MaskedInput from '../../../components/Forms/MaskedInput';
import { ResponseMessage } from '../../../components/ResponseMessage';
import getValidationErrors from '../../../utils/getValidationErrors';
import { Error } from './styles';
import SelectSimple from '../../../components/Forms/SelectSimple';
import Whatsapp from '../../../components/WhatsAppCompartilhar';

import api from '../../../services/api';
import { authTitle, defaultProjectHash, recaptchaKey, urlWebsite } from '../../../config';
import { countries } from './countries';
import TextareaInput from '../../../components/Forms/TextareaInput';
import { useToast } from '../../../hooks/Toast';
import CheckboxInput from '../../../components/Forms/CheckboxInput';
import getCep from '../../../services/cep';
import Axios from 'axios';
import states from './states';
import { useLanguage } from '../../../hooks/Language';
import CompleteSaleFields from './CompleteSaleFields';
import testCPF from '../../../utils/testCPF';
import { addYears, isValid } from 'date-fns';
import { useModal } from '../../../hooks/Modal';
import { useConfiguration } from '../../../hooks/Configuration';
import { loadFromLocalJson } from '../../../utils/loadFromJson';
import { useLoading } from '../../../hooks/LoadingHook';
import { useAuth } from '../../../hooks/Auth';
interface IRecoverFormData {
  [key: string]: any;
}


interface IRead {
  [key: string]: any;
}

interface IOption {
  value: string;
  label: string;
}
interface IParams {
  projectHash: string;
  eventHash: string;
  productId: string;
}

interface ICaptcha {
  getValue(): string;
}

declare var window: any;

const FormContainer = ({ context = {} }) => {
  const { signIn } = useAuth();
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [errorMessage, setErrorMessage] = useState<Array<string>>([]);
  const [withPassword, setWithPassword] = useState(false);
  const formRefConfirmationOK = useRef<FormHandles>(null);
  const formRefConfirmation = useRef<FormHandles>(null);
  const formRef = useRef<FormHandles>(null);
  const recaptchaRef = useRef(null);
  const { configuration } = useConfiguration();
  const { projectHash = configuration?.url, eventHash = configuration?.current_event_id_response?.url, productId } = useParams<IParams>();

  const [emailRecord, setEmailRecord] = useState('');

  const getCaptchaValue = (elem: ICaptcha): string => {
    return elem.getValue();
  };
  const siglas = readSigla();
  const { addLoading, removeLoading } = useLoading();
  const { addToast } = useToast();
  const { translate } = useLanguage();
  const { addModal } = useModal();
  const [countryISO, setCountryISO] = useState('BR');
  const [position, setPosition] = useState('');
  const [otherCompany, setOtherCompany] = useState('');
  const [product, setProduct] = useState<Record<string, any>>({});
  const [cities, setCities] = useState<Array<IOption>>([]);
  const [stateValue, setStateValue] = useState('');

  const [escolaCategory, setEscolaCategory] = useState('');
  const [escolaOption, setEscolaOption] = useState([]);


  const [currentEmail, setCurrentEmail] = useState('');
  const [ready, setReady] = useState(false);
  const [statusConfirmation, setStatusConfirmation] = useState('new-subscriber');
  const [deficiencia, setDeficiencia] = useState('Não');
  const [categoryOptions, setCategoryOptions] = useState([]);
  const [category1Options, setCategory1Options] = useState([]);
  const [category, setCategory] = useState('');



  const [extra1, setExtra1] = useState('');
  const [extra2, setExtra2] = useState('');


  const [inscricao1, setInscricao1] = useState('');
  const [inscricao2, setInscricao2] = useState('');

  const [company, setCompany] = useState('');


  const [categoryValue, setCategoryValue] = useState('');
  const [category1Value, setCategory1Value] = useState('');

  const options = {


    "Submissão de trabalhos": [


      { label: 'Biomedicina - diurno', value: 'Biomedicina - diurno' },
      { label: 'Biomedicina - noturno', value: 'Biomedicina - noturno' },
      { label: 'Enfermagem', value: 'Enfermagem' },
      { label: 'Farmácia', value: 'Farmácia' },
      { label: 'Física Médica', value: 'Física Médica' },
      { label: 'Fisioterapia', value: 'Fisioterapia' },
      { label: 'Fonoaudiologia', value: 'Fonoaudiologia' },
      { label: 'Gastronomia', value: 'Gastronomia' },
      { label: 'Gestão em Saúde', value: 'Gestão em Saúde' },
      { label: 'Informática Biomédica', value: 'Informática Biomédica' },
      { label: 'Medicina', value: 'Medicina' },
      { label: 'Nutrição', value: 'Nutrição' },
      { label: 'Psicologia', value: 'Psicologia' },

      { label: 'Química Medicinal', value: 'Química Medicinal' },

      { label: 'Tecnologia em Alimentos', value: 'Tecnologia em Alimentos' },
      { label: 'Toxicologia Analítica', value: ' Toxicologia Analítica' },
      { label: 'Outro', value: 'Outro? Qual?' },
    ],



    'Estudante de graduação': [
      { label: 'Biomedicina - diurno', value: 'Biomedicina - diurno' },
      { label: 'Biomedicina - noturno', value: 'Biomedicina - noturno' },
      { label: 'Biotecnologia', value: 'Biotecnologia' },

      { label: 'Enfermagem', value: 'Enfermagem' },

      { label: 'Farmácia', value: 'Farmácia' },

      { label: 'Física Médica', value: 'Física Médica' },
      { label: 'Fisioterapia', value: 'Fisioterapia' },
      { label: 'Fonoaudiologia', value: 'Fonoaudiologia' },
      { label: 'Gastronomia', value: 'Gastronomia' },
      { label: 'Gestão em Saúde', value: 'Gestão em Saúde' },
      { label: 'Informática Biomédica', value: 'Informática Biomédica' },
      { label: 'Medicina', value: 'Medicina' },
      { label: 'Nutrição', value: 'Nutrição' },

      { label: 'Psicologia', value: 'Psicologia' },

      { label: 'Química Medicinal', value: 'Química Medicinal' },

      { label: 'Tecnologia em Alimentos', value: 'Tecnologia em Alimentos' },
      { label: 'Toxicologia Analítica', value: ' Toxicologia Analítica' },
      { label: 'Outro', value: 'Outro? Qual?' },
    ],
    'Estudante de pós-graduação': [
      { label: ' Especialização', value: ' Especialização' },
      { label: ' Residência médica', value: ' Residência médica' },
      { label: ' Residência multi/uniprofissional', value: ' Residência multi/uniprofissional' },
      { label: ' Mestrado', value: ' Mestrado' },
      { label: ' Doutorado', value: ' Doutorado' },
      { label: 'Outro', value: 'Outro? Qual?' },
    ],
    "Seminário de Internacionalização": [
      { label: "Biomedicina - diurno", value: "Biomedicina - diurno" },
      { label: "Biomedicina - noturno", value: "Biomedicina - noturno" },
      { label: "Biotecnologia", value: "Biotecnologia" },
      { label: "Ciências Biológicas/Biologia", value: "Ciências Biológicas/Biologia" },
      { label: "Ciências Sociais", value: "Ciências Sociais" },
      { label: "Educação Física", value: "Educação Física" },
      { label: "Enfermagem", value: "Enfermagem" },
      { label: "Engenharia Ambiental", value: "Engenharia Ambiental" },
      { label: "Engenharia de Alimentos", value: "Engenharia de Alimentos" },
      { label: "Farmácia", value: "Farmácia" },
      { label: "Física", value: "Física" },
      { label: "Física Médica", value: "Física Médica" },
      { label: "Fisioterapia", value: "Fisioterapia" },
      { label: "Fonoaudiologia", value: "Fonoaudiologia" },
      { label: "Gastronomia", value: "Gastronomia" },
      { label: "Gestão em Saúde", value: "Gestão em Saúde" },
      { label: "Informática Biomédica", value: "Informática Biomédica" },
      { label: "Medicina", value: "Medicina" },
      { label: "Nutrição", value: "Nutrição" },
      { label: "Odontologia", value: "Odontologia" },
      { label: "Pedagogia", value: "Pedagogia" },
      { label: "Psicologia", value: "Psicologia" },
      { label: "Química", value: "Química" },
      { label: "Química Medicinal", value: "Química Medicinal" },
      { label: "Saúde Coletiva", value: "Saúde Coletiva" },
      { label: "Serviço Social", value: "Serviço Socia" },
      { label: "Tecnologia em Alimentos", value: "Tecnologia em Alimentos" },
      { label: "Toxicologia Analítica", value: "Toxicologia Analítica" },
      { label: 'Outro', value: 'Outro? Qual?' }]
  }



  useEffect(() => {
    if (extra2 === 'Submissão de trabalhos') {
      setCategory('Submissão de trabalhos');
    }
  }, [extra2])
  useEffect(() => {



    setCategoryOptions(options[category] ? options[category] : []);


  }, [category])

  useEffect(() => {



    setCategory1Options(options[extra1] ? options[extra1] : []);


  }, [extra1])

  const loadEscolaOptions = async (value) => {

    if (value === 'Pública') {
      addLoading({ title: 'Verificando', key: 'loading' })
      const newOptions = await loadFromLocalJson({ url: '/apoio/publicas.json' });
      setEscolaOption(newOptions)

      removeLoading('loading')

    }
    else if (value === 'Privada') {
      addLoading({ title: 'Verificando', key: 'loading' })
      const newOptions = await loadFromLocalJson({ url: '/apoio/privado.json' });
      setEscolaOption(newOptions)

      removeLoading('loading')
    }

  }

  useEffect(() => {

    loadEscolaOptions(escolaCategory)

  }, [escolaCategory])

  const [info, setInfo] = useState({
    nome: '',
    fantasia: '',
    uf_empresa: '',
    municipio_empresa: ''
  })

  const verifyCNPJ = async (cnpj) => {

    const check = cnpj.toString().replace(/[^0-9]/g, '');

    if (check?.length < 14) {
      return;
    }

    try {
      const checkCNPJ = await api.post('/check-cnpj', { cnpj: cnpj });

      setInfo({
        nome: checkCNPJ?.data?.nome,
        fantasia: checkCNPJ?.data?.fantasia,
        uf_empresa: checkCNPJ?.data?.uf_empresa,
        municipio_empresa: checkCNPJ?.data?.municipio_empresa

      })
    }
    catch (err) {

    }


  }



  const callCityApi = async (value) => {

    const response = await Axios.get(`https://servicodados.ibge.gov.br/api/v1/localidades/estados/${value}/municipios`);

    if (response) {

      const values: Array<IOption> = [];

      response.data.map(city => {
        values.push({ value: city.nome, label: city.nome });
      })

      setCities(values);
    }


  }

  useEffect(() => {
    callCityApi(stateValue);
  }, [stateValue])



  const getCepFromApi = async (value: string): Promise<void> => {
    const cepResponse = await getCep(value);

    if (!cepResponse.error) {
      formRef?.current?.setFieldValue('address', cepResponse.logradouro);
      formRef?.current?.setFieldValue('neighborhood', cepResponse.bairro);
      formRef?.current?.setFieldValue('city', cepResponse.localidade);
      formRef?.current?.setFieldValue('state', cepResponse.uf);
    }
  };


  const handleNewLoginUpdate = async (data: IRecoverFormData) => {
    setLoading(true);
    try {
      formRef.current?.setErrors({});

      const fieldsValues: Record<string, any> = {};
      let fieldsText = '';
      const shape = {

      };

      if (product?.fields && product?.fields.length > 0) {


        const fieldsKeys: Array<string> = [];


        let x = 1;
        product?.fields.map((f: Record<string, any>) => {
          if (f.mandatory === 'yes') {
            shape[f.field] = Yup.string().required(`Campo ${translate(f.label)} é obrigatório`);
          }
          fieldsKeys.push(f.field);
          fieldsValues[f.field] = data?.[f.field];

          if (x > 1) {
            fieldsText = `${fieldsText} | `;
          }

          fieldsText = `${fieldsText}${data?.[f.field]}`;


          x++;

        })


      }


      const schema = Yup.object().shape(shape);



      await schema.validate(data, { abortEarly: false });

      const content: Record<string, any> = {
        ...data,
        email: emailRecord,
        project_id: projectHash,
        event_id: eventHash ? eventHash : '',
        product: product._id.toString(),
        fields: { ...fieldsValues },
        fields_text: fieldsText,
      };



      const newUser = await api
        .post('/signup-update', content)
        .catch(error => {
          if (error instanceof Yup.ValidationError) {
            const errors = getValidationErrors(error);
            formRef.current?.setErrors(errors);
            setLoading(false);
          }

          addToast({
            type: 'error',
            title: error?.response?.data?.message || error?.response?.data?.error || 'Erro ao cadastrar',
          });
          setLoading(false);
          return false;
        });

      if (!newUser) {
        setLoading(false);
        return false;
      }

      if (window?.fbq) {
        window.fbq('track', 'CompleteRegistration');
      }

      setSuccess(true);
      setLoading(false);


      return false;
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const errors = getValidationErrors(err);

        formRef.current?.setErrors(errors);

        const errorInfo = err.errors.reduce((error, prev) => {
          prev = `${prev}, ${error}`;
          return prev;
        });
        setLoading(false);
        addToast({
          type: 'error',
          title: 'Erro de validação',
          description: errorInfo,
        });
      }
      setLoading(false);
    }
  }


  const handleNewLogin = async (data: IRecoverFormData) => {
    setLoading(true);

    const fieldsValues: Record<string, any> = {};
    let fieldsText = '';



    let razao_social = '';
    let fantasia = '';
    let uf_empresa = '';
    let municipio_empresa = '';





    try {
      formRef.current?.setErrors({});

      let shape: Record<string, any> = {
        name: Yup.string().required('O campo Nome é obrigatório'),

        email: Yup.string()
          .email('Preencha um email válido')
          .required('O campo Email é obrigatório'),
        phone: Yup.string().required('o campo Celular é obrigatório'),
        term: Yup.string().required('O campo Termo é obrigatório'),
        company: Yup.string().required('O campo Instituição é obrigatório'),


        gender_id: Yup.string().required('O campo Gênero é obrigatório'),
        city: Yup.string().required('O campo Cidade é obrigatório'),
        state: Yup.string().required('O campo Estado é obrigatório'),
        country: Yup.string().required('O campo País é obrigatório'),

      };

      if (!inscricao1 && !inscricao2) {
        addToast({ title: 'Selecione pelo menos uma categoria de inscrição', description: "Seminário de Internacionalização e/ ou Mostra de Ensino, Pesquisa e Extensão", type: "info" })
        setLoading(false);
        return;
      }


      shape.extra_1 = Yup.string().required('O campo Categoria  é obrigatório');

      if (category1Options?.length > 0) {
        shape.extra_3 = Yup.string().required('O campo SubCategoria é obrigatório');

      };



      if (data?.extra1 === 'Estudante de pós-graduação') {
        shape.instituicao = Yup.string().required('O campo Nome do programa é obrigatório');
      }


      if (inscricao2) {
        shape.extra_2 = Yup.string().required('O campo Tipo de inscrição da Mostra é obrigatório');

      };




      if (product?.fields && product?.fields.length > 0) {


        const fieldsKeys: Array<string> = [];


        let x = 1;
        product?.fields.map((f: Record<string, any>) => {
          if (f.mandatory === 'yes') {
            shape[f.field] = Yup.string().required(`Campo ${translate(f.label)} é obrigatório`);
          }
          fieldsKeys.push(f.field);
          fieldsValues[f.field] = data?.[f.field];

          if (x > 1) {
            fieldsText = `${fieldsText} | `;
          }

          fieldsText = `${fieldsText}${data?.[f.field]}`;

          x++;

        })


      }


      const schema = Yup.object().shape(shape);
      data.phone = data?.phone?.toString()?.replace(/[^0-9]/g, '');












      data.email = data?.email?.trim()?.toLowerCase() || '';

      await schema.validate(data, { abortEarly: false });



      data.extra_2 = data.extra_2?.[0] || '';
      data.extra_4 = data.extra_4?.[0] || '';
      data.extra_5 = data.extra_5?.[0] || '';
      data.inscricao_1 = data.inscricao_1?.[0] || '';
      data.inscricao_2 = data.inscricao_2?.[0] || '';
      data.term = data.term?.[0] || '';



      const captchaValue =
        getCaptchaValue(recaptchaRef?.current || { getValue: () => '' }) ||
        false;

      const content: Record<string, any> = {
        ...data,

        project_id: projectHash,
        event_id: eventHash ? eventHash : '',
        captcha: captchaValue,
        term: ['Aceito'],
        fields: { ...fieldsValues },
        fields_text: fieldsText,


      };


      content.product = configuration?.current_event_id_response?.product_id_response?._id?.toString();



      const newUser = await api
        .post('/signup', content)
        .catch(error => {
          if (error instanceof Yup.ValidationError) {
            const errors = getValidationErrors(error);
            formRef.current?.setErrors(errors);
            setLoading(false);
          }

          addToast({
            type: 'error',
            title: error?.response?.data?.message || error?.response?.data?.error || 'Erro ao cadastrar',
          });
          setLoading(false);
          return false;
        });

      if (!newUser) {
        setLoading(false);
        return false;
      }

      if (window?.fbq) {
        window.fbq('track', 'CompleteRegistration');
      }

      await signIn({ email: data?.email, project_id: projectHash })

      setSuccess(true);
      setLoading(false);
      // window.location.href = `/app/${projectHash}/user-dashboard`;


      return false;
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const errors = getValidationErrors(err);

        formRef.current?.setErrors(errors);

        const errorInfo = err.errors.reduce((error, prev) => {
          prev = `${prev}, ${error}`;
          return prev;
        });
        setLoading(false);
        addToast({
          type: 'error',
          title: 'Erro de validação',
          description: errorInfo,
        });
      }
      setLoading(false);
    }
  }

  const handleSendConfirmation = async () => {

    try {
      const confirm = await api.post(`/confirm-subscriber`, { email: currentEmail, project_id: projectHash, event_id: eventHash ? eventHash : '', product: productId ? productId : '' });

      if (confirm) {
        setSuccess(true);
        setStatusConfirmation('finished');

      }
      setLoading(false);
    }
    catch (err) {
      addToast({ type: 'error', title: 'Erro ao confirmar inscrição' });
      setLoading(false);
    }



  }

  const handleConfirmation = async (data) => {

    data.email = data?.email?.trim()?.toLowerCase() || '';

    try {
      const checkUser = await api.post(`/check-subscriber`, { email: data.email, project_id: projectHash, event_id: eventHash || '', product: productId ? productId : '' });

      if (checkUser) {
        setCurrentEmail(data.email);
        setStatusConfirmation(checkUser?.data?.status || 'new-subscriber');
        if (checkUser?.data?.product?._id) {

          setProduct(checkUser?.data?.product);
        }
        setEmailRecord(data.email)
        setReady(true);

      }

    }
    catch (err) {
      addToast({ type: 'error', title: err?.response?.data?.message || 'Erro ao checar usuário, por favor, tente novamente' });
      return
    }


  }





  if (statusConfirmation === 'finished') {

    return (
      <>

        <h1 style={{ color: 'rgb(0,15,64)', marginBottom: '20px', textAlign: 'center' }}>Inscrições encerradas</h1>

        <p style={{ color: 'rgb(0,15,64)', marginBottom: '20px', textAlign: 'center' }}>Agradecemos seu interesse, mas as vagas estão encerradas</p>

        <Link to={`/`}><button className="defaultButton" type="button">
          Voltar
        </button></Link>
      </>
    )
  }

  if (statusConfirmation === 'confirmated') {

    return (
      <>

        <h1 style={{ color: 'rgb(0,15,64)', marginBottom: '20px' }}>Olá participante!</h1>

        <p style={{ color: 'rgb(0,15,64)', marginBottom: '20px' }}>Identificamos o seu cadastro em nossa plataforma. A sua inscrição no próximo evento está confirmada.</p>

        <Link to={`/app/${projectHash}/login`}><button className="defaultButton" type="button">
          Acessar
        </button></Link>
      </>
    )
  }

  if (window?.location?.pathname === '//') {
    return <aside style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <button className="blueButtonBig" type="submit">
        INSCRIÇÕES SUSPENSAS


      </button>
    </aside>
  }



  if (statusConfirmation === 'update') {
    return <>
      {loading === true && (
        <Loader message="Preparando seu cadastro..." position="fixed" />
      )}

      <aside>
        {success ? (
          <ResponseMessage
            active
            type="clean"
            title=""
            description={
              <p style={{ color: 'rgb(0, 14, 64)', textAlign: 'center' }}>
                <h2 className="titleH2" style={{ color: 'rgb(0, 14, 64)' }}> Inscrição no evento realizada com sucesso!</h2>

                Aproveite e se inscreva em nossas palestras, oficinas ou agende visitações em grupo no botão abaixo!

                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                  <Link to={`/app/${projectHash}/user-dashboard`} >
                    <button className="defaultButton" type="button">
                      Acessar
                    </button>
                  </Link>
                </div>
                <br />

              </p>
            }
          />
        ) : (<>

          <Form ref={formRef} style={{ padding: '15px', minWidth: '320px' }} onSubmit={handleNewLoginUpdate} initialData={{}}>
            <h1 style={{ color: 'rgb(0,15,64)', marginBottom: '20px' }}>Completar inscrição</h1>

            {product && product?.fields && product?.fields.length > 0 ? <CompleteSaleFields fields={product.fields} /> : <></>}

            <aside style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <button className="blueButtonBig" type="submit">
                QUERO ME INSCREVER


              </button>
            </aside>
          </Form>
        </>
        )}
      </aside>
    </>
  }

  if (success) {

    return <div style={{ display: 'flex', minHeight: '50vh', padding: '20px', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>

      <h2 className="titleH2  purple" style={{ padding: '15px', fontSize: '24px', textAlign: 'center' }} > Sua inscrição está confirmada!</h2>
      <br />
      <p className='yellow' style={{ padding: '15px', fontSize: '18px', lineHeight: '24px' }}>
        Para submeter o seu trabalho, clique no botão abaixo</p>

      <br />
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <Link to={`/app/${projectHash}/user-dashboard`} >
          <button className="defaultButtonReverse" type="button">
            Acessar
          </button>
        </Link>
      </div>
      <br />


    </div>


  }


  return (
    <>
      {loading === true && (
        <Loader message="Preparando seu cadastro..." position="fixed" />
      )}

      <aside style={{ background: '#fff', padding: '50px 20px', borderRadius: '10px' }}>
        {success ? (
          <div style={{ display: 'flex', background: 'none', padding: '20px', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>

          </div>


        ) : (<>

          <Form name={projectHash} ref={formRef} onSubmit={handleNewLogin} initialData={{ country: 'BR' }}>




            <Input name="name" placeholder="Nome Completo" />
            <MaskedInput mask='999.999.999-99' name="document_number" placeholder="CPF - Somente Brasileiros" />
            <Input type="email" name="email" placeholder="E-mail" />

            <SelectSimple label="Gênero" name="gender_id" options={[
              { value: 'Masculino', label: 'Masculino' },
              { value: 'Feminino', label: 'Feminino' },
              { value: 'Não binário', label: 'Não binário' },
              { value: 'Prefiro não declarar', label: 'Prefiro não declarar' }

            ]} />

            <SelectSimple name="disability_id" setCallback={(value) => { setDeficiencia(value || 'Não') }} label="Você têm alguma deficiência?" options={[
              { value: 'Sim', label: 'Sim' },
              { value: 'Não', label: 'Não' },

            ]} />
            {deficiencia === 'Sim' && <Input name="disability_type" placeholder="Cite o tipo de auxílio necessário" />}
            <MaskedInput mask='(99) 99999-9999' icon={FaPhone} name="phone" placeholder="Telefone Celular" />

            <h3 style={{ margin: '10px 0px', marginTop: '25px' }}> Modalidade de inscrição </h3>
            <CheckboxInput name="inscricao_1" setCallBack={(value) => { setInscricao1(value || '') }} placeholder="" options={[

              { id: 'Seminário de Internacionalização', value: 'Seminário de Internacionalização', label: 'Seminário de Internacionalização', sub_title: 'Não permite a possibilidade de inscrição de resumo' },


            ]} />





            <CheckboxInput name="inscricao_2" setCallBack={(value) => { console.log(value); setInscricao2(value || '') }} placeholder="" options={[

              { id: 'Mostra de Ensino, Pesquisa e Extensão', value: 'Mostra de Ensino, Pesquisa e Extensão', label: 'Mostra de Ensino, Pesquisa e Extensão' },


            ]} />




            {inscricao2 === 'Mostra de Ensino, Pesquisa e Extensão' && <> <p style={{ margin: '10px 0px', marginTop: '25px' }}> Tipo de inscrição </p><CheckboxInput unique name="extra_2" setCallBack={(value) => { console.log(value); setExtra2(value || '') }} placeholder="Tipo" options={[

              {
                value: 'Submissão de trabalhos', id: 'Submissão de trabalhos',
                label: 'Submissão de trabalhos', sub_title: '(Exclusivo para bolsistas e voluntários dos Programas de Ensino, Pesquisa e Extensão conforme indicado no Regulamento para Inscrição de Trabalhos para a VIII Mostra de Ensino, Pesquisa e Extensão.'
              },
              { id: 'Ouvintes', value: 'Ouvintes', label: 'Ouvintes' },


            ]} /></>}


            <SelectSimple name="extra_1" setCallback={(value) => { setExtra1(value || '') }} label="Categoria" options={
              [
                { value: 'Estudante de graduação', label: 'Estudante de graduação' },
                { value: 'Estudante de pós-graduação', label: 'Estudante de pós-graduação' },
                { value: 'Professor/pesquisador', label: 'Professor/pesquisador' },
                { value: 'Técnico-administrativo', label: 'Técnico-administrativo' },
                { value: 'Profissional', label: 'Profissional' },

              ]} />



            {category1Options && category1Options?.length > 0 && <SelectSimple setCallback={(value) => setCategory1Value(value)} name="extra_3" options={category1Options} label="Subcategoria : Seminário de Internacionalização" />}


            {category1Value === 'Outro? Qual?' && <Input name="extra_3" placeholder="Qual?" />}


            {extra1 === 'Estudante de pós-graduação' || category === 'Estudante de pós-graduação' ? <SelectSimple name="instituicao" options={[
              { label: 'Biociências', value: 'Biociências' },
              { label: 'Ciências da Nutrição', value: 'Ciências da Nutrição' },
              { label: 'Ciências da Reabilitação', value: 'Ciências da Reabilitação' },
              { label: 'Ciências da Saúde', value: 'Ciências da Saúde' },
              { label: 'Enfermagem', value: 'Enfermagem' },
              { label: 'Ensino na Saúde', value: 'Ensino na Saúde' },
              { label: 'Hepatologia', value: 'Hepatologia' },
              { label: 'Patologia', value: 'Patologia' },
              { label: 'Pediatria', value: 'Pediatria' },
              { label: 'Psicologia e Saúde', value: 'Psicologia e Saúde' },
              { label: 'Saúde da Família', value: 'Saúde da Família' },
              { label: 'Tecnologia em Informação e Gestão em Saúde', value: 'Tecnologia em Informação e Gestão em Saúde' },
              { label: 'Atenção em Terapia Intensiva', value: 'Atenção em Terapia Intensiva' },
              { label: 'Oncohematologia', value: 'Oncohematologia' },
              { label: 'Atenção ao Câncer Infantil', value: 'Atenção ao Câncer Infantil' },
              { label: 'Física Médica', value: 'Física Médica' },
              { label: 'Outro. Qual?', value: 'Outro. Qual?' },
            ]} label="Nome do Programa" /> : <></>}



            <SelectSimple name="company" setCallback={(value) => setCompany(value)} options={category === 'Profissional' ? [{ label: 'Autônomo', value: 'Autônomo' }, ...universidades] : universidades} label="Instituição" />
            {company === 'Outro? Qual?' && <Input name="company" placeholder="Qual?" />}
            <p style={{ fontSize: '12px', marginBottom: '20px', marginTop: '10px' }}>*Profissional sem vínculo universitário favor colocar "Autônomo" na opção "Instituição".</p>


            <p style={{ margin: '10px 0px' }}>Endereço completo</p>
            <SelectSimple setCallback={(value) => setCountryISO(value)} options={countries} name="country" label={translate('Pais')} />
            {countryISO === 'BR' ?
              <MaskedInput

                setCallback={getCepFromApi}
                mask="99999999"
                name="zipcode"
                placeholder={translate('CEP')}
              /> : <Input name="zipcode" placeholder={translate('ZIP Code')} />}
            <Input name="address" placeholder={translate('Endereço')} />
            <Input name="address_number" placeholder={translate('Número / Apartamento')} />
            <Input name="neighborhood" placeholder={translate('Bairro')} />
            <Input name="city" placeholder={translate('Cidade')} />
            {countryISO === 'BR' ? <MaskedInput mask="aa" name="state" placeholder={translate('Estado')} /> :

              <Input name="state" placeholder={translate('Província / Região')} />}

            {withPassword && (
              <Input
                icon={FiLock}
                name="password"
                type="password"
                placeholder="senha"
              />
            )}

            <CheckboxInput name="term" options={[{ value: 'Li e aceito a política de privacidade do evento X Mostra de Ensino, Pesquisa e Extensão', label: 'Li e aceito a política de privacidade do evento X Mostra de Ensino, Pesquisa e Extensão', id: 'Li e aceito a política de privacidade do evento X Mostra de Ensino, Pesquisa e Extensão' }]} />
            <CheckboxInput name="extra_4" options={[{ value: 'Aceito receber as comunicações da FUNDMED', id: 'Aceito receber as comunicações da FUNDMED', label: 'Aceito receber as comunicações da FUNDMED' }]} />
            <CheckboxInput name="extra_5" options={[{ value: 'Aceito receber as comunicações da UFCSPA', id: 'Aceito receber as comunicações da UFCSPA', label: 'Aceito receber as comunicações da UFCSPA' }]} />


            <div style={{ marginBottom: '10px', padding: '15px', borderRadius: '10px' }}>


              <p
                style={{
                  padding: '10px',
                  background: 'none',
                  margin: '5px auto',
                  color: '#333',
                  fontSize: '12px',
                  textAlign: 'left',
                }}
              >

                Fico ciente de que, ao realizar a inscrição no X Mostra de Ensino, Pesquisa e Extensão da UFCSPA, estarei autorizando a UFCSPA a utilizar meus dados pessoais para o cumprimento da finalidade da coleta, ou seja, viabilizar a participação no evento, conforme sua Política de Privacidade, disponível neste site, bem como para gestão de relacionamento, marketing e divulgação do X Mostra de Ensino, Pesquisa e Extensão da UFCSPA.
                <br /><br />
                Autorizo ainda o uso da minha imagem, captada durante a realização do evento, exclusivamente para a divulgação da repercussão o X Mostra de Ensino, Pesquisa e Extensão da UFCSPA.  <br /><br />
                <br /><br />


                {'Ao me cadastrar, afirmo que li a'}
                {' '}
                <strong>
                  <Link
                    style={{ display: 'inline', color: '#333' }}
                    to="/termos"
                    target="_BLANK"
                  >
                    {'Política de Privacidade'}
                    {' '}
                  </Link>
                  {' '}
                </strong>
                {'desta plataforma.'}
              </p>
            </div>
            <aside style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <ReCAPTCHA ref={recaptchaRef} sitekey={recaptchaKey} />

            </aside>  <aside style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <button className="blueButtonBig" type="submit">
                FINALIZAR INSCRIÇÃO


              </button>
            </aside>
          </Form>
        </>
        )}
      </aside>
    </>
  );
};

export default FormContainer;
