import styled from 'styled-components';
import { ContainerRef } from '../styles';

export const Container = styled(ContainerRef)`

>div{
    width: 100% !important;
    margin-top: 20px  !important;
   .ck-editor__editable{
    min-height: 300px;
   }

    p,strong,i {
        color: #000 !important;
    }
}
`;