import React from 'react';
import { FaEye, FaIntercom, FaPrint, FaRadiationAlt } from 'react-icons/fa';
import CopyToClipboard from '../../../../../core/components/CopyToClipboard';
import { urlWebsite } from '../../../../../../../config';
import { useSocket } from '../../../../../../../hooks/Socket';
import { useModal } from '../../../../../../../hooks/Modal';
import Review from '../../../modules/Review';
import api from '../../../../../../../services/api';
import { usePrinter } from '../../../../../../../hooks/PrinterHook';

interface ICreateModule {
    lineData: Record<string, any>;
}

export default function PrintModule({
    lineData,
}: ICreateModule): JSX.Element {

    const { addModal } = useModal();
    const { addPrint, printIt } = usePrinter();

    const printReview = async (line) => {

        const submission = await api.get(`/get-submission-selection-by-id/${line.project_id}/${line.cientific_selective_id_response?._id}`);
        const mypaper = await api.get(`/get-paper/${line._id?.toString()}`);

        addPrint([<div className='allBlack' style={{ padding: '50px' }}>
            <h2 style={{ color: '#000' }}>{mypaper?.data?.title}</h2>
            <p style={{ color: '#000', marginTop: '15px' }} dangerouslySetInnerHTML={{ __html: mypaper?.data?.short_description }} />
            <p style={{ color: '#000', marginTop: '15px' }}><strong>Autor:</strong> {mypaper?.data?.author}</p>
            <p style={{ color: '#000', marginTop: '15px' }}><strong>Coautores:</strong> {mypaper?.data?.nested_co_authors}</p>
            <p style={{ color: '#000', marginTop: '15px' }}><strong>Orientador:</strong> {mypaper?.data?.orientator}</p>
            <p style={{ color: '#000', marginTop: '15px' }}><strong>Palavras-chave:</strong> {mypaper?.data?.nested_keywords}</p>
        </div>])

        addModal({
            theme: 'whiteModalMedium', title: 'Trabalho submetido', content: <>
                <button onClick={() => printIt()} className='defaultButtonReverse'>Imprimir</button>
            </>
        });
    }


    const moduleCreate = (): JSX.Element => {

        return <div onClick={() => printReview(lineData)} style={{ cursor: 'pointer', width: '30px', height: '30px', display: 'flex', alignItems: 'center', justifyContent: 'center', borderRadius: '50%', border: '1px solid #333', margin: '5px' }}>
            <FaPrint className="hover" style={{ cursor: 'pointer' }} size={20} /></div>;
    }



    return moduleCreate();
}
