
import React, { useEffect, useState } from 'react';
import { FaCalendar, FaCalendarCheck, FaCalendarDay, FaMapMarker } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import { scrollTo } from '../../../../components/Menu';
import { urlWebsite } from '../../../../config';
import { useConfiguration } from '../../../../hooks/Configuration';
import { useLanguage } from '../../../../hooks/Language';
import {

    Capa, Container,



} from '../Themes/SinglePageTheme/styles';

const HomeApplication = () => {
    const { configuration, projectHash } = useConfiguration();
    const { translate } = useLanguage();

    const [theme, setTheme] = useState({
        titleColor: configuration?.current_event_id_response?.theme?.title_color || '#fff',
        textColor: configuration?.current_event_id_response?.theme?.text_color || '#fff',
        buttonColor: configuration?.current_event_id_response?.theme?.button_color || '#333',
        buttonBackground: configuration?.current_event_id_response?.theme?.button_background || '#3FB8EB',
    })


    useEffect(() => {
        setTheme({
            titleColor: configuration?.current_event_id_response?.theme?.title_color || '#fff',
            textColor: configuration?.current_event_id_response?.theme?.text_color || '#fff',
            buttonColor: configuration?.current_event_id_response?.theme?.button_color || '#333',
            buttonBackground: configuration?.current_event_id_response?.theme?.button_background || '#3FB8EB',
        })
    }, [configuration])


    return <>
        <div style={{ width: '100%', background: '#06496E', boxShadow: '0px 4px 4px 0px rgba(0,0,0,0.25)', zIndex: 1000, padding: '50px 20px' }}>
            <Container className='capa'  >
                <Capa style={{ flexDirection: 'row', justifyContent: 'center', alignItems: 'stretch' }}>


                    <div className='header column column-start headerLeft ' style={{ padding: '0px', maxWidth: '400px' }} >

                        <div className='column column-start full-width'>
                            <p className='color-white medium p-title'> DISCUSSÕES SOBRE </p>
                            <p className='color-white medium  p-title'>O FUTURO DO</p>
                            <div className='space-between full-width'> <p className='color-white medium  p-title'>CUIDADO</p><p className='color-white medium  p-title'>/ /</p> </div>

                        </div>


                    </div>

                    <div className=' column column-start' style={{ minWidth: 'auto', maxWidth: '400px', display: 'flex', borderRadius: '15px', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', padding: '0px' }}>
                        <div className='column column-start'>


                            <p className='color2' style={{ width: 'auto' }}>


                                VÃO ESTAR EM PAUTA NO MAIOR
                                <br />EVENTO DO BRASIL SOBRE SAÚDE
                                <br />INTEGRAL NO TRABALHO
                            </p>


                        </div>
                    </div>




                </Capa>
            </Container>
        </div>
        <div className='gradient1' style={{ width: '100%', paddingBottom: '100px' }}>
            <Container className='capa-no-padding'>

                <Capa style={{ flexDirection: 'row', justifyContent: 'center' }}>


                    <div className='header column column-start headerLeft ' style={{ padding: '0px 50px', maxWidth: '400px' }} >

                        <img src="/apoio/mulher.png" className='full-width' />

                    </div>

                    <div className='header column column-start' style={{ minWidth: 'auto', maxWidth: '400px', display: 'flex', borderRadius: '15px', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', padding: '0px  50px', paddingTop: '50px' }}>
                        <div className='column column-start'>


                            <p className='color-white' style={{ width: 'auto' }}>


                                Realizado anualmente pelo SESI-RS, o <strong className='color-white semibold'>X Mostra de Ensino, Pesquisa e Extensão da UFCSPA</strong> tem o objetivo de impulsionar o diálogo sobre a saúde integral do trabalhador, dentro e fora da indústria.

                                <br /><br />
                                A 4ª edição do evento discutirá o tema <strong className='color-white semibold'>“Expandindo as fronteiras do cuidado. Um olhar para os impactos no futuro da saúde e economia”</strong>. Especialistas renomados trarão reflexões sobre as mudanças no estilo de vida das pessoas, novas demandas de cuidado, os impactos na economia, no mundo corporativo e nos modelos atuais de saúde.

                                <br />

                                <br />
                            </p>

                        </div>
                    </div>




                </Capa>


            </Container >

            <Container className='capa-no-padding'>

                <Capa style={{ flexDirection: 'row', justifyContent: 'center', paddingTop: '25px' }}>




                    <div className='header column  full-width' style={{ minWidth: 'auto', display: 'flex', borderRadius: '15px', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', padding: '0px  50px' }}>
                        <div className='column column-start gap-sm'>


                            <p className='color-white text-center full-width' style={{ paddingTop: '45px', paddingBottom: '10px' }}>


                                Profissionais da saúde, da segurança no trabalho, <br />empresários, estudantes, comunidade em geral:

                            </p>

                            <p className='medium midTitle  text-center color2'>
                                SE VOCÊ SE CONECTA COM O UNIVERSO <br /> DA SAÚDE, NÃO PODE PERDER.
                            </p>

                            {/*  <div className='row-to-column gap-sm full-width' style={{ paddingTop: '35px' }}>


                                <Link className='full-width' to="/presencial" style={{ maxWidth: '304px' }}>  <button className='greenButtonBig bold full-width'>

                                    <strong> PARTICIPAÇÃO PRESENCIAL</strong>
                                </button>
                                </Link>
                                <Link className='full-width' to="/app/evento/inscricao" style={{ maxWidth: '304px' }}>  <button className='greenButtonBig  bold  full-width'>

                                    <strong>PARTICIPAÇÃO ONLINE</strong>
                                </button>
                                </Link>

                         </div>
  */}
                        </div>
                    </div>




                </Capa>


            </Container >
        </div>
    </>
}

export default HomeApplication;