import React, { useEffect, useRef, useState } from 'react';
import { Form } from '@unform/web';
import { FiMail, FiUser, FiLock, FiMap } from 'react-icons/fi';
import { Link, useParams } from 'react-router-dom';


import { MdError } from 'react-icons/md';

import * as Yup from 'yup';
import ReCAPTCHA from 'react-google-recaptcha';
import { FormHandles } from '@unform/core';
import Input from '../../../../core/components/Forms/Input';
import Button from '../../../../core/components/Forms/Button';
import Loader from '../../../../../../components/Loader';
import MaskedInput from '../../../../core/components/Forms/MaskedInput';
import { ResponseMessage } from '../../../../../../components/ResponseMessage';
import getValidationErrors from '../../../../../../utils/getValidationErrors';
import { Error, Separator, Container, FixedResponse } from './styles';
import SelectSimple from '../../../../core/components/Forms/SelectSimple';
import Whatsapp from '../../../../../../components/WhatsAppCompartilhar';

import JsonListMultiple from '../../../../core/components/Forms/JsonListMultiple';

import api from '../../../../../../services/api';

import CheckboxInput from '../../../../core/components/Forms/CheckboxInput';
import { useLanguage } from '../../../../../../hooks/Language';
import TextareaInput from '../../../../core/components/Forms/TextareaInput';
import { areas } from './areas';
import CheckboxIncluder from './components/CategoryChange';

import { useToast } from '../../../../../../hooks/Toast';
import Review from '../../modules/Review';
import { rgb } from 'polished';
import { useConfiguration } from '../../../../../../hooks/Configuration';
import RichText from '../../../../core/components/Forms/RichText';
import { ods } from './ods';
import JsonInputList from '../../../../core/components/Forms/JsonInputList';
import { defaultProjectHash } from '../../../../../../config';
import ArrayInputIncluder from '../../../../core/components/Forms/ArrayInputIncluder';
import { useModal } from '../../../../../../hooks/Modal';
import { useAuth } from '../../../../../../hooks/Auth';

interface IRecoverFormData {
  [key: string]: any
}

interface ICaptcha {
  getValue(): string;
}

interface IOption {
  label: string;
  value: string;
  id: string;
}

interface IParam {
  id: string;
  hash: string;
  projectHash: string;
}

const Submission: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [errorMessage, setErrorMessage] = useState<Array<string>>([]);
  const [withPassword, setWithPassword] = useState(true);
  const formRef = useRef<FormHandles>(null);
  const { configuration } = useConfiguration();
  const { addModal } = useModal();
  const { id, hash, projectHash = configuration?.url } = useParams<IParam>();

  const [submissionData, setSubmissionData] = useState<Record<string, any>>({});
  const [submission, setSubmission] = useState<Record<string, any>>({})
  const recaptchaRef = useRef(null);
  const { translate } = useLanguage();
  const getCaptchaValue = (elem: ICaptcha): string => {
    return elem.getValue();
  };
  const { addToast } = useToast();
  const [haveAgency, setHaveAgency] = useState('Não');
  const [activeReview, setActiveReview] = useState(false);
  const { user } = useAuth();
  const [category, setCategory] = useState('');
  const [area, setArea] = useState('');
  const [subarea, setSubarea] = useState('');
  const [countryISO, setCountryISO] = useState('BR');

  const [categoryOptions, setCategoryOptions] = useState<Array<IOption>>([]);
  const [areaOptions, setAreaOptions] = useState<Array<IOption>>([]);
  const [subareaOptions, setSubareaOptions] = useState<Array<IOption>>([]);

  const [breakInfo, setBreakInfo] = useState('');

  const cleanData = (string) => {

    if (!string) { return '0'; }

    let newString = string;

    const tagsAndNbspToReplace = /<\/?(p|i|strong|h2|h1|h3)>|&nbsp;/gi;
    const modifiedText = string.replace(tagsAndNbspToReplace, '');



    return modifiedText?.length;

  }

  const validation = (submissionInfo, data) => {

    const validate: Array<string> = [];
    console.log(data)
    submissionInfo?.available_blocks?.map(available => {

      if (available.hash === 'extra_categories') {
        if (data?.extra_categories?.length < 1) {
          validate.push(`Selecione ao menos 1 ODS`);
        }
        else if (data?.extra_categories?.length > 3) {
          validate.push(`Selecione no máximo 3 ODS`);
        }
      }
      else if (available.hash === 'language') {

      }
      else if (available.hash === 'award') {
        if (data?.award?.length < 1) {
          validate.push(`Informe se deseja participar do Prêmio Divulgação Científica`);
        }
        if (data?.award === 'Sim' && !data?.award_link) {
          validate.push(`Para participar do Prêmio Divulgação Científica, preencha o seu link de apresentação`);
        }
      }
      else if (available.hash === 'resume') {
        if (!data?.title) {
          validate.push(`Preencha o título`);
        }
        if (!data?.short_description) {
          validate.push(`Preencha o resumo do trabalho`);
        }
        if (data?.keywords?.length < 1) {
          validate.push(`Insira ao menos 1 palavra-chave`);
        }
        if (cleanData(data?.short_description) > 2200) {
          validate.push('Se atente ao limite máximo de 2000 caracteres no resumo.');
        }

      }
      else if (submissionInfo?.[`${available.hash}_fields`]) {

        submissionInfo?.[`${available.hash}_fields`]?.map(info => {

          if (info.mandatory === 'yes' && !data?.[info.hash]) {
            validate.push(`Preencha o campo ${info.title}`);
          }




        })


      }

      else if (available.hash === 'categories') {
        if (data?.category && data?.area && data?.subarea) { }
        else if (data?.category && data?.area) {
          const indexCategory = submissionInfo.categories?.findIndex(cat => cat.title === data.category);

          const indexArea = submissionInfo.categories?.[indexCategory]?.options?.findIndex(areainfo => areainfo.title === data.area);

          if (submissionInfo.categories?.[indexCategory]?.options?.[indexArea]?.options?.length > 0) {

            validate.push('Selecione uma subárea.');

          }


        }
        else if (data?.category) {
          const indexCategory = submissionInfo.categories?.findIndex(cat => cat.title === data.category);

          if (submissionInfo.categories?.[indexCategory]?.options?.length > 0) {

            validate.push('Selecione uma área.');

          }


        }
        else {
          validate.push('Selecione uma categoria.');
        }
      }

    })

    return validate;


  }





  const [initialData, setInitialData] = useState<Record<string, any>>({})


  const loadPaper = async () => {
    if (id) {
      const response = await api.get(`/getPaper/${id}`);

      if (response) {
        const { category: cat, area: a, subarea: s } = response.data;
        const data = { ...response.data };
        delete data.category;
        delete data.area;
        delete data.subarea;



        setInitialData(data);
        setCategory(cat);
        setArea(a);
        setSubarea(s);

      }
    }
  }

  const loadSubmission = async () => {

    const checkPaper = await api.get(`/check-papers-limit/${projectHash}/${hash}`);
    const response = await api.get(`/get-submission-selection/${projectHash}/${hash}`);

    const checkException = response?.data?.quantity_exceptions ? response?.data?.quantity_exceptions?.findIndex(cpf => cpf === user?.document_number) : -1;

    if (checkPaper?.data?.status === 'closed') {

      setBreakInfo('closed');
    }

    if (checkException < 0) {
      if (checkPaper?.data?.status === 'user-limit' && !id) {
        setBreakInfo('user-limit');
      }
    }
    if (checkPaper?.data?.status === 'global-limit' && !id) {
      setBreakInfo('global-limit');
    }



    if (response?.data) {
      setSubmission(response?.data);
      loadPaper();
    }

  }

  useEffect(() => {

    const areaOptions: Array<IOption> = [];

    areas.map(a => {
      areaOptions.push({ label: a.title, value: a.title, id: a.title });
    })

    setCategoryOptions(areaOptions);

    loadSubmission();


  }, [hash])

  useEffect(() => {

    const categoryIndex = areas.findIndex(a => a.title === category);

    if (categoryIndex < 0) {
      setAreaOptions([]);
      setSubareaOptions([]);
    }
    else {

      const newAreas: Array<IOption> = [];

      areas?.[categoryIndex]?.lists?.map(c => {
        newAreas.push({ label: c.title, value: c.title, id: c.title })
      })
      setAreaOptions(newAreas);
      setSubareaOptions([]);
    }

  }, [category])

  useEffect(() => {

    const categoryIndex = areas.findIndex(a => a.title === category);

    if (categoryIndex < 0) {
      setAreaOptions([]);
      setSubareaOptions([]);
    }
    else {

      const areaIndex = areas?.[categoryIndex]?.lists.findIndex(a => a.title === area);

      if (areaIndex < 0) {
        setSubareaOptions([]);
      }
      else {


        const newSubareas: Array<IOption> = [];

        areas?.[categoryIndex]?.lists[areaIndex]?.lists?.map(c => {
          newSubareas.push({ label: c.title, value: c.title, id: c.title })
        })
        setSubareaOptions(newSubareas);
      }
    }

  }, [area])


  const checkCategoria = (category, area) => {

    const categoryList = area.finIndex(a => a.title === category);

    if (!category) {
      addToast({ title: 'Campo categoria de submissão é obrigatório' });
      setLoading(false);
      return;
    }

  }

  const renderField = (hashField, sub) => {


    if (hashField === 'categories') {

      //  return <CheckboxIncluder category={category} area={area} subarea={subarea} setSubarea={(value) => setSubarea(value)} setArea={(value) => setArea(value)} setCategory={(value) => setCategory(value)} name="category" options={sub?.categories} placeholder="Categorias" />
      return <CheckboxIncluder category={category} area={area} subarea={subarea} setSubarea={(value) => setSubarea(value)} setArea={(value) => setArea(value)} setCategory={(value) => setCategory(value)} name="category" options={sub.categories} placeholder="Categorias" />


    }
    else if (hashField === 'extra_categories') {

      const options = sub?.extra_categories_fields ? sub?.extra_categories_fields?.reduce((prev, item) => {

        prev.push({ value: item?.title, label: item?.title, id: item.title });

        return prev;

      }, []) : [];

      return <><div style={{ color: '#333', width: '100%', }}>
        <strong style={{ color: '#333', width: '100%', textAlign: 'center', display: 'block' }}>OBJETIVOS DE DESENVOLVIMENTO SUSTENTÁVEL (ODS) DA AGENDA 2030<br />
          DA ORGANIZAÇÃO DAS NAÇÕES UNIDAS (<a href="https://brasil.un.org/pt-br/sdgs" target="_BLANK" style={{ color: '#333' }}>https://brasil.un.org/pt-br/sdgs</a>)</strong>

      </div>

        {/*  <a href="" target="_BLANK" style={{ color: '#333' }}>Veja AQUI a lista de palavras-chave por ODS!</a> */}
        <CheckboxInput name={'extra_categories'} options={options} placeholder={'ODS'} /></>


    }
    else if (hashField === 'coauthor') {

      const options = sub?.coautor_fields ? sub?.coautor_fields?.reduce((prev, item) => {

        prev.push({ label: item?.title, name: item?.hash, type: item.type });

        return prev;

      }, []) : [];


      return <JsonInputList show={['name']} name={'co_authors'} placeholder="Coautores" list={options} />


    }
    else if (sub?.[`${hashField}_fields`]) {

      const elements: Array<JSX.Element> = [];

      sub?.[`${hashField}_fields`]?.map(field => {

        if (field?.hash) {
          if (field?.type === 'input') {
            elements.push(<Input name={field?.hash} placeholder={field?.title} />);
          }

          else if (field?.type === 'checkbox') {
            const options = field?.options ? field?.options?.reduce((prev, string) => {

              prev.push({ value: string, label: string, id: string });

              return prev;

            }, []) : [];

            elements.push(<CheckboxInput unique options={options} name={field?.hash} preText={field?.title} />);
          }
          else if (field?.hash === 'keywords') {
            elements.push(<JsonInputList show={['keyword']} name="keywords" placeholder="Palavras-chave" list={[
              { name: 'keyword', label: "Palavra-chave", type: 'input' },

            ]} />)
          }

          else if (field?.type === 'array_includer') {
            elements.push(<ArrayInputIncluder name={field?.hash} placeholder={field?.title} />);
          }

          else if (field?.type === 'jsonInputList') {
            elements.push(<ArrayInputIncluder name={field?.hash} placeholder={field?.title} />);
          }
          else if (field?.type === 'richText') {
            elements.push(<RichText name={field?.hash} placeholder={field?.title} />);
          }
        }

      })


      return <> {elements}</>
    }

  }

  const renderBlock = (block, number) => {


    return <Separator>
      <label>{number} - {block?.title}</label>

      {renderField(block?.hash, submission)}

    </Separator>


  }

  const handleNewLogin = async (data: IRecoverFormData) => {
    setLoading(true);

    try {
      formRef.current?.setErrors({});



      if (!activeReview) {

        data.category = category || '';
        data.area = area || '';
        data.subarea = subarea || '';
        data.language = data?.language?.[0] || '';
        data.award = data?.award?.[0] || '';
        data.nested_keywords = data.keywords?.reduce((prev, item) => { return prev !== '' ? `${prev}, ${item.keyword}` : item.keyword }, '');
        data.nested_co_authors = data.co_authors?.reduce((prev, item) => { return prev !== '' ? `${prev}, ${item.name}` : item.name }, '');


      }


      const validationValue = validation(submission, data);



      if (validationValue?.length > 0) {
        setErrorMessage(validationValue);
        setLoading(false);
        return;
      }





      if (!activeReview) {
        setSubmissionData(data);
        setActiveReview(true);
        setLoading(false);
        return;
      }

    } catch (err) {


      if (err instanceof Yup.ValidationError) {
        const errors = getValidationErrors(err);
        if (formRef && formRef.current && formRef.current.setErrors) {
          formRef.current.setErrors(errors);
        }
        setLoading(false);

        const errorsString = Object.keys(errors).reduce(
          (prev: Array<string>, key: string): Array<string> => {
            prev = [...prev, errors[key]];
            return prev;
          },
          [],
        );

        setErrorMessage(errorsString);
        setLoading(false);
        return;
      }

      setErrorMessage([
        err?.response?.data?.message ||
        err?.response?.data?.error ||
        'Erro ao gerar seu cadastro',
      ]);
      setLoading(false);
    }

  }

  const sendSubmission = async (submissionInfo) => {
    setLoading(true);
    try {

      if (id) {
        await api.put(`/updatePaper/${id}`, {
          ...submissionData,
          project_id: configuration?.url,
          event_id: configuration?.url,


        });
      }
      else {
        await api.post(`/submit-my-papers`, {
          ...submissionData,
          cientific_selective_id: submissionInfo?._id?.toString(),
          project_id: configuration?.url,
          event_id: configuration?.url


        });
      }


      setSuccess(true);
      setLoading(false);
      window?.scrollTo(0, 0);
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const errors = getValidationErrors(err);
        if (formRef && formRef.current && formRef.current.setErrors) {
          formRef.current.setErrors(errors);
        }
        setLoading(false);

        const errorsString = Object.keys(errors).reduce(
          (prev: Array<string>, key: string): Array<string> => {
            prev = [...prev, errors[key]];
            return prev;
          },
          [],
        );

        setErrorMessage(errorsString);
        setLoading(false);
        return;
      }

      addModal({
        key: 'aaa',
        title: 'Erro', theme: 'whiteModalMini', content: <p style={{ color: '#333' }}>{err?.response?.data?.message ||
          err?.response?.data?.error ||
          'Erro ao gerar seu cadastro'}</p>
      })
      setLoading(false);
    }
    setLoading(false);
  };

  if (id && submission?.allow_edit === 'no') {
    return <Container style={{ width: '100%' }}>
      <div style={{ background: 'rgb(250,100,0)', padding: '20px', margin: '20px auto', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
        <strong style={{ width: '100%', textAlign: 'center' }}>A edição não está autorizada pela organização.</strong>
        <Link to={'/trabalhos'}><button className='defaultButtonReverse'> Voltar </button></Link>
      </div></Container>
  }


  if (breakInfo === 'closed') {
    return <Container style={{ width: '100%' }}>
      <div style={{ background: 'rgb(250,100,0)', padding: '20px', margin: '20px auto', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
        <strong style={{ width: '100%', textAlign: 'center' }}>Período de submissão de trabalhos encerrado</strong>
        <Link to={'/trabalhos'}><button className='defaultButtonReverse'> Voltar </button></Link>
      </div></Container>

  }

  if (breakInfo === 'user-limit') {
    return <Container style={{ width: '100%' }}>
      <div style={{ background: 'rgb(250,100,0)', padding: '20px', margin: '20px auto', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
        <strong style={{ width: '100%', textAlign: 'center', color: '#fff' }}>Você chegou ao número limite de submissões por cadastro</strong>
        <Link to={'/trabalhos'}><button className='defaultButtonReverse'> Voltar </button></Link>
      </div></Container>

  }

  if (breakInfo === 'global-limit') {
    return <Container style={{ width: '100%' }}>
      <div style={{ background: 'rgb(250,100,0)', padding: '20px', margin: '20px auto', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
        <strong style={{ width: '100%', textAlign: 'center' }}>O número máximo de submissões para este projeto foi alcançado. Submissões encerradas.</strong>
        <Link to={'/trabalhos'}><button className='defaultButtonReverse'> Voltar </button></Link>
      </div></Container>

  }

  if (submission?.allow_edit === 'no') {
    return <Container style={{ width: '100%' }}>
      <div style={{ background: 'rgb(250,100,0)', padding: '20px', margin: '20px auto', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
        <strong style={{ width: '100%', textAlign: 'center' }}>A edição não está autorizada pela organização.</strong>
        <Link to={'/trabalhos'}><button className='defaultButtonReverse'> Voltar </button></Link>
      </div></Container>
  }

  if (initialData?.orientator_approval === 'Sim') {
    return <Container style={{ width: '100%' }}>
      <div style={{ background: 'rgb(250,100,0)', padding: '20px', margin: '20px auto', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
        <strong style={{ width: '100%', textAlign: 'center' }}>Este trabalho já foi aprovado pelo orientador</strong>
        <Link to={'/trabalhos'}><button className='defaultButtonReverse'> Voltar </button></Link>
      </div></Container>
  }

  if (id === '99999') {
    return <Container style={{ width: '100%' }}>
      <div style={{ background: 'rgb(250,100,0)', padding: '20px', margin: '20px auto', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
        <strong style={{ width: '100%', textAlign: 'center' }}>Período de submissão de trabalhos encerrado</strong>
        <p>Clique no botão "Meus Trabalhos" para adicionar seu pôster</p>
      </div></Container>
  }

  if (configuration.allow_cientific_submission === 'no') {

    return <Container style={{ width: '100%' }}>
      <div style={{ background: 'rgb(250,100,0)', padding: '20px', margin: '20px auto', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
        <strong style={{ width: '100%', textAlign: 'center', color: '#fff' }}>Período de submissão de trabalhos encerrado</strong>
        {configuration.allow_cientific_poster_submission === 'yes' ? <p style={{ color: '#fff' }}>Clique no botão "Meus Trabalhos" para adicionar seu pôster</p> : <></>}
      </div></Container>

  }

  return (
    <>
      {loading === true && (
        <Loader message="Preparando seu cadastro..." position="fixed" />
      )}

      <aside style={{ width: '100%' }}>
        {success ? (
          <aside style={{ display: 'flex', width: '100%', justifyContent: 'center' }}> <ResponseMessage
            active
            type="success"
            title="Sucesso"
            description={
              <p style={{ color: '#333' }}>
                Trabalho submetido com sucesso!
                <br />



              </p>
            }
          /></aside>
        ) : (<Container style={{ width: '100%' }}>



          {initialData?.orientator_correction ? <p style={{ textAlign: 'center', marginTop: '30px', border: '1px solid #ddd', borderRadius: '5px', padding: '15px', color: '#333', width: '100%', maxWidth: '800px', marginBottom: '30px' }}><strong style={{ color: '#333' }}>Última correção solicitada:</strong><br />{initialData?.orientator_correction}</p> : <></>}

          <Form ref={formRef} onSubmit={handleNewLogin} initialData={initialData} style={{ maxWidth: '800px', padding: '40px 10px' }}>
            <h1 style={{ color: '#333' }}>{submission?.title}</h1>
            <h1 style={{ color: '#333' }}>Submissão de Trabalho</h1>
            <p style={{ color: '#fff', background: '#e46218', margin: '10px auto', padding: '10px' }}>Limite de {submission?.quantity_by_user} {parseInt(submission?.quantity_by_user, 10) > 1 ? 'trabalhos' : 'trabalho'} por inscrito</p>
            {initialData.observations && <Separator>
              <p dangerouslySetInnerHTML={{ __html: initialData.observations }} />
            </Separator>}

            {submission?.available_blocks?.map((block, index) => {

              return renderBlock(block, index + 1);

            })}


            {errorMessage && (
              <Error>
                {errorMessage.map((item, index) => (
                  <div key={`error${index}`}>
                    <MdError />
                    {item}
                  </div>
                ))}
              </Error>
            )}

            <aside style={{ display: 'flex', justifyContent: 'center' }}>
              <Button style={{ width: '100%' }} type="submit">
                Submeter
              </Button>
            </aside>


          </Form>
          <FixedResponse style={{ display: activeReview ? 'flex' : 'none' }}>
            <div>
              <h1>Revisão de dados</h1>

              <Review data={submissionData} submission={submission} />


              <button className="defaultButtonReverse" onClick={() => setActiveReview(false)} type="button" >Editar</button>
              <button className="defaultButton" onClick={() => sendSubmission(submission)}>Enviar</button>
            </div>

          </FixedResponse>
        </Container>
        )}
      </aside>


    </>
  );
};

export default Submission;

