import styled from 'styled-components';
import { shade } from 'polished';
import { defaultTheme } from '../../config';

export const Button = styled.button`
 
  height: 56px;

  border-radius: 54px;
  border: 0;
  padding: 5 10px;
  min-width: 140px;

  font-weight: 500;
  margin: auto;
  margin-top: 5px;
  font-size: 12px;

  transition: 0.2s all;
 
  border-radius: 54px;
  margin-right: 5px !important;
  margin-left: 5px !important ;
  box-shadow: rgb(7 13 28 / 25%) 0px 9px 90px;
 
`;
