import React, { useEffect, useRef, useState } from 'react';
import { Form } from '@unform/web';
import { FiMail, FiUser, FiLock, FiMap } from 'react-icons/fi';
import { Link, useParams } from 'react-router-dom';


import { MdError } from 'react-icons/md';

import * as Yup from 'yup';
import ReCAPTCHA from 'react-google-recaptcha';
import { FormHandles } from '@unform/core';
import Input from '../../../../components/Forms/Input';
import Button from '../../../../components/Forms/Button';
import Loader from '../../../../components/Loader';
import MaskedInput from '../../../../components/Forms/MaskedInput';
import { ResponseMessage } from '../../../../components/ResponseMessage';
import getValidationErrors from '../../../../utils/getValidationErrors';
import { Error, Separator, Container, FixedResponse } from './styles';
import SelectSimple from '../../../../components/Forms/SelectSimple';
import Whatsapp from '../../../../components/WhatsAppCompartilhar';

import JsonListMultiple from '../../../../components/Forms/JsonListMultiple';

import api from '../../../../services/api';
import { authTitle, defaultProjectHash, eventName, recaptchaKey, urlWebsite } from '../../../../config';
import getCep from '../../../../services/cep';
import { FaBuilding, FaGenderless, FaIdBadge, FaPhone } from 'react-icons/fa';
import CheckboxInput from '../../../../components/Forms/CheckboxInput';
import { useLanguage } from '../../../../hooks/Language';
import TextareaInput from '../../../../components/Forms/TextareaInput';
import { areas } from './areas';
import CheckboxIncluder from './components/CategoryChange';
import { modalidade } from './modalidade';
import { useToast } from '../../../../hooks/Toast';
import { useModal } from '../../../../hooks/Modal';
import CheckboxInputJSON from '../../../DashboardPages/core/components/Forms/CheckboxInputJson';
import { sub } from 'date-fns';
import { useConfiguration } from '../../../../hooks/Configuration';


interface IRecoverFormData {
  [key: string]: any
}

interface ICaptcha {
  getValue(): string;
}

interface IOption {
  label: string;
  value: string;
  id: string;
}

const AvaliatorSubscription: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [errorMessage, setErrorMessage] = useState<Array<string>>([]);
  const [withPassword, setWithPassword] = useState(true);
  const formRef = useRef<FormHandles>(null);
  const { configuration } = useConfiguration();
  const { hash, projectHash = configuration?.url } = useParams<Record<string, any>>();
  const [submissionData, setSubmissionData] = useState<Record<string, any>>({});
  const recaptchaRef = useRef(null);
  const { translate } = useLanguage();
  const getCaptchaValue = (elem: ICaptcha): string => {
    return elem.getValue();
  };
  const { addToast } = useToast();
  const [haveAgency, setHaveAgency] = useState('Não');
  const [activeReview, setActiveReview] = useState(false);

  const [category, setCategory] = useState([]);
  const [area, setArea] = useState('');
  const [subarea, setSubarea] = useState('');
  const [countryISO, setCountryISO] = useState('BR');

  const [categoryOptions, setCategoryOptions] = useState<Array<IOption>>([]);
  const [areaOptions, setAreaOptions] = useState<Array<IOption>>([]);
  const [subareaOptions, setSubareaOptions] = useState<Array<IOption>>([]);

  const [initialData, setInitialData] = useState({});

  const [submission, setSubmission] = useState<Record<string, any>>();
  const [email, setEmail] = useState<string>('');

  const categoriesSession = submission?.available_blocks?.find(block => block?.hash === 'categories');
  const extraCategoriesSession = submission?.available_blocks?.find(block => block?.hash === 'extra_categories');

  const hours = submission?.avaliator_hour_fields?.reduce((prev, item) => {

    if (item?.options?.length > 0) {

      item?.options?.map(avaliableHour => {

        prev.push({ id: `${avaliableHour}`, label: `${avaliableHour}`, value: { hour: avaliableHour } });


      })

    }

    return prev

  }, [])


  const categoryValues = submission?.avaliator_categories?.reduce((prev, item) => {



    prev.push({ id: item?.title, label: item?.title, value: item.title });




    return prev

  }, [])


  const categories = submission?.categories?.reduce((prev, item) => {

    if (item?.options?.length > 0) {

      item?.options?.map(area => {

        if (area?.options?.length > 0) {

          area?.options?.map(subarea => {

            prev.push({ id: `${item?.title} > ${area?.title} > ${subarea}`, label: `${item?.title} > ${area?.title} > ${subarea}`, value: { category: item?.title, area: area?.title, subarea } });


          })

        }
        else {
          prev.push({ id: `${item?.title} > ${area?.title}`, label: `${item?.title} > ${area?.title}`, value: { category: item?.title, area: area?.title } });
        }

      })

    }
    else {
      prev.push({ id: item?.title, label: item?.title, value: { category: item?.title } });
    }

    return prev

  }, [])

  const extra_categories_fields = submission?.extra_categories_fields?.reduce((prev, item) => {

    if (item?.options?.length > 0) {

      item?.options?.map(area => {

        if (area?.options?.length > 0) {

          area?.options?.map(subarea => {

            prev.push({ id: `${item?.title} > ${area?.title} > ${subarea}`, label: `${item?.title} > ${area?.title} > ${subarea}`, value: { category: item?.title, area: area?.title, subarea } });


          })

        }
        else {
          prev.push({ id: `${item?.title} > ${area?.title}`, label: `${item?.title} > ${area?.title}`, value: { category: item?.title, area: area?.title } });
        }

      })

    }
    else {
      prev.push({ id: item?.title, label: item?.title, value: { category: item?.title } });
    }

    return prev

  }, [])

  const loadSubmission = async () => {


    const response = await api.get(`/get-submission-selection/${projectHash}/${hash}`);

    if (response?.data) {
      setSubmission(response?.data);

    }

  }


  useEffect(() => {

    const areaOptions: Array<IOption> = [];

    areas.map(a => {
      areaOptions.push({ label: a.title, value: a.title, id: a.title });
    })

    setCategoryOptions(areaOptions);

    loadSubmission();


  }, [hash])



  useEffect(() => {

    const areaOptions: Array<IOption> = [];

    areas.map(a => {
      areaOptions.push({ label: a.title, value: a.title, id: a.title });
    })

    setCategoryOptions(areaOptions);

  }, [])


  const box = (dataInfo, key = '') => {
    if (!dataInfo) {
      return <></>
    }
    return <>
      {dataInfo.map(d => <aside style={{ background: '#eee', padding: '5px', margin: '5px', color: '#333', width: 'auto' }}>{key === 'self' ? d || '' : d?.[key] || ''}</aside>)}
    </>
  }


  const checkCategoria = (category, area) => {

    const categoryList = area.finIndex(a => a.title === category);

    if (!category) {
      addToast({ title: 'Campo categoria de submissão é obrigatório' });
      setLoading(false);
      return;
    }

  }

  const handleNewLogin = async (data: IRecoverFormData) => {




    setLoading(true);


    console.log(data);


    if (data?.hours?.length < submission?.avaliator_hour_fields?.[0]?.minimun) {
      addToast({ title: `Selecione ao menos ${submission?.avaliator_hour_fields?.[0]?.minimun} horário(s)`, type: 'error' });
      setLoading(false);
      return;
    }

    if (categoriesSession?.status === 'yes' && data?.categories?.length < 1) {
      addToast({ title: `Selecione ao menos 1 ${categoriesSession?.avaliator_title || categoriesSession?.title}`, type: 'error' });
      setLoading(false);
      return;
    }
    /*
        if (extraCategoriesSession?.status === 'yes' && data?.extra_categories?.length < 1) {
          addToast({ title: `Selecione ao menos 1 ${extraCategoriesSession?.avaliator_title || extraCategoriesSession?.title}`, type: 'error' });
          setLoading(false);
          return;
        }
    */
    try {
      formRef.current?.setErrors({});


      if (data?.is_orientating?.length > 0) {
        data.is_orientating = data?.is_orientating.join(', ');
      }
      else {
        data.is_orientating = '';
      }



      const shape: Record<string, any> = {
        name: Yup.string().required('O campo Nome do autor é obrigatório'),
        document_number: Yup.string().required('O campo CPF é obrigatório'),
        email: Yup.string().required('O campo Email do autor é obrigatório'),
        password: Yup.string().required('O campo senha é obrigatório'),


      };


      if (submission?.avaliator_categories?.length > 0) {
        shape.category = Yup.string().required('Informe a sua categoria');
      }

      if (submission?.avaliator_is_orientating) {
        shape.is_orientating = Yup.string().required('Informe se você é orientador de alunos dos programas de IC, ITI, PID, Extensão');
      }




      const schema = Yup.object().shape(shape);

      await schema.validate(data, { abortEarly: false });



      if (!activeReview) {
        setSubmissionData(data);
        setActiveReview(true);
        setLoading(false);
        return;
      }




      const response = await api.post(`/signup-avaliator/${projectHash}/${hash}`, {
        ...data,
        project_id: projectHash

      });

      setSuccess(true);
      setLoading(false);
      window.scrollTo(0, 0)
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const errors = getValidationErrors(err);
        if (formRef && formRef.current && formRef.current.setErrors) {
          formRef.current.setErrors(errors);
        }
        setLoading(false);

        const errorsString = Object.keys(errors).reduce(
          (prev: Array<string>, key: string): Array<string> => {
            prev = [...prev, errors[key]];
            return prev;
          },
          [],
        );

        setErrorMessage(errorsString);

        return;
      }

      console.log(err);

      setErrorMessage([
        err?.response?.data?.message ||
        err?.response?.data?.error ||
        'Erro ao gerar seu cadastro',
      ]);
    }
    setLoading(false);
  };

  const [categoryValue, setCategoryValue] = useState('');
  const { addModal } = useModal();


  const [ended, setEnded] = useState(false);

  if (ended) {
    return <Container style={{ minHeight: '60vh', display: 'flex', alignItems: 'center' }}>

      <h2 style={{ background: 'rgb(0,14,64)', color: '#fff', padding: '15px', marginBottom: '15px' }}>Inscrições de avaliadores encerrada</h2>
      <Link to={`/app/${projectHash}/login-avaliador/${hash}`}><Button>Logar como avaliador</Button></Link>
    </Container>
  }

  return (
    <>
      {loading === true && (
        <Loader message="Preparando seu cadastro..." position="fixed" />
      )}

      <aside style={{
        width: '100%',
        padding: '20px'
      }}>
        {success ? (<div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
          <ResponseMessage
            active
            type="success"
            title="Sucesso"
            description={
              <><p style={{ color: '#333' }}>
                Cadastro realizado com sucesso!
                <br />


              </p>
                <aside style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
                  <Link to={`/app/${projectHash}/login-avaliador/${hash}`} style={{ width: 'auto' }}><button className="defaultButton">Acessar área do avaliador</button></Link>
                </aside></>
            }
          /></div>
        ) : (<Container>
          <Form ref={formRef} initialData={initialData} onSubmit={handleNewLogin} style={{ maxWidth: '800px' }}>
            <h1>{submission?.avaliator_title || submission?.title}</h1>

            <p>Cadastro de avaliadores</p>

            <Separator>


              <label>Dados do Avaliador</label>
              <Input name="name" placeholder="Nome Completo" />
              <MaskedInput mask="999.999.999-99" name="document_number" placeholder="CPF" />



            </Separator>

            <Separator>
              <label>Dados do Acesso</label>
              <Input name="email" setCallback={(emailValue) => setEmail(emailValue)} placeholder="Email" />
              <Input type="password" name="password" placeholder="Cadastre sua senha de acesso" />
            </Separator>

            {submission?.avaliator_ask_lattes === 'yes' && (email?.indexOf('@') >= 0 && email?.indexOf('.') >= 0 && email?.toLowerCase()?.indexOf('ufcspa') < 0) ?
              <Separator>
                <Input name="lattes" placeholder="Link do Currículo Lattes" />
              </Separator>
              : <></>}

            {submission?.avaliator_is_orientating ? <Separator>
              <label>{submission?.avaliator_is_orientating}</label>


              <CheckboxInput name="is_orientating" placeholder='' options={[
                { value: 'Ensino', label: 'Ensino', id: 'Ensino' },
                { value: 'Pesquisa', label: 'Pesquisa', id: 'Pesquisa' },
                { value: 'Extensão', label: 'Extensão', id: 'Extensão' },
              ]} />



            </Separator> : <></>}

            {categoryValues?.length > 0 ? <Separator>
              <label>Categoria</label>
              <SelectSimple name="category" label="" options={categoryValues} />
            </Separator> : <></>}



            {categoriesSession?.status === 'yes' ? <Separator>
              <label>{categoriesSession?.avaliator_title || categoriesSession?.title}</label>



              <CheckboxInputJSON name="categories" options={
                categories || []
              } placeholder="" />
            </Separator> : <></>}

            {/*
            {extraCategoriesSession?.status === 'yes' ?
              <Separator>
                <label>{extraCategoriesSession?.avaliator_title || extraCategoriesSession?.title}</label>



                <CheckboxInputJSON name="extra_categories" options={
                  extra_categories_fields || []
                } placeholder="" />
              </Separator> : <></>}

              */}

            <Separator>
              <label>Disponibilidade de horários - registre no mínimo {submission?.avaliator_hour_fields?.[0]?.minimun} horários</label>



              <CheckboxInputJSON name="hours" options={
                hours || []
              } placeholder="" />
            </Separator>

            {/*  <Separator>
              <label>3 - Número de resumos para avaliação</label>
              <CheckboxInput unique name="papers_numbers" options={
                [
                  { value : '5 a 8', id:'5 a 8', label: '5 a 8'},
                  { value : '9 a 11', id:'9 a 11', label: '9 a 11'},
                  { value : '12 a 14', id:'12 a 14', label: '12 a 14'}
                ]
              } placeholder="" />

            </Separator> */}






            {errorMessage && (
              <Error>
                {errorMessage.map((item, index) => (
                  <div key={`error${index}`}>
                    <MdError />
                    {item}
                  </div>
                ))}
              </Error>
            )}

            <aside style={{ display: 'flex', justifyContent: 'center' }}>
              <Button style={{ width: '100%' }} type="submit">
                Enviar
              </Button>
            </aside>

            <FixedResponse style={{ display: activeReview ? 'flex' : 'none' }}>
              <div>
                <h1>Revisão de dados</h1>

                <h2>Dados do Avaliador</h2>
                <strong>Nome completo</strong><p>{submissionData?.name}</p>
                <strong>CPF</strong><p>{submissionData?.document_number}</p>


                <h2>Dados de acesso</h2>
                <strong>Email</strong><p>{submissionData?.email}</p>

                {submission?.avaliator_is_orientating ? <>
                  <h2>Informações adicionais</h2>
                  <strong>É orientador de alunos dos programas de IC, ITI, PID, Extensão?</strong><p>{submissionData?.is_orientating}</p>
                </> : <></>}

                {categoryValues?.length > 0 ? <><h2>Categoria</h2>
                  <strong>Categoria</strong><p>{submissionData?.category}</p> </> : <></>}


                {submission?.avaliator_ask_lattes === 'yes' ? <><strong>Link do Currículo Lattes</strong><p>{submissionData?.lattes}</p></> : <></>}


                {categoriesSession?.status === 'yes' ? <>
                  <h2>{categoriesSession?.avaliator_title || categoriesSession?.title}</h2>
                  {submissionData?.categories?.map(categoryValue => {
                    return <aside style={{ background: '#eee', padding: '5px', margin: '5px', color: '#333', width: 'auto' }}>{categoryValue}</aside>
                  })}
                </> : <></>
                }

                {extraCategoriesSession?.status === 'yes' ? <>
                  <h2>{extraCategoriesSession?.avaliator_title || extraCategoriesSession?.title}</h2>
                  {submissionData?.extra_categories?.map(extraCategoryValue => {
                    return <aside style={{ background: '#eee', padding: '5px', margin: '5px', color: '#333', width: 'auto' }}>{extraCategoryValue}</aside>
                  })}
                </> : <></>
                }

                <h2>Horários</h2>

                {submissionData?.hours?.map(hourValue => {
                  return <aside style={{ background: '#eee', padding: '5px', margin: '5px', color: '#333', width: 'auto' }}>{hourValue}</aside>
                })}



                <button className="buttonBigGreen" type="submit" style={{ width: '200px', padding: '15px' }}>Enviar</button>


                <button className="defaultButtonReverse" type="button" onClick={() => setActiveReview(false)}>Editar</button>
              </div>

            </FixedResponse>
          </Form>
        </Container>
        )}
      </aside>


    </>
  );
};

export default AvaliatorSubscription;

