import React, { useEffect, useRef, InputHTMLAttributes, useState } from 'react';
import { useField } from '@unform/core';
import { Container } from './styles';
import theme from '../../../../../../../../styles/ThemeComponents';



interface IList {
  title: string;
  subname?: string;
  subcategory?: string;
  options?: IList[];
  subdescription?: string;
  description?: string;
}

interface Props extends InputHTMLAttributes<HTMLInputElement> {

  style?: Record<string, any>;
  name: string;
  unique?: boolean;
  preText?: JSX.Element;
  posText?: JSX.Element;
  background_line?: string;
  description?: string;
  setCategory?: Function;
  setArea?: Function;
  setSubarea?: Function;
  options: IList[];
  category?: string;
  area?: string;
  subarea?: string;
}
const CheckboxIncluder: React.FC<Props> = ({

  name,
  options,
  preText = '',
  posText = '',
  unique = true,
  style,
  background_line = '',
  placeholder = '',
  description = '',
  setCategory = (value) => { },
  setArea = (value) => { },
  setSubarea = (value) => { },
  category = '',
  subarea = '',
  area = '',
  ...rest
}) => {
  let inputRefs = useRef<HTMLInputElement[]>([]);
  const [currentValue, setCurrentValue] = useState('');
  const { fieldName, registerField, defaultValue = [] } = useField(name);

  const [valid, setValid] = useState({
    category: '',
    area: '',
    subarea: ''
  })



  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRefs.current,
      getValue: (refs: HTMLInputElement[]) => {


        return refs.filter(ref => ref.checked).map(ref => ref.value);
      },
      clearValue: (refs: HTMLInputElement[]) => {
        refs.forEach(ref => {
          ref.checked = false;
        });
      },
      setValue: (refs: HTMLInputElement[], values: string[]) => {
        refs.forEach(ref => {
          if (values.includes(ref.id)) {
            ref.checked = true;

          }
        });
      },
    });


  }, [defaultValue, fieldName, registerField]);

  useEffect(() => {
    inputRefs.current.map(i => {
      if (i.name !== 'category') {
        i.checked = false;
      }
    })


  }, [category])

  useEffect(() => {
    inputRefs.current.map(i => {
      if (i.name === 'subarea') {
        i.checked = false;
      }
    })


  }, [area])

  useEffect(() => {

    setValid({ category, area, subarea });

  }, [category, area, subarea])


  return (
    <Container style={style} {...rest}>
      {preText && <label style={{ border: '0', textAlign: 'justify', color: '#333' }}>{preText}</label>}
      {placeholder && <h2 style={{ border: '0', textAlign: 'justify', color: '#333' }}>{placeholder}</h2>}
      {description && <p style={{ color: '#333' }} dangerouslySetInnerHTML={{ __html: description }} />}
      {options.map((option, index) => {
        const optionValue = typeof option === 'string' ? { title: option } : option;
        return <div style={{ border: '1px solid #ddd', margin: '0px' }}>
          <label style={{ cursor: 'pointer', color: theme.color, width: '100%', background: optionValue?.title === valid[name] ? '#ededed' : '#fff', padding: "10px" }} htmlFor={`${name}_${optionValue.title}`} key={`${name}_${optionValue.title}`}>
            <input checked={optionValue?.title === valid[name]} style={{ color: theme.color, border: '1px solid#333' }}
              onClick={(e) => {
                if (unique) {
                  inputRefs.current.forEach(ref => {
                    ref.checked = false;

                  });

                  const value = e.currentTarget.value;
                  setCurrentValue(value);
                  inputRefs.current[index].checked = true;




                  if (name === 'category') {
                    setCategory(value)
                    setArea('');
                    setSubarea('');
                  };
                  if (name === 'area') {
                    setArea(value);
                    setSubarea('');
                  };
                  if (name === 'subarea') { setSubarea(value) };
                }
              }}
              defaultChecked={defaultValue.find((dv: string) => dv === optionValue.title)}
              ref={ref => {
                inputRefs.current[index] = ref as HTMLInputElement;
              }}
              value={optionValue.title}
              type="checkbox"
              id={`${name}_${optionValue.title}`}
              name={name}
              {...rest}
            />
            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'flex-start', marginLeft: '10px' }}>
              <strong style={{ color: "#333", fontSize: '14px' }}>{optionValue.title}</strong>
              {optionValue?.description && <p className='everBlack' style={{ color: "#333", fontSize: '14px' }} dangerouslySetInnerHTML={{ __html: optionValue.description || '' }} />}
            </div>
          </label>
          {optionValue?.options && optionValue?.options?.length > 0 && <div style={{ display: optionValue?.title === valid[name] ? 'block' : 'none', width: '100%', maxWidth: '100%', padding: '0px', paddingLeft: '30px', margin: '0px' }}><CheckboxIncluder category={category} area={area} subarea={subarea} setCategory={setCategory} setArea={setArea} setSubarea={setSubarea} name={name === 'area' ? 'subarea' : 'area'} placeholder={''} options={optionValue.options || []} /></div>}
        </div>
      })
      }
      {posText && <label style={{ border: '0', textAlign: 'justify', color: "#333" }}>{posText}</label>}
    </Container >
  );
};
export default CheckboxIncluder;
