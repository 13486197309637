import styled, { keyframes } from 'styled-components';

interface IBackground {
  background?: string;
}



export const Container = styled.div<IBackground>`
 position: fixed;
 top: 0px;
 left: 0px;
 right: 0px;
 bottom: 0px;
 width: 100%;
 height: 100%;
 z-index:100000;

 display: flex ;
 align-items: center;
 justify-content: center;
 flex-direction: column;
 background: rgb(0,0,0,0.5);
 
>div{
  position: relative ;
  width: 100%;
  height: 100vh;
  display: flex ;
 align-items: center;
 justify-content: center;
 flex-direction: column;
  >img{
    max-height: 100vh;
 
 
  }

  >button{
    position: absolute ;
    bottom: 15px;
    right: 15px;
  }
}


  @media (max-width: 750px) {
    padding: 30px 0px;
  }
 
`;
