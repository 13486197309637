export const allowBoth = [

    '02467985059'
]


export const cpf = [
    '01478153040',
    '03381405063',
    '04992322085',
    '02649830093',
    '03944323041',
    '01084082055',
    '00457601019',
    '86310607049',
    '02850253090',
    '04883964094',
    '01319844030',
    '85825875034',
    '05313009001',
    '01500326097',
    '00852119070',
    '02798668018',
    '04958972056',
    '01010228080',
    '01902053001',
    '03753326003',
    '01729501044',
    '02528929099',
    '03754042017',
    '09813808918',
    '06459279101',
    '02077976098',
    '02925654029',
    '85096032000',
    '02394018089',
    '86596306020',
    '01980121010',
    '05401141033',
    '03102664083',
    '03900755078',
    '01260964060',
    '02467985059',
    '03683624000',
    '84747609091',
    '86135023004',
    '03722083044',
    '03557377016',
    '02628245078',
    '85645400020',
    '85756962034',
    '84623993000',
    '86966987034',
    '03121471040',
    '03918249026',
    '85211435087',
    '86394681091',
    '01349071080',
    '05108086032',
    '03547222044',
    '09859862125',
    '88036995034',
    '32330058810',
    '04806385000',
    '01087176077',
    '38168451856',
    '86771310000',
    '01416098267',
    '09473223960',
    '47043676819',
    '11666252905',
    '03704737011',
    '02984166035',
    '47891626804',
    '14359862709',
    '02646292058',
    '02832198074',
    '01714181073',
    '04562869003',
    '04555814010',
    '39429573888',
    '60023336064',
    '46701088830',
    '50895320010',
    '03111595080',
    '16368974796',
    '08495425963',
    '01729177000',
    '88066282087',
    '02524765067',
    '04758386021',
    '02119449040',
    '01492146188',
    '03819296042',
    '05377039009',
    '03771523079',
    '60030684005',
    '04003592000',
    '05172337032',
    '02842826000',
    '05576051130',
    '04745544065',
    '04131942054',
    '03030271005',
    '14013784637',
    '02653134063',
    '03525130040',
    '04221717009',
    '10841412642',
    '03531964011',
    '03984420021',
    '03548939040',
    '05115111080',
    '11406872474',
    '60057261024',
    '04986697005',
    '49169198829',
    '03558661028',
    '04004962080',
    '09212220681',
    '00982845090',
    '09307102902',
    '20666668028',
    '03870896086',
    '01616529059',
    '10571465951',
    '86199021053',
    '04457701041',
    '03495471065',
    '04510864060',
    '16355829709',
    '03855826099',
    '42462570870',
    '04577453045',
    '60055046061',
    '05776569028',
    '05288009066',
    '02760230058',
    '03874076008',
    '87654873034',
    '47004187888',
    '04170560019',
    '06401717106',
    '04759708022',
    '39681540883',
    '08177464981',
    '04653617066',
    '01634683030',
    '03082842089',
    '47573105878',
    '09774253981',
    '85266922087',
    '85976407072',
    '03629974031',
    '01782868003',
    '07110229505',
    '03481610009',
    '01130429067',
    '85568821020',
    '84905980020',
    '03569833070',
    '04038861040',
    '03550588046',
    '2169116952',
    '96763221053',
    '03636339010',
    '85983721020 ',
    '04146233046',
    '04292097038',
    '03901404066',
    '4760899057',
    '03546522060',
    '04225923070',
    '02997647029',
    '51551581841',
    '08787296900',
    '04049931095',
    '04112645082',
    '03808893079',
    '06341467184',
    '85701688020',
    '04003771010',
    '04746960062',
    '02669195024',
    '03783160014',
    '03630255027',

    '04019282099',
    '14869722763',
    '01687278032',
    '87031663091',
    '07532686124',
    '60144369010',
    '06263345942',
    '02544079002',
    '05065926000',
    '04909157018',
    '85761583020',
    '04333143110',
    '01697361056',
    '47034182825',
    '86209272053',
    '02596774003',
    '04059145041',
    '03139279019',
    '09582790989',
    '00484753002',
    '04293143025',
    '02276966033',
    '23084418870',
    '04024819097',
    '03130249010',
    '02755487089',
    '03227447096',
    '18658762750',
    '03384231090',
    '04500299050',
    '02456696042',
    '04665871043',
    '43846713856',
    '08252590969',
    '05051030017',
    '02717770011',
    '41570831807',
    '03766920065',
    '02904638075',
    '06572355551',
    '03499163063',
    '87221764034',
    '46152496837',
    '01836006063',
    '44110020867',
    '09892024109',
    '04052658086',
    '85146951004',
    '07617754558',
    '07617754558',
    '45278223812',
    '03694929002',
    '03561559000',
    '02041084030',
    '05928879105',
    '50124156800',
    '03861478099',
    '05928879105',
    '10040968952',
    '17837648722',
    '03945674042',
    '03285071052',
    '02111723088',
    '04800116058',
    '13296904948',
    '01319844030',
    '03066480089',
    '08171348165',
    '13079117654',
    '03754042017',
    '01811287050',
    '87754142015',
    '00941381099',
    '03095919026',
    '04343730018',
    '40451223896',
    '14402669723',
    '01491278048',
    '86394681091',
    '04203503060',
    '47361292801',
    '05057376047',
    '01089891008',
    '44467977864',
    '01492146188',
    '04131942054',
    '02494016061',
    '02066668028',
    '60054529085',
    '60035497009',
    '02849708089',
    '02273681003',
    '04208940042',
    '04240345065',
    '03397892040',
    '85568821020',
    '01895836069',
    '85701688020',
    '02952779040',
    '03227447096',
    '08252590969',
    '02852725002',
    '02232625010',
    '86046063000',
    '85597090020',
    '86844172087',
    '02649830093',
    '03944323041',
    '85615048034',
    '86967665049',
    '85243582034',
    '04758444080',
    '87265710053',
    '03394377071',
    '04450595084',
    '09774965973',
    '03006305060',
    '87854074020',
    '85581895015',
    '02180515065',
    '86438468053',
    '02815237032',
    '03017925005',
    '00600406024',
    '87452294068',
    '02150732076',
    '07898034961',
    '45986706811',
    '04257504005',
    '86668676049',
    '04829821019',
    '03815044090',
    '03900755078',
    '00635415054',
    '02467985059',
    '32538762847',
    '03658342030',
    '86308710598',
    '87775832053',
    '85645400020',
    '03284521062',
    '03770390040',
    '03279550076',
    '04530739058',
    '85261254000',
    '03447906065',
    '00873949013',
    '85955370030',
    '48573339888',
    '02354635079',
    '03114641067',
    '85242896000',
    '03764992069',
    '02946152022',
    '05119966080',
    '04177121037',
    '02860253025',
    '02610854099',
    '04224185016',
    '04303925080',
    '04920895070',
    '34032554846',
    '60049729080',
    '02832198074',
    '03012317023',
    '02003812024',
    '03630035094',
    '03876494095',
    '04003592000',
    '86213598049',
    '03886055027',
    '05225180019',
    '04221717009',
    '02905096020',
    '85974021053',
    '83926348020',
    '04986697005',
    '50097826855',
    '86024965087',
    '02488940054',
    '04848925096',
    '01032654066',
    '87133962004',
    '03589017074',
    '03687644006',
    '03845441003',
    '16202729708',
    '06898332104',
    '85976385087',
    '03846711012',
    '02750442036',
    '01189402017',
    '03886539067',
    '44366880890',
    '03806827028',
    '03497769037',
    '85094587072',
    '03740909048',
    '87092425034',
    '87938812072',
    '05305354080',
    '04528813076',
    '03044772060',
    '05043974001',
    '07023293981',
    '05555059957',
    '11175928984',
    '03356994050',
    '03020972019',
    '05153367002',
    '04827312060',
    '07980064984',
    '03674965003',
    '03874076008',
    '03371926001',
    '03582026043',
    '03281088040',
    '03388570019',
    '85083089068',
    '06326990009',
    '02356343016',
    '02818022037',
    '03810949019',
    '14247371681',
    '83844333053',
    '03780776006',
    '86172549049',
    '02081146029',
    '86199617053',
    '03573786014',
    '04153899097',
    '03020438020',
    '85915637000',
    '04146233046',
    '60135688043',
    '03551483078',
    '85508268049',
    '60070586039',
    '04213027040',
    '02577970099',
    '09384845914',
    '02475981008',
    '87523663087',
    '47066906845',
    '00484753002',
    '44846257843',
    '02822743088',
    '03038434035',
    '04749771023',
    '04561793054',
    '03940216062',
    '16141801722',
    '47345139883',
    '05108585039',
    '04665871043',
    '01081329009',
    '01201770050',
    '05051030017',
    '04705460006',
    '03997357048',
    '04381307070',
    '04621992074',
    '04105266063',
    '85620114015',
    '04028428041',
    '84895314049',
    '02892630002',
    '03548401007',
    '03269716028',
    '00695232070',
    '01837136050',
    '13296904948',
    '86485113053',
    '04493636009',
    '05058423006',
    '01356179010',
    '02522147064',
    '41347268839',
    '85211435087',
    '02373910071',
    '04113541086',
    '87384906091',
    '02749269032',
    '42462570870',
    '03144990086',
    '05130472047',
    '03943292010',
    '03943292010',
    '08252590969',
    '04052658086',
    '06341467184',
    '60068798083',
    '14939600750',
    '02731345071',
    '02937946003',
    '05363528010',
    '87052180049',
    '86596306020',
    '12939569967',
    '01902053001',
    '04531321030',
    '60077679067',
    '38168451856',
    '86630083091',
    '03102664083',
    '03682268081',
    '03588210024',
    '01895836069',
    '02467985059',
    '00082408254',
    '04992322085',
    '00457601019',
    '86967665049',
    '03683128011',
    '03750555095',
    '45758806803',
    '87265710053',
    '01319844030',
    '03066480089',
    '08171348165',
    '18232135700',
    '16045954777',
    '05313009001',
    '03830375069',
    '46393248807',
    '60074731041',
    '04919229062',
    '02867462045',
    '13079117654',
    '87780488004',
    '01902053001',
    '01902053001',
    '41248927800',
    '02528929099',
    '03754042017',
    '02815237032',
    '03507592002',
    '03713658056',
    '85140678004',
    '04099313069',
    '05112766050',
    '04113531013',
    '03133948067',
    '01356179010',
    '01904359094',
    '01811287050',
    '04501017031',
    '86668676049',
    '87754142015',
    '11305881907',
    '00941381099',
    '03095919026',
    '03102664083',
    '04343730018',
    '02467985059',
    '04325163018',
    '02575878020',
    '40451223896',
    '04429895040',
    '03557377016',
    '86179403015',
    '44457607819',
    '60215664086',
    '87775832053',
    '14402669723',
    '01491278048',
    '00896907295',
    '01578737060',
    '85124915072',
    '03725647046',
    '03654157076',
    '86966987034',
    '12560038919',
    '86394681091',
    '01349071080',
    '04887850069',
    '03291050002',
    '04806385000',
    '03229113039',
    '38168451856',
    '86771310000',
    '88040046068',
    '04203503060',
    '05288008094',
    '11666252905',
    '47361292801',
    '05057376047',
    '02475961082',
    '01146004060',
    '03982429099',
    '01089891008',
    '02062681062',
    '01815899077',
    '06084188117',
    '03433184070',
    '86174975053',
    '02571012096',
    '44467977864',
    '04318448010',
    '01729177000',
    '04448825027',
    '88066282087',
    '02119449040',
    '01492146188',
    '03819296042',
    '05377039009',
    '03771523079',
    '01314013009',
    '02773575008',
    '05576051130',
    '04131942054',
    '03030271005',
    '14013784637',
    '14013784637',
    '03640926021',
    '03984420021',
    '05115111080',
    '04925541021',
    '86385674053',
    '83926348020',
    '60005931045',
    '02213589003',
    '49169198829',
    '00754578062',
    '60024049026',
    '02494016061',
    '16202729708',
    '09307102902',
    '02750442036',
    '02066668028',
    '00923267069',
    '04313617035',
    '03934988083',
    '87384906091',
    '60054529085',
    '84886706053',
    '60035497009',
    '02849708089',
    '02273681003',
    '07023293981',
    '02052484035',
    '04510864060',
    '04577453045',
    '82853720063',
    '03820894055',
    '05288009066',
    '05153367002',
    '04208940042',
    '02199860027',
    '02380245045',
    '04240345065',
    '00884338037',
    '87654873034',
    '04002622070',
    '03281088040',
    '03984571070',
    '03423438070',
    '05403161058',
    '02641926059',
    '03397892040',
    '01757453075',
    '02356343016',
    '03962769030',
    '48686130810',
    '03082842089',
    '03098289023',
    '09774253981',
    '02015484027',
    '00902923080',
    '01782868003',
    '85568821020',

    '04279990140',
    '00818248076',
    '03996547048',
    '01895836069',
    '03446377000',
    '04292097038',
    '03110446030',
    '05416578017',
    '04636151070',
    '51551581849',
    '06341467184',
    '85701688020',
    '04003771010',
    '04335189010',
    '03551483078',
    '03357458063',
    '04087458008',
    '03783160014',
    '02369259035',
    '03440244075',
    '04942555019',
    '02582415006',
    '85140848015',
    '04506760050',
    '02749269032',
    '05058423006',
    '83809180025',
    '04368286006',
    '03626888002',
    '03260884076',
    '05122056048',
    '86209272053',
    '03509080017',
    '04293143025',
    '02094813070',
    '04067572085',
    '12723352676',
    '86290665049',
    '01708768025',
    '23084418870',
    '04024819097',
    '03943292010',
    '02952779040',
    '03227447096',
    '03752455020',
    '60006860052',
    '05124996080',
    '87592274000',
    '08252590969',
    '05051030017',
    '02852725002',
    '03766920065',
    '02904638075',
    '07573431120',
    '04103280069',
    '03733234090',
    '01430051035',
    '06572355551',
    '87221764034',
    '46152496837',
    '02232625010',
    '04320139038',
    '01836006063',
    '02411303017',
    '03296125060',
    '04052658086',
    '85146951004',
    '86094688015',
    '86046063000',
    '07617754558',
    '43370707888',
    '04065591040',
    '85400386091',
    '05087782043',
    '05402268070',
    '05402268070',
    '04441634099',
    '04353732005',
    '03548401007',
    '01898707103',
    '02368851038',
    '85597090020',
    '04404709005',
    '12040334980',
    '86438468053',
    '04153963011',
    '85554626068',
    '50895320010',
    '43412753807',
    '02948827078',
    '03031370023',
    '04167662000',
    '00457601019',
    '45278223812',
    '43930190087',
    '85122688087',
    '03689393086',
    '03085261044'
]
