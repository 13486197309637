export const universidades = [
{ label : 'UFCSPA – UNIVERSIDADE FEDERAL DE CIENCIAS DA SAÚDE DE PORTO ALEGRE', value : 'UFCSPA – UNIVERSIDADE FEDERAL DE CIENCIAS DA SAÚDE DE PORTO ALEGRE'},
{ label : 'UFFS – UNIVERSIDADE FEDERAL DA FRONTEIRA SUL', value : 'UFFS – UNIVERSIDADE FEDERAL DA FRONTEIRA SUL'},
{ label : 'UFPel – UNIVERSIDADE FEDEDERAL DE PELOTAS', value : 'UFPel – UNIVERSIDADE FEDEDERAL DE PELOTAS'},
{ label : 'UNIPAMPA – UNIVERSIDADE FEDEDERAL DO PAMPA', value : 'UNIPAMPA – UNIVERSIDADE FEDEDERAL DO PAMPA'},
{ label : 'FURG – UNIVERSIDADE FEDERAL DO RIO GRANDE', value : 'FURG – UNIVERSIDADE FEDERAL DO RIO GRANDE'},
{ label : 'UFRGS – UNIVERSIDADE FEDERAL DO RIO GRANDE DO SUL', value : 'UFRGS – UNIVERSIDADE FEDERAL DO RIO GRANDE DO SUL'},
{ label : 'UFSM – UNIVERSIDADE FEDERAL DE SANTA MARIA', value : 'UFSM – UNIVERSIDADE FEDERAL DE SANTA MARIA'},
{ label : 'UERGS – UNIVERSIDADE ESTADUAL DO RIO GRANDE DO SUL', value : 'UERGS – UNIVERSIDADE ESTADUAL DO RIO GRANDE DO SUL'},
{ label : 'UFAC – UNIVERSIDADE FEDERAL DO ACRE', value : 'UFAC – UNIVERSIDADE FEDERAL DO ACRE'},
{ label : 'UFAL – UNIVERSIDADE FEDERAL DE ALAGOAS', value : 'UFAL – UNIVERSIDADE FEDERAL DE ALAGOAS'},
{ label : 'UNIFAP – UNIVERSIDADE FEDERAL DO AMAPÁ', value : 'UNIFAP – UNIVERSIDADE FEDERAL DO AMAPÁ'},
{ label : 'UFAM – UNIVERSIDADE FEDERAL DO AMAZONAS', value : 'UFAM – UNIVERSIDADE FEDERAL DO AMAZONAS'},
{ label : 'UFBA – UNIVERSIDADE FEDERAL DA BAHIA', value : 'UFBA – UNIVERSIDADE FEDERAL DA BAHIA'},
{ label : 'UnB – UNIVERSIDADE DE BRASÍLIA', value : 'UnB – UNIVERSIDADE DE BRASÍLIA'},
{ label : 'UFC – UNIVERSIDADE FEDERAL DO CEARÁ', value : 'UFC – UNIVERSIDADE FEDERAL DO CEARÁ'},
{ label : 'UFES – UNIVERSIDADE FEDRAL DO ESPÍRITO SANTO', value : 'UFES – UNIVERSIDADE FEDRAL DO ESPÍRITO SANTO'},
{ label : 'UFG – UNIVERSIDADE FEDERAL DE GOIÁS', value : 'UFG – UNIVERSIDADE FEDERAL DE GOIÁS'},
{ label : 'UFMA – UNIVERSIDADE FEDERAL DO MARANHÃO', value : 'UFMA – UNIVERSIDADE FEDERAL DO MARANHÃO'},
{ label : 'UFMT – UNIVERSIDADE FEDERAL DE MATO GROSSO', value : 'UFMT – UNIVERSIDADE FEDERAL DE MATO GROSSO'},
{ label : 'UFMS – UNIVESIDADE FEDERAL DE MATO GROSSO DO SUL', value : 'UFMS – UNIVESIDADE FEDERAL DE MATO GROSSO DO SUL'},
{ label : 'UFMG – UNIVERSIDADE FEDERAL DE MINAS GERAIS', value : 'UFMG – UNIVERSIDADE FEDERAL DE MINAS GERAIS'},
{ label : 'UFPA – UNIVERSIDADE FEDERAL DO PARÁ', value : 'UFPA – UNIVERSIDADE FEDERAL DO PARÁ'},
{ label : 'UFPR – UNIVERSIDADE FEDERAL DO PARANÁ', value : 'UFPR – UNIVERSIDADE FEDERAL DO PARANÁ'},
{ label : 'UFPB – UNIVERSIDADE FEDERAL DA PARAÍBA', value : 'UFPB – UNIVERSIDADE FEDERAL DA PARAÍBA'},
{ label : 'UFPE – UNIVERSIDADE FEDERAL DE PERNAMBUCO', value : 'UFPE – UNIVERSIDADE FEDERAL DE PERNAMBUCO'},
{ label : 'UFPI – UNIVERSIDADE FEDERAL DO PIAUÍ', value : 'UFPI – UNIVERSIDADE FEDERAL DO PIAUÍ'},
{ label : 'UFRJ – UNIVERSIDADE FEDERAL DO RIO DE JANEIRO', value : 'UFRJ – UNIVERSIDADE FEDERAL DO RIO DE JANEIRO'},
{ label : 'UFRN – UNIVERSIDADE FEDERAL DO RIO GRANDE DO NORTE', value : 'UFRN – UNIVERSIDADE FEDERAL DO RIO GRANDE DO NORTE'},
{ label : 'UNIR – FUNDAÇÃO UNIVERSIDADE FEDERAL DE RONDÔNIA', value : 'UNIR – FUNDAÇÃO UNIVERSIDADE FEDERAL DE RONDÔNIA'},
{ label : 'UFRR – UNIVERSIDADE FEDERAL DE RORAIMA', value : 'UFRR – UNIVERSIDADE FEDERAL DE RORAIMA'},
{ label : 'UFSC – UNIVERSIDADE FEDERAL DE SANTA CATARINAL', value : 'UFSC – UNIVERSIDADE FEDERAL DE SANTA CATARINAL'},
{ label : 'UNIFESP – UNIVERSIDADE FEDERAL DE SÃO PAULO', value : 'UNIFESP – UNIVERSIDADE FEDERAL DE SÃO PAULO'},
{ label : 'UFS – UNIVERSIDADE FEDERAL DE SERGIPE', value : 'UFS – UNIVERSIDADE FEDERAL DE SERGIPE'},
{ label : 'UFT – UNIVERSIDADE FEDERAL DO TOCANTINS', value : 'UFT – UNIVERSIDADE FEDERAL DO TOCANTINS'},
{ label : 'IFPR - INSTITUTO FEDERAL DE EDUCAÇÃO, CIÊNCIA E TECNOLOGIA DO PARANÁ', value : 'IFPR - INSTITUTO FEDERAL DE EDUCAÇÃO, CIÊNCIA E TECNOLOGIA DO PARANÁ'},
{ label : 'IFSUL – INSTITUTO FEDERAL DE EDUCAÇÃO, CIÊNCIA E TECNOLOGIA DO RIO GRANDE DO SUL', value : 'IFSUL – INSTITUTO FEDERAL DE EDUCAÇÃO, CIÊNCIA E TECNOLOGIA DO RIO GRANDE DO SUL'},
{ label : 'IFSC- INSTITUTO FEDERAL DE EDUCAÇÃO, CIÊNCIA E TECNOLOGIA DE SANTA CATARINA', value : 'IFSC- INSTITUTO FEDERAL DE EDUCAÇÃO, CIÊNCIA E TECNOLOGIA DE SANTA CATARINA'},
{ label : 'IFF – INSTITUTO FEDERAL FARROUPILHA', value : 'IFF – INSTITUTO FEDERAL FARROUPILHA'},
{ label : 'IFRS – INSTITUTO FEDERAL DO RIO GRANDE DO SUL', value : 'IFRS – INSTITUTO FEDERAL DO RIO GRANDE DO SUL'},
{ label : 'UNIRITTER – CENTRO UNIVERSITÁRIO RITTER DOS REIS', value : 'UNIRITTER – CENTRO UNIVERSITÁRIO RITTER DOS REIS'},
{ label : 'PUCRS – PONTIFÍCIA UNIVERSIDADE CATÓLICA DO RIO GRANDE DO SUL', value : 'PUCRS – PONTIFÍCIA UNIVERSIDADE CATÓLICA DO RIO GRANDE DO SUL'},
{ label : 'IPA – REDE METODISTA DE EDUCAÇÃO DO SUL', value : 'IPA – REDE METODISTA DE EDUCAÇÃO DO SUL'},
{ label : 'UCPEL – UNIVERSIDADE CATÓLICA DE PELOTAS', value : 'UCPEL – UNIVERSIDADE CATÓLICA DE PELOTAS'},
{ label : 'UCS – UNIVERSIDADE DE CAXIAS DO SUL', value : 'UCS – UNIVERSIDADE DE CAXIAS DO SUL'},
{ label : 'UNICRUZ – UNIVERSIDADE DE CRUZ ALTA', value : 'UNICRUZ – UNIVERSIDADE DE CRUZ ALTA'},
{ label : 'FEEVALE – UNIVERSIDADE FEEVALE', value : 'FEEVALE – UNIVERSIDADE FEEVALE'},
{ label : 'UFN – UNIVERSIDADE FRANCISCANA', value : 'UFN – UNIVERSIDADE FRANCISCANA'},
{ label : 'URI – UNIVERSIDADE INTEGRADA DO ALTO URUGUAI E DAS MISSÕES', value : 'URI – UNIVERSIDADE INTEGRADA DO ALTO URUGUAI E DAS MISSÕES'},
{ label : 'UNILASALLE – UNIVERSIDADE LA SALLE', value : 'UNILASALLE – UNIVERSIDADE LA SALLE'},
{ label : 'ULBRA – UNIVERSIDADE LUTERANA DO BRASIL', value : 'ULBRA – UNIVERSIDADE LUTERANA DO BRASIL'},
{ label : 'UPF – UNIVERSIDADE DE PASSO FUNDO', value : 'UPF – UNIVERSIDADE DE PASSO FUNDO'},
{ label : 'URCAMP – UNIVERSIDADE DA REGIÃO DA CAMPANHA', value : 'URCAMP – UNIVERSIDADE DA REGIÃO DA CAMPANHA'},
{ label : 'UNIJUI – UNIVERSIDADE REGIONAL DO NOROESTE DO ESTADO DO RIO GRANDE DO SUL', value : 'UNIJUI – UNIVERSIDADE REGIONAL DO NOROESTE DO ESTADO DO RIO GRANDE DO SUL'},
{ label : 'UNISC – UNIVERSIDADE DE SANTA CRUZ DO SUL', value : 'UNISC – UNIVERSIDADE DE SANTA CRUZ DO SUL'},
{ label : 'UNISINOS – UNIVERSIDADE DO VALE DO RIO DOS SINOS', value : 'UNISINOS – UNIVERSIDADE DO VALE DO RIO DOS SINOS'},
{ label : 'UNIVATES – UNIVERSIDADE DO VALE DO TAQUARI', value : 'UNIVATES – UNIVERSIDADE DO VALE DO TAQUARI'},
{ label : 'OUTRO, QUAL?', value : 'Outro? Qual?'}
]