export const areas: Array<Record<string, any>> = [

  {
    title: 'Ensino',
    subname: 'area',
    subcategory: 'Áreas',

    description: '',
    lists: [
      /*   { title : 'Currículo, formação, docência e ensino na saúde',
        lists : []
      },
      { title : 'Integração universidade, serviço de saúde e comunidade',
      lists : []
    },
    { title : 'Educação popular em saúde ',
    lists : []
  }, */

    ]
  },

  {
    title: 'Pesquisa',
    subname: 'area',
    subcategory: 'Áreas',
    description: ``,
    lists: [
      {
        title: 'Ciências Exatas e da Terra',
        subname: 'subarea',
        subcategory: 'Subáreas',
        lists: [
          { title: 'Ciência da Computação' },
          { title: 'Engenharia Biomédica' },
          { title: 'Física' },
          { title: 'Matemática' },
          { title: 'Química' },
          { title: 'Teleinformática' },
          { title: 'Outra' }
        ]
      },
      {
        title: 'Ciências Biológicas e Ambientais',
        subname: 'subarea',
        subcategory: 'Subáreas',
        lists: [
          { title: 'Biofísica' },
          { title: 'Bioquímica' },
          { title: 'Botânica' },
          { title: 'Ecologia' },
          { title: 'Farmacologia' },
          { title: 'Fisiologia' },
          { title: 'Genética' },
          { title: 'Imunologia' },
          { title: 'Microbiologia' },
          { title: 'Morfologia' },
          { title: 'Parasitologia' },
          { title: 'Toxicologia' },
          { title: 'Outra' },
        ]
      },
      {
        title: 'Ciências da Saúde e Agrárias',
        subname: 'subarea',
        subcategory: 'Subáreas',
        lists: [
          { title: 'Ciência e Tecnologia de Alimentos' },
          { title: 'Gastronomia' },
          { title: 'Educação Física' },
          { title: 'Enfermagem' },
          { title: 'Farmácia' },
          { title: 'Fisioterapia e Terapia Ocupacional' },
          { title: 'Fonoaudiologia' },
          { title: 'Medicina' },
          { title: 'Nutrição' },
          { title: 'Odontologia' },
          { title: 'Saúde Coletiva' },
          { title: 'Outra' }
        ]
      },
      {
        title: 'Ciências Sociais Aplicadas',
        subname: 'subarea',
        subcategory: 'Subáreas',
        lists: [
          { title: 'Administração' },
          { title: 'Ciência da Informação' },
          { title: 'Divulgação Científica' },
          { title: 'Outra' }
        ]
      },
      {
        title: 'Ciências Humanas e Bioética',
        subname: 'subarea',
        subcategory: 'Subáreas',
        lists: [
          { title: 'Bioética' },
          { title: 'Educação' },
          { title: 'Psicologia' },
          { title: 'Outra' }
        ]
      },
      {
        title: 'Lingüística, Letras e Artes',
        subname: 'subarea',
        subcategory: 'Subáreas',
        lists: [
        ]
      },


    ]
  },

  {
    title: 'Extensão',
    subname: 'area',
    subcategory: 'Áreas',
    description: '',
    lists: [
      {
        title: 'Comunicação',
        lists: []
      },
      {
        title: 'Cultura',
        lists: []
      },
      {
        title: 'Direitos Humanos e Justiça',
        lists: []
      },
      {
        title: 'Educação',
        lists: []
      },
      {
        title: 'Meio Ambiente',
        lists: []
      },
      {
        title: 'Saúde',
        lists: []
      },
      {
        title: 'Tecnologia e Produção',
        lists: []
      },
      {
        title: 'Trabalho',
        lists: []
      }
    ]
  }


]