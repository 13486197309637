import React, { useEffect, useRef, useState } from 'react';
import { Form } from '@unform/web';
import { FiMail, FiUser, FiLock, FiMap } from 'react-icons/fi';
import { Link } from 'react-router-dom';


import { MdError } from 'react-icons/md';

import * as Yup from 'yup';
import ReCAPTCHA from 'react-google-recaptcha';
import { FormHandles } from '@unform/core';
import Input from '../Forms/Input';
import Button from '../Forms/Button';
import Loader from '../Loader';
import MaskedInput from '../Forms/MaskedInput';
import { ResponseMessage } from '../ResponseMessage';
import getValidationErrors from '../../utils/getValidationErrors';
import { Confirmated, Error } from './styles';
import SelectSimple from '../Forms/SelectSimple';
import Whatsapp from '../WhatsAppCompartilhar';

import api from '../../services/api';
import { authTitle, recaptchaKey, urlWebsite } from '../../config';
import getCep from '../../services/cep';
import { FaBuilding, FaCheck, FaCheckCircle, FaGenderless, FaIdBadge, FaPhone } from 'react-icons/fa';
import CheckboxInput from '../Forms/CheckboxInput';
import { useLanguage } from '../../hooks/Language';
import { countries } from './countries';
import { universidades } from './universidades';
import { useAuth } from '../../hooks/Auth';
import { useToast } from '../../hooks/Toast';
import CourseProductsSubscriptionModule from '../../pages/CourseProductSubscription';
interface IRecoverFormData {
  name: string;
  email: string;
  password: string;
  phone: string;
  captcha: string;
  birth_date: string;
  document_number: string;
  extra_4: Array<string>,
  extra_5: Array<string>,
  extra_3: Array<string>,
  extra_1: Array<string>,
  extra_2: Array<string>,
  inscricao_1: Array<string>,
  inscricao_2: Array<string>
}

interface ICaptcha {
  getValue(): string;
}
declare global {
  interface Window {
    grecaptcha: any
  }
}
const FormContainer = ({ context = {} }) => {
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [errorMessage, setErrorMessage] = useState<Array<string>>([]);
  const [withPassword, setWithPassword] = useState(true);
  const formRef = useRef<FormHandles>(null);
  const recaptchaRef = useRef(null);
  const { translate } = useLanguage();
  const getCaptchaValue = (elem: ICaptcha): string => {
    return elem.getValue();
  };

  const { signIn } = useAuth();

  const [deficiencia, setDeficiencia] = useState('Não');
  const [categoryOptions, setCategoryOptions] = useState([]);
  const [category1Options, setCategory1Options] = useState([]);
  const [category, setCategory] = useState('');



  const [extra1, setExtra1] = useState('');
  const [extra2, setExtra2] = useState('');
  const [countryISO, setCountryISO] = useState('BR');

  const [inscricao1, setInscricao1] = useState('');
  const [inscricao2, setInscricao2] = useState('');

  const [company, setCompany] = useState('');


  const [categoryValue, setCategoryValue] = useState('');
  const [category1Value, setCategory1Value] = useState('');


  const [myProfile, setMyProfile] = useState<Record<string, any>>({});

  const loadProfile = async () => {
    const response = await api.get("/get-my-profile");

    const item = response.data ? { ...response.data } : {};

    if (item.inscricao_1) {
      setInscricao1(item.inscricao_1);
      item.inscricao_1 = [item.inscricao_1];
    }

    if (item.extra_1) {
      setExtra1(item.extra_1);
    }

    if (item.extra_3) {

      const index = options?.[item.extra_1]?.findIndex(i => i.label === item.extra_3);

      if (index < 0) {
        setCategoryValue('Outro? Qual?');
      }



    }


    if (item.extra_2) {
      setExtra2(item.extra_2);
      setCategory(item.extra_2);
      item.extra_2 = [item.extra_2];
    }

    if (item.inscricao_2) {
      setInscricao2(item.inscricao_2);
      item.inscricao_2 = [item.inscricao_2];
    }



    if (item.extra_3) {

      const index = options?.[item.extra_1]?.findIndex(i => i.label === item.extra_3);

      if (index < 0) {
        setCategory1Value('Outro? Qual?');
      }



    }

    if (item.category_id) {

      const index = options?.[item.extra_1]?.findIndex(i => i.label === item.category_id);

      if (index < 0) {
        setCategoryValue('Outro? Qual?');
      }



    }
    setMyProfile(item || {});
  }

  useEffect(() => {

    loadProfile();

  }, [])


  const options = {


    "Submissão de trabalhos": [


      { label: 'Biomedicina - diurno', value: 'Biomedicina - diurno' },
      { label: 'Biomedicina - noturno', value: 'Biomedicina - noturno' },
      { label: 'Enfermagem', value: 'Enfermagem' },
      { label: 'Farmácia', value: 'Farmácia' },
      { label: 'Física Médica', value: 'Física Médica' },
      { label: 'Fisioterapia', value: 'Fisioterapia' },
      { label: 'Fonoaudiologia', value: 'Fonoaudiologia' },
      { label: 'Gastronomia', value: 'Gastronomia' },
      { label: 'Gestão em Saúde', value: 'Gestão em Saúde' },
      { label: 'Informática Biomédica', value: 'Informática Biomédica' },
      { label: 'Medicina', value: 'Medicina' },
      { label: 'Nutrição', value: 'Nutrição' },
      { label: 'Psicologia', value: 'Psicologia' },

      { label: 'Química Medicinal', value: 'Química Medicinal' },

      { label: 'Tecnologia em Alimentos', value: 'Tecnologia em Alimentos' },
      { label: 'Toxicologia Analítica', value: ' Toxicologia Analítica' },
      { label: 'Outro', value: 'Outro? Qual?' },
    ],



    'Estudante de graduação': [
      { label: 'Biomedicina - diurno', value: 'Biomedicina - diurno' },
      { label: 'Biomedicina - noturno', value: 'Biomedicina - noturno' },
      { label: 'Biotecnologia', value: 'Biotecnologia' },
      { label: 'Ciências Biológicas/Biologia', value: 'Ciências Biológicas/Biologia' },
      { label: 'Ciências Sociais', value: 'Ciências Sociais' },
      { label: 'Educação Física', value: 'Educação Física' },
      { label: 'Enfermagem', value: 'Enfermagem' },
      { label: 'Engenharia Ambiental', value: 'Engenharia Ambiental' },
      { label: 'Engenharia de Alimentos', value: 'Engenharia de Alimentos' },
      { label: 'Farmácia', value: 'Farmácia' },
      { label: 'Física', value: 'Física' },
      { label: 'Física Médica', value: 'Física Médica' },
      { label: 'Fisioterapia', value: 'Fisioterapia' },
      { label: 'Fonoaudiologia', value: 'Fonoaudiologia' },
      { label: 'Gastronomia', value: 'Gastronomia' },
      { label: 'Gestão em Saúde', value: 'Gestão em Saúde' },
      { label: 'Informática Biomédica', value: 'Informática Biomédica' },
      { label: 'Medicina', value: 'Medicina' },
      { label: 'Nutrição', value: 'Nutrição' },
      { label: 'Odontologia', value: 'Odontologia' },
      { label: 'Pedagogia', value: 'Pedagogia' },
      { label: 'Psicologia', value: 'Psicologia' },
      { label: 'Química', value: 'Química' },
      { label: 'Química Medicinal', value: 'Química Medicinal' },
      { label: 'Saúde Coletiva', value: 'Saúde Coletiva' },
      { label: 'Serviço Social', value: 'Serviço Social' },
      { label: 'Tecnologia em Alimentos', value: 'Tecnologia em Alimentos' },
      { label: 'Toxicologia Analítica', value: ' Toxicologia Analítica' },
      { label: 'Outro', value: 'Outro? Qual?' },
    ],
    'Estudante de pós-graduação': [
      { label: ' Especialização', value: ' Especialização' },
      { label: ' Residência médica', value: ' Residência médica' },
      { label: ' Residência multiprofissional', value: ' Residência multiprofissional' },
      { label: ' Mestrado', value: ' Mestrado' },
      { label: ' Doutorado', value: ' Doutorado' },
      { label: 'Outro', value: 'Outro? Qual?' },
    ],
    "Seminário de Internacionalização": [
      { label: "Biomedicina - diurno", value: "Biomedicina - diurno" },
      { label: "Biomedicina - noturno", value: "Biomedicina - noturno" },
      { label: "Biotecnologia", value: "Biotecnologia" },
      { label: "Ciências Biológicas/Biologia", value: "Ciências Biológicas/Biologia" },
      { label: "Ciências Sociais", value: "Ciências Sociais" },
      { label: "Educação Física", value: "Educação Física" },
      { label: "Enfermagem", value: "Enfermagem" },
      { label: "Engenharia Ambiental", value: "Engenharia Ambiental" },
      { label: "Engenharia de Alimentos", value: "Engenharia de Alimentos" },
      { label: "Farmácia", value: "Farmácia" },
      { label: "Física", value: "Física" },
      { label: "Física Médica", value: "Física Médica" },
      { label: "Fisioterapia", value: "Fisioterapia" },
      { label: "Fonoaudiologia", value: "Fonoaudiologia" },
      { label: "Gastronomia", value: "Gastronomia" },
      { label: "Gestão em Saúde", value: "Gestão em Saúde" },
      { label: "Informática Biomédica", value: "Informática Biomédica" },
      { label: "Medicina", value: "Medicina" },
      { label: "Nutrição", value: "Nutrição" },
      { label: "Odontologia", value: "Odontologia" },
      { label: "Pedagogia", value: "Pedagogia" },
      { label: "Psicologia", value: "Psicologia" },
      { label: "Química", value: "Química" },
      { label: "Química Medicinal", value: "Química Medicinal" },
      { label: "Saúde Coletiva", value: "Saúde Coletiva" },
      { label: "Serviço Social", value: "Serviço Socia" },
      { label: "Tecnologia em Alimentos", value: "Tecnologia em Alimentos" },
      { label: "Toxicologia Analítica", value: "Toxicologia Analítica" },
      { label: 'Outro', value: 'Outro? Qual?' }]
  }


  useEffect(() => {
    if (extra2 === 'Submissão de trabalhos') {
      setCategory('Submissão de trabalhos');
    }
  }, [extra2])
  useEffect(() => {



    setCategoryOptions(options[category] ? options[category] : []);


  }, [category])

  useEffect(() => {



    setCategory1Options(options[extra1] ? options[extra1] : []);


  }, [extra1])

  const states = [
    { uf: 'AC', label: 'Acre (AC)', value: 1 },
    { uf: 'AL', label: 'Alagoas (AL)', value: 2 },
    { uf: 'AP', label: 'Amapá (AP)', value: 3 },
    { uf: 'AM', label: 'Amazonas (AM)', value: 4 },
    { uf: 'BA', label: 'Bahia (BA)', value: 5 },
    { uf: 'CE', label: 'Ceará (CE)', value: 6 },
    { uf: 'DF', label: 'Distrito Federal (DF)', value: 7 },
    { uf: 'ES', label: 'Espírito Santo (ES)', value: 8 },
    { uf: 'GO', label: 'Goiás (GO)', value: 9 },
    { uf: 'MA', label: 'Maranhão (MA)', value: 10 },
    { uf: 'MT', label: 'Mato Grosso (MT)', value: 11 },
    { uf: 'MS', label: 'Mato Grosso do Sul (MS)', value: 12 },
    { uf: 'MG', label: 'Minas Gerais (MG)', value: 13 },
    { uf: 'PA', label: 'Pará (PA)', value: 14 },
    { uf: 'PB', label: 'Paraíba (PB)', value: 15 },
    { uf: 'PR', label: 'Paraná (PR)', value: 16 },
    { uf: 'PE', label: 'Pernambuco (PE)', value: 17 },
    { uf: 'PI', label: 'Piauí (PI)', value: 18 },
    { uf: 'RJ', label: 'Rio de Janeiro (RJ)', value: 19 },
    { uf: 'RN', label: 'Rio Grande do Norte (RN)', value: 20 },
    { uf: 'RS', label: 'Rio Grande do Sul (RS)', value: 21 },
    { uf: 'RO', label: 'Rondônia (RO)', value: 22 },
    { uf: 'RR', label: 'Roraima (RR)', value: 23 },
    { uf: 'SC', label: 'Santa Catarina (SC)', value: 24 },
    { uf: 'SP', label: 'São Paulo (SP)', value: 25 },
    { uf: 'SE', label: 'Sergipe (SE)', value: 26 },
    { uf: 'TO', label: 'Tocantins (TO)', value: 27 },
    { uf: '', label: 'Não sou Brasileiro', value: 28 },
  ];

  const { addToast } = useToast();

  const handleNewLogin = async (data: IRecoverFormData) => {
    setLoading(true);



    try {
      formRef.current?.setErrors({});

      let shape: Record<string, any> = {

      };



      if (!inscricao2) {
        addToast({ title: 'Selecione a opção Mostra de Ensino, Pesquisa e Extensão', type: "info" })
        setLoading(false);
        return;
      }


      if (inscricao2) {
        shape.extra_2 = Yup.string().required('O campo Tipo de inscrição da Mostra é obrigatório');
        shape.category_id = Yup.string().required('O campo Categoria da Mostra é obrigatório');





        if (categoryOptions?.length > 0) {
          shape.facebook = Yup.string().required('O campo SubCategoria da Mostra é obrigatório');

        };
      };


      const schema = Yup.object().shape(shape);

      await schema.validate(data, { abortEarly: false });

      const captchaValue =
        getCaptchaValue(recaptchaRef?.current || { getValue: () => '' }) ||
        false;




      const response = await api.post(`/update-my-profile`, {
        ...data,


        extra_2: data?.extra_2 && data?.extra_2?.[0] ? data?.extra_2?.[0] : '',

        inscricao_2: data?.inscricao_2?.[0] ? data?.inscricao_2?.[0] : '',


      });


      window.location.reload();
      setSuccess(true);

      setLoading(false);
    } catch (err) {
      window?.grecaptcha?.reset();
      if (err instanceof Yup.ValidationError) {
        const errors = getValidationErrors(err);
        if (formRef && formRef.current && formRef.current.setErrors) {
          formRef.current.setErrors(errors);
        }
        setLoading(false);

        const errorsString = Object.keys(errors).reduce(
          (prev: Array<string>, key: string): Array<string> => {
            prev = [...prev, errors[key]];
            return prev;
          },
          [],
        );

        setErrorMessage(errorsString);

        return;
      }

      setErrorMessage([
        err?.response?.data?.message ||
        err?.response?.data?.error ||
        'Erro ao gerar seu cadastro',
      ]);
    }
    setLoading(false);
  };



  const handleNewSeminario = async (data: IRecoverFormData) => {
    setLoading(true);



    try {
      formRef.current?.setErrors({});

      let shape: Record<string, any> = {

      };



      if (!inscricao1) {
        addToast({ title: 'Selecione pelo a opção Seminário de Internacionalização', type: "info" })
        setLoading(false);
        return;
      }

      if (inscricao1) {
        shape.extra_1 = Yup.string().required('O campo Categoria do Seminário é obrigatório');

        if (category1Options?.length > 0) {
          shape.extra_3 = Yup.string().required('O campo SubCategoria do Seminário é obrigatório');

        };
      }


      const schema = Yup.object().shape(shape);

      await schema.validate(data, { abortEarly: false });

      const captchaValue =
        getCaptchaValue(recaptchaRef?.current || { getValue: () => '' }) ||
        false;




      const response = await api.post(`/update-my-profile`, {
        ...data,


        inscricao_1: data?.inscricao_1?.[0] ? data?.inscricao_1?.[0] : '',


      });


      window.location.reload();

      setLoading(false);
    } catch (err) {
      window?.grecaptcha?.reset();
      if (err instanceof Yup.ValidationError) {
        const errors = getValidationErrors(err);
        if (formRef && formRef.current && formRef.current.setErrors) {
          formRef.current.setErrors(errors);
        }
        setLoading(false);

        const errorsString = Object.keys(errors).reduce(
          (prev: Array<string>, key: string): Array<string> => {
            prev = [...prev, errors[key]];
            return prev;
          },
          [],
        );

        setErrorMessage(errorsString);

        return;
      }

      setErrorMessage([
        err?.response?.data?.message ||
        err?.response?.data?.error ||
        'Erro ao gerar seu cadastro',
      ]);
    }
    setLoading(false);
  };

  const getCepFromApi = async (value: string): Promise<void> => {
    const cepResponse = await getCep(value);


    const state = cepResponse.uf
      ? states.find(st => {
        return st.uf === cepResponse.uf;
      })
      : false;

    if (!cepResponse.error) {
      formRef?.current?.setFieldValue('address', cepResponse.logradouro);
      formRef?.current?.setFieldValue('neighborhood', cepResponse.bairro);
      formRef?.current?.setFieldValue('city', cepResponse.localidade);
      if (state) {

        formRef?.current?.setFieldValue('state', state.uf);
      }
    }
  };

  const { user } = useAuth();

  return (
    <>
      {loading === true && (
        <Loader message="Preparando seu cadastro..." position="fixed" />
      )}

      <aside>
        <h1 style={{ width: '100%', margin: '20px auto', textAlign: 'center', color: '#333' }}>Minhas inscrições</h1>

        <p style={{ background: 'green', color: '#fff', fontSize: '18px', padding: '10px', margin: '10px auto' }}>Você está inscrito no evento!</p>

        <CourseProductsSubscriptionModule />

        {/* <aside style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', margin: '30px 0px' }}> <Confirmated><FaCheckCircle /> <h3>Inscrição confirmada</h3> </Confirmated> </aside> */}

      </aside>

    </>)
};

export default FormContainer;
