import React, { useEffect, useState } from 'react';
import Button from '../../../../../../components/Forms/Button';
import Submission from '../Submission';
import { Container } from './styles';
import MyPapers from '../MyPapers';
import { useParams } from 'react-router';
import { Link } from 'react-router-dom';
import { authTitle, defaultProjectHash, supportNumber } from '../../../../../../config';
import Whatsapp from '../../../../../../components/Whatsapp';
import UpdateProfile from '../../../../../../components/FormContainerUpdate';
import api from '../../../../../../services/api';
import { cpf, allowBoth } from './verify';
import { useAuth } from '../../../../../../hooks/Auth';
import { useConfiguration } from '../../../../../../hooks/Configuration';

interface IParams {
  tab: string;
}

const CientificListSubmission: React.FC = () => {
  const [type, setType] = useState('profile');
  const { configuration } = useConfiguration();
  const [submissions, setSubmissions] = useState<Array<Record<string, any>>>([])
  const { user } = useAuth();
  const [validTab, setValidTab] = useState({
    home: '',
    submeter: 'submeter'
  });

  const load = async () => {

    const submission = await api.get(`/list-submission-selective/${configuration?.url}`);

    if (submission?.data?.rows?.length > 0) {

      const submissionList = [];

      submission?.data?.rows?.map(i => {
        if (i?.allowed_document_numbers?.length > 0) {

          const indexDoc = i?.allowed_document_numbers?.find(doc => doc === user?.document_number?.toString()?.replace(/[^0-9/]/g, ''));

          if (indexDoc >= 0) {
            i.not_allowed = false;
          }
          else {
            i.not_allowed = true;
          }

        }
      })


      setSubmissions(submission?.data?.rows?.reverse());
    }

  }

  useEffect(() => {
    load();
  }, [])

  const indexCPF = cpf.findIndex(c => c === user?.document_number);
  const indexAllowBothCPF = allowBoth.findIndex(c => c === user?.document_number);
  return (
    <>

      <Container >

        <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column', flexWrap: 'wrap', justifyContent: 'center', padding: '10px' }}>
          <h2 style={{ color: '#333' }}>Selecione a categoria</h2>
          {submissions?.map(sub => {
            console.log(sub)
            return <>
              {!sub?.not_allowed ? <a href={`/trabalhos/submeter-trabalho/${sub?.hash}`}><button className='defaultButtonReverse' style={{ height: 'auto', width: '100%', minWidth: '300px', maxWidth: '300px' }}>{sub.title}</button></a> : <></>}

              {sub.not_allowed ? <button className='defaultButtonReverse' style={{ height: 'auto', width: '100%', minWidth: '300px', maxWidth: '300px' }}>{sub.title}<br /> <br /><p style={{ color: '#fff', fontSize: '12px' }}>Somente CPFs liberados podem submeter.<br /><br /> CPF não está liberado.<br /><br />Caso considere que deva submeter, entre em contato com a organização</p></button> : <></>}


            </>
          })}
        </div>


      </Container>
    </>
  );
};
export default CientificListSubmission;
