import React, { useEffect, useState } from 'react';
import Button from '../../../../../../components/Forms/Button';

import { Container } from './styles';
import AvaliatePapers from '../MyPapers';
import AvaliatePapersOral from '../MyPapersOral';
import AvaliatePapersFinished from '../AvaliatePapersFinished';
import { useParams } from 'react-router';
import { Link } from 'react-router-dom';
import { authTitle, supportNumber } from '../../../../../../config';
import Whatsapp from '../../../../../../components/Whatsapp';
import { useAuth } from '../../../../../../hooks/Auth';
import MyAvaliationDates from '../MyDates';
import MyPapersVisualization from '../MyPapersVisualization';
import { useConfiguration } from '../../../../../../hooks/Configuration';

interface IParams {
  tab: string;
}

const CientificHome: React.FC = () => {
  const [type, setType] = useState('');
  const { tab } = useParams<IParams>();
  const { user } = useAuth();
  const { configuration } = useConfiguration();
  const [validTab, setValidTab] = useState({
    home: '',
    avaliar: 'avaliar',
    trabalhos_avaliados: 'trabalhos_avaliados'
  });

  useEffect(() => {
    if (!user) {
      window.location.href = `/app/${configuration?.url}/login-avaliador`;
    }

    if (user.profile !== 10 && user.profile !== 1) {
      window.location.href = `/app/${configuration?.url}/login-avaliador`;
    }
    return;
  }, [])

  useEffect(() => {
    if (tab && validTab[tab]) {
      setType(validTab[tab]);
    }
  }, [tab])



  return (
    <>

      <Container >
        <button className='buttonBigGreen'  >
          ÁREA DO AVALIADOR
        </button>
        <div style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap', justifyContent: 'center', padding: '10px' }}>
          <Button onClick={() => { setType('') }}>Página inicial</Button>
          { /* <a target="_BLANK" href="/arquivos_apoio/orientacoes-avaliadores.pdf"><Button style={{ background: 'orange' }}>Orientações para avaliação</Button></a> */}
          <Button onClick={() => { setType('horarios') }}>Meus Horários </Button>
          <Button onClick={() => { setType('avaliacoes') }}>Minhas Avaliações </Button>
          {/*   <Button onClick={() => { setType('avaliacoes_oral') }}>Avaliações orais</Button> */}
          <Whatsapp number={supportNumber} text={`${authTitle}: Sou um avaliador de trabalhos`} message="Suporte Técnico" />

        </div>

        {type === '' && <div style={{ color: '#333', background: '#fff', margin: '20px auto', width: '100%', maxWidth: '400px', border: '2px solid #fff', padding: '20px', borderRadius: '20px', display: 'flex', flexDirection: 'column', alignItems: 'flex-start', justifyContent: 'flex-start' }}>
          <strong style={{ color: '#333' }}>Prezado (a) Avaliador (a),</strong>
          <br /><br />Sua colaboração na avaliação online de resumos submetidos na {authTitle}  e/ou nas sessões de apresentação oral é muito bem-vinda.
          <br /><br />Acima deste texto, você encontrará os botões para visualizar os seus horários de avaliação e para visualização dos resumos.
          <br /><br />Dúvidas e informações, clique no botão de suporte técnico.
          <br /><br />Seja bem-vindo(a)!
          <br /><br />Obrigado por colaborar conosco!
        </div>}

        {type === 'avaliacoes' && <MyPapersVisualization />}
        {type === 'horarios' && <MyAvaliationDates />}
        {type === 'avaliacoes_oral' && <AvaliatePapersOral />}
        {type === 'avaliar-trabalhos' && <AvaliatePapersFinished />}

      </Container>
    </>
  );
};
export default CientificHome;
