import React from 'react';

import {
  SearchComponent,
  ISearchComponent,
} from '../../../../core/components/SearchComponent';

import lineModules from '../../../../core/components/lineModules'
import { Container } from './styles';
import { useSocket } from '../../../../../../hooks/Socket';
import { Button } from '../../../../core/components/Forms/Button/styles';
import uploadConfirmation from './modules/uploadConfirmations';
import viewAnais from './modules/ViewAnais';

import viewAnaisComplete from './modules/ViewAnaisOnModal';

import review from './modules/Review';
import sendToOrientator from './modules/SendToOrientator';

interface ICreateModule {
  lineData: Record<string, any>;



}

interface ValidationRules {
  type: 'email' | 'required' | 'min' | 'max' | 'length';
  message?: string;
  quantity?: number;
}

interface ValidationSchema {
  name: string;
  type: 'string' | 'number' | 'boolean';
  label: string;
  rules: Array<ValidationRules>;
}

interface ILine {
  [key: string]: string;
}

const CientificSubmission: React.FC = () => {
  const endpoint = '/cientific-submissions';
  const { socket } = useSocket();
  const title = 'Submissão de Trabalhos';


  const schema: Record<string, any> = {
    _id: { ref: '_id', column: '_id', label: 'ID' },
    hash: { ref: 'hash', column: 'hash', label: 'Hash', show: false },
    presentation_image: { ref: 'presentation_image', column: 'presentation_image', label: 'Pôster', type: 'image' },
    cientific_selective_id: {
      ref: 'cientific_selective_id',
      column: 'cientific_selective_id_response',
      includeColumn: 'title',
      label: 'Projeto',
    },
    author: { ref: 'author', column: 'author', label: 'Autor' },
    author_institution: { ref: 'author_institution', column: 'author_institution', label: 'Instituição do autor' },
    author_email: { ref: 'author_email', column: 'author_email', label: 'Email do autor', show: false },
    author_document_number: { ref: 'author_document_number', column: 'author_document_number', label: 'CPF do autor', show: false },
    co_authors: { ref: 'co_authors', column: 'co_authors', label: 'Coautores', show: false },
    orientator: { ref: 'orientator', column: 'orientator', label: 'Orientador', show: false },
    orientator_institution: { ref: 'orientator_institution', column: 'orientator_institution', label: 'Instituição do Orientador', show: false },
    orientator_email: { ref: 'orientator_email', column: 'orientator_email', label: 'Email do Orientador', show: false },
    orientator_document_number: { ref: 'orientator_document_number', column: 'orientator_document_number', label: 'CPF do Orientador', show: false },

    orientator_approval: { ref: 'orientator_approval', column: 'orientator_approval', label: 'Aprovado pelo orientador?' },

    title: { ref: 'title', column: 'title', label: 'Título' },
    short_description: { ref: 'short_description', column: 'short_description', label: 'Resumo', show: false },
    area: { ref: 'area', column: 'area', label: 'Área', show: false },
    category: { ref: 'category', column: 'category', label: 'Categoria' },
    subarea: { ref: 'subarea', column: 'subarea', label: 'SubÁrea', show: false },
    keywords: { ref: 'keywords', column: 'keywords', label: 'Palavras-chave', show: false },
    presentation_time: { ref: 'presentation_time', column: 'presentation_time', label: 'Turno de apresentação', show: false },
    have_agency: { ref: 'have_agency', column: 'have_agency', label: 'O trabalho tem financiamento de agência de fomento?', show: false },
    agency: { ref: 'agency', column: 'agency', label: 'Agência de fomento', show: false },
    status_name: { ref: 'status_name', column: 'status_name', label: 'Status de aprovação' },
    approval_notes: { ref: 'approval_notes', column: 'approval_notes', label: 'Observações' },
    nested_co_authors: { ref: 'nested_co_authors', column: 'nested_co_authors', label: 'Coautores', show: false },

    status: {
      ref: 'status',
      column: 'status',

      label: 'Status',

    },
    createdAt: {
      ref: 'createdAt',
      column: 'createdAt',
      label: 'Data de Criação',
      mask: 'date',
    },
  };


  const formSchema = {
    cientific_selective_id: {
      alias: 'cientific_selective_id',
      model: 'selectApi',
      name: 'cientific_selective_id',
      label: 'Projeto de Submissão',
      endpoint,
    },
    status: {
      alias: 'status',
      model: 'selectSimple',
      name: 'status',
      label: 'Status',
      options: [
        { label: 'Avaliado', value: 'Avaliado' },
        { label: 'Não aprovado', value: 'Não aprovado' },

        { label: 'Aguardando avaliação', value: 'Aguardando avaliação' },
        { label: 'Correção solicitada', value: 'Correção solicitada' }
      ]
    },
    orientator_approval: {
      model: 'input',
      name: 'orientator_approval',
      label: 'Aprovado pelo orientador?',
    },
    status_name: {
      model: 'input',
      name: 'status_name',
      label: 'Status de aprovação',
    },
    oral_paper_id: {
      alias: 'oral_paper_id',
      model: 'selectSimple',
      name: 'oral_paper_id',
      label: 'Indicado para apresentação oral?',
      options: [
        { label: 'Sim', value: 'Sim' },
        { label: 'Não', value: 'Não' },

      ]
    },

    room: {
      model: 'input',
      name: 'room',
      label: 'Sala',
    },
    section: {
      model: 'input',
      name: 'section',
      label: 'Seção',
    },
    date: {
      model: 'input',
      name: 'date',
      label: 'Data',
    },
    hour: {
      model: 'input',
      name: 'hour',
      label: 'Hora',
    },
    theme: {
      model: 'input',
      name: 'theme',
      label: 'Temática',
    },
    totem: {
      model: 'input',
      name: 'totem',
      label: 'Totem',
    },

    title: {
      model: 'input',
      name: 'title',
      label: 'Título',
    },
    short_description: {
      model: 'richText',
      name: 'short_description',
      label: 'Descritivo',
    },
    observations: {
      model: 'richText',
      name: 'observations',
      label: 'Correções',
    },
    author: {
      model: 'input',
      name: 'author',
      label: 'Autor',
    },
    author_institution: {
      model: 'input',
      name: 'author_institution',
      label: 'Instituição do Autor',
    },

    author_email: {
      model: 'input',
      name: 'author_email',
      label: 'Email do Autor',
    },
    author_document_number: {
      model: 'input',
      name: 'author_document_number',
      label: 'CPF do Autor',
    },
    orientator: {
      model: 'input',
      name: 'orientator',
      label: 'Orientador',
    },
    orientator_institution: {
      model: 'input',
      name: 'orientator_institution',
      label: 'Instituição do Orientador',
    },



    orientator_email: {
      model: 'input',
      name: 'orientator_email',
      label: 'Email do Orientador',
    },

    orientator_document_number: {
      model: 'input',
      name: 'orientator_document_number',
      label: 'CPF do Orientador',
    },
    category: {
      model: 'input',
      name: 'category',
      label: 'Categoria',
    },
    area: {
      model: 'input',
      name: 'area',
      label: 'Área',
    },
    subarea: {
      model: 'input',
      name: 'subarea',
      label: 'Subárea',
    },

    extra_categories: {
      model: 'ArrayInputIncluder',
      name: 'extra_categories',
      label: 'ODS',
    },
    nested_extra_categories: {
      model: 'input',
      name: 'nested_extra_categories',
      label: 'ODS em texto',
    },

    language: {
      model: 'input',
      name: 'language',
      label: 'Deseja apresentar em inglês?',
    },

  };

  const formSchemaUpdate = {
    ...formSchema,

  };



  const formValidation: Record<string, ValidationSchema> = {

  };


  function updateLessonModule({

    lineData,


  }: ICreateModule): JSX.Element {

    const clearLessonCache = () => {
      socket?.emit('clearChatCache', { room_id: lineData.id });
    }

    return <Button style={{ padding: '5px' }} onClick={() => clearLessonCache()}>Limpar Cache</Button>

  }



  const config: ISearchComponent = {
    idKey: endpoint,
    title,
    endpoint,
    schema,
    autoStart: true,
    headModules: [
      () =>
        lineModules.create({
          title,
          formSchema,
          validationSchema: formValidation,
          endpoint,
        }),

    ],
    bodyModules: [
      (lineData: Record<string, any>) =>
        review({
          lineData,

        }),




      (lineData: Record<string, any>) =>
        lineModules.updateUnique({
          lineData,
          title,
          formSchema: formSchemaUpdate,
          validationSchema: {},
          endpoint,
        }),
      (lineData: Record<string, any>) =>
        lineModules.delete({
          lineData,
          title,
          formSchema,
          validationSchema: formValidation,
          endpoint,
        }),
    ],
  };

  const config2: ISearchComponent = {
    idKey: endpoint,
    title: 'Trabalhos submetidos',
    endpoint: endpoint,
    schema: schema,
    autoStart: true,
    headModules: [

      () =>
        lineModules.create({
          title,
          formSchema,
          validationSchema: formValidation,
          endpoint,
        }),

      () =>
        uploadConfirmation(),
    ],
    bodyModules: [

      (lineData: Record<string, any>) =>
        review({
          lineData,

        }),

      (lineData: Record<string, any>) =>
        sendToOrientator({
          lineData,

        }),

      /*
            (lineData: Record<string, any>) =>
              viewAnais({
                lineData,
      
              }), */
      (lineData: Record<string, any>) =>
        lineModules.updateUnique({
          lineData,
          title,
          formSchema: formSchemaUpdate,
          validationSchema: {},
          endpoint,
        }),
      (lineData: Record<string, any>) =>
        lineModules.delete({
          lineData,
          title,
          formSchema,
          validationSchema: formValidation,
          endpoint,
        }),
    ],
  };

  return (
    <Container>

      <SearchComponent {...config2} />
    </Container>
  );
};

export default CientificSubmission;
