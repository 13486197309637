import React from 'react';
import { FaEdit, FaEye, FaIntercom, FaRadiationAlt } from 'react-icons/fa';
import CopyToClipboard from '../../../../../core/components/CopyToClipboard';
import { urlWebsite } from '../../../../../../../config';
import { useSocket } from '../../../../../../../hooks/Socket';
import { useModal } from '../../../../../../../hooks/Modal';
import Review from '../../../modules/Review';
import api from '../../../../../../../services/api';

interface ICreateModule {
  lineData: Record<string, any>;
}

export default function ReviewModule({
  lineData,
}: ICreateModule): JSX.Element {

  const { addModal } = useModal();

  const openReview = async (line) => {

    const cientificSubmission = await api?.get(`/get-submission-selection-by-id/${lineData?.project_id}/${lineData?.cientific_selective_id_response?._id}`);

    if (cientificSubmission?.data?.allow_edit === 'no') {
      addModal({ title: '', theme: 'whiteModalMini', content: <h2 style={{ color: '#333', width: '100%', textAlign: 'center' }}>Edição desabilitada pela organização</h2> })
      return;
    }

    window.location.href = `/trabalhos/submeter-trabalho/${cientificSubmission?.data?.hash}/${lineData?._id?.toString()}`;
  }


  const moduleCreate = (): JSX.Element => {

    return lineData?.orientator_approval !== 'Sim' ? <div onClick={() => openReview(lineData)} style={{ cursor: 'pointer', width: '30px', height: '30px', display: 'flex', alignItems: 'center', justifyContent: 'center', borderRadius: '50%', border: '1px solid #333', margin: '5px' }}>
      <FaEdit className="hover" style={{ cursor: 'pointer' }} size={20} /> </div> : <></>;
  }



  return moduleCreate();
}
