import React, { useCallback, useRef, useEffect, useState } from 'react';

import { IconType } from 'react-icons/lib';
import { Link, useParams } from 'react-router-dom';
import { FiChevronRight, FiChevronLeft } from 'react-icons/fi';
import api from '../../services/api';
import { date, hour, dateLanguage, simpleDate } from '../../utils/date';
import { Container } from './styles';
import { addHours } from 'date-fns';
import { useToast } from '../../hooks/Toast';
import { Form } from '@unform/web';

import { string } from 'yup';

import { useLanguage } from '../../hooks/Language';
import { FaArrowDown, FaArrowUp } from 'react-icons/fa';
import { MdKeyboardArrowDown, MdKeyboardArrowUp } from 'react-icons/md';
import Favorite from '../../components/ClassRoomContainer/modules/Favorite';
import { useAuth } from '../../hooks/Auth';
import { useConfiguration } from '../../hooks/Configuration';
import { defaultProjectHash, hash } from '../../config';
import { loadFromJson } from '../../utils/loadFromJson';

interface IOption {
  value: string;
  label: string;
}

interface IProgram {
  date: Date;
  formatted_date?: string;
  start_hour: Date;
  formatted_start_hour?: string;
  end_date: Date;
  formatted_end_date?: string;
  title: string;
  category: string;
  room_reference_id_response: Record<string, any>;
  description: string;
}

interface IProgramFilter {
  date: Date;
  formatted_date: string;
  program: Array<IProgram>;
  simpleDate: string;
  day_reference?: string;
  category?: string;
  room_name?: string;
}

interface IProps {
  filterCategory?: string;
  removeCategory?: string;
}
const ProgramTable: React.FC<IProps> = ({ filterCategory = '', removeCategory = '' }) => {
  const [programs, setPrograms] = useState<Array<IProgramFilter>>([]);
  const [referenceProgram, setReferenceProgram] = useState<Array<IProgramFilter>>([]);
  const [currentDay, setCurrentDay] = useState(0);

  const [search, setSearch] = useState('');
  const [currentProgram, setCurrentProgram] = useState('');
  const [roomList, setRoomList] = useState<Array<IOption>>([]);
  const { addToast } = useToast();
  const { user, handleApiErrors } = useAuth();
  const { translate } = useLanguage();
  const { configuration } = useConfiguration();
  const { projectHash = defaultProjectHash, eventHash } = useParams<Record<string, any>>();
  const [filter, setFilter] = useState({
    date: '',
    category: '',
    search: ''
  });

  const addFilter = (column, value) => {

    const newFilter = { ...filter };
    newFilter[column] = value;

    setFilter(newFilter);

  }

  const newSearch = (data) => {

    const newFilter = { ...filter };
    newFilter.search = search;

    setFilter(newFilter);

  }

  useEffect(() => {

    let programsContent: Array<IProgramFilter> = [];

    if (filter.date) {
      programs.map(p => {
        if (p.simpleDate === filter.date) {
          programsContent.push({ ...p });
        }
      })
    }
    else {
      programsContent = [...programs];
    }



    if (filter.category !== "") {
      const filteredProgram: Array<IProgramFilter> = [];
      programsContent.map((item, index) => {
        filteredProgram.push({ ...item, program: [] });
        return false;
      })

      programsContent.map((p1) => {

        const index = filteredProgram.findIndex(p => p.simpleDate === p1.simpleDate);
        if (index >= 0) {
          p1.program.map(p1_program => {
            const text = p1_program?.category?.toString()?.toLowerCase();
            const searchValue = filter?.category?.toString()?.toLowerCase();

            const indexText = text === searchValue;


            if (indexText) {

              filteredProgram[index].program.push(p1_program);
            }
          })
        }
      })

      programsContent = [...filteredProgram];
    }



    if (filter.search !== "") {
      const filteredProgram: Array<IProgramFilter> = [];
      programsContent.map((item, index) => {
        filteredProgram.push({ ...item, program: [] });
        return false;
      })

      programs.map((p1) => {

        const index = filteredProgram.findIndex(p => p.simpleDate === p1.simpleDate);
        if (index >= 0) {
          p1.program.map(p1_program => {
            const text = p1_program?.title?.toString()?.toLowerCase();
            const text2 = p1_program?.description?.toString()?.toLowerCase();
            const searchValue = filter?.search?.toString()?.toLowerCase();
            let indexText = text?.indexOf(searchValue);

            if (indexText < 0) {
              indexText = text2?.indexOf(searchValue);

            }


            if (indexText >= 0) {

              filteredProgram[index].program.push(p1_program);
            }
          })
        }
      })

      programsContent = [...filteredProgram];
    }

    setReferenceProgram(programsContent);

  }, [programs, filter])

  const checkUser = async (id) => {

    try {
      const response = await api.post(`/check-activity/${id}`);

      if (response && response.data) {
        window.open(response?.data?.url, '_BLANK');
      }
      else {
        addToast({ type: "error", title: "Inscrição não encontrada" });
      }


    } catch (err) {
      addToast({ type: "error", title: "Inscrição não encontrada" });
    }
  }


  const groupList = {

    room_reference_id: (rep, program_ref: Array<Record<string, any>> = []) => {
      const index = program_ref.findIndex(
        day => day?.formatted_date === rep.formatted_date,
      );

      if (index < 0) {
        const item: IProgramFilter = {
          simpleDate: rep.simpleDate,
          formatted_date: rep.formatted_date,
          program: [{ ...rep }],
          room_name: rep.room_reference_id,
          date: rep.date
        };

        if (rep?.day_reference) {
          item.day_reference = `${rep?.day_reference || ""} `;

        }

        program_ref.push(item);

      } else {
        program_ref[index].program.push({ ...rep });

        if (rep?.day_reference) {

          program_ref[index].day_reference = program_ref[index].day_reference ? `${program_ref[index].day_reference || ""}<br/> ${rep?.day_reference || ""} ` : `${rep?.day_reference || ""} `;
        }
      }

      return program_ref;
    },

    category: (rep, program_ref: Array<Record<string, any>> = []) => {
      /* const index = program_ref.findIndex(
day => day?.formatted_date === rep.formatted_date && day?.category === rep.category,
); */
      const index = program_ref.findIndex(
        day => day?.formatted_date === rep.formatted_date,
      );
      if (index < 0) {
        const item: IProgramFilter = {
          simpleDate: rep.simpleDate,
          formatted_date: rep.formatted_date,
          program: [{ ...rep }],
          category: rep.category,
          date: rep.date
        };

        if (rep?.day_reference) {
          item.day_reference = `${rep?.day_reference || ""} `;

        }

        program_ref.push(item);

      } else {
        program_ref[index].program.push({ ...rep });

        if (rep?.day_reference) {

          program_ref[index].day_reference = program_ref[index].day_reference ? `${program_ref[index].day_reference || ""}<br/> ${rep?.day_reference || ""} ` : `${rep?.day_reference || ""} `;
        }
      }

      return program_ref;
    },


    default: (rep, program_ref: Array<Record<string, any>> = []) => {
      const index = program_ref.findIndex(
        day => day?.formatted_date === rep.formatted_date,
      );

      if (index < 0) {
        const item: IProgramFilter = {
          simpleDate: rep.simpleDate,
          formatted_date: rep.formatted_date,
          program: [{ ...rep }],
          date: rep.date
        };

        if (rep?.day_reference) {
          item.day_reference = `${rep?.day_reference || ""} `;

        }

        program_ref.push(item);

      } else {
        program_ref[index].program.push({ ...rep });

        if (rep?.day_reference) {

          program_ref[index].day_reference = program_ref[index].day_reference ? `${program_ref[index].day_reference || ""}<br/> ${rep?.day_reference || ""} ` : `${rep?.day_reference || ""} `;
        }
      }

      return program_ref;
    }
  };

  const load = async () => {
    let program_ref: Array<IProgramFilter> = [];
    const rooms = {};

    try {

      let data: Array<Record<string, any>> = [];

      const jsonData = await loadFromJson({ hash: hash, project_id: projectHash, table: 'Program' });

      if (jsonData) {
        data = jsonData || [];
      }
      else {
        const response = await api.get(eventHash ? `/programs-list/${projectHash}/${eventHash}` : `/programs-list/${projectHash}`);

        data = response.data.rows;
      }

      if (data?.length >= 0 && filterCategory) {

        data = data.filter(item => item.category === filterCategory);

      }

      if (data?.length > 0 && removeCategory) {
        data = data.filter(item => item.category !== removeCategory);
      }

      if (data) {
        const responseData = data.reduce((prev, elem) => {
          elem.simpleDate = simpleDate(elem.date);
          elem.formatted_date = dateLanguage(elem.date);
          elem.formatted_start_hour = hour(elem.start_hour);
          elem.formatted_end_hour = hour(elem.end_hour);

          if (elem?.[configuration?.theme_data?.program_list]) {
            rooms[elem?.[configuration?.theme_data?.program_list]] = "";
          }

          prev.push(elem);

          return prev;
        }, []);

        const itens: Array<IOption> = [];
        Object.keys(rooms).map(key => {
          itens.push({ label: key, value: key });
        });


        setRoomList(itens);

        if (responseData) {



          responseData.map(rep => {


            program_ref = configuration?.theme_data?.program_list && groupList[configuration?.theme_data?.program_list] ? groupList[configuration?.theme_data?.program_list](rep, program_ref) : groupList.default(rep, program_ref);





          });
        }

        program_ref.map((item, index) => {
          program_ref[index].program.sort((a, b) => {
            return a.date > b.date ? 1 : a.date < b.date ? -1 : 0;
          });
        });

        program_ref.sort((a, b) => {
          return a.date > b.date ? 1 : a.date < b.date ? -1 : 0;

        });

        setPrograms(program_ref);
        setReferenceProgram(program_ref);
      }
    }
    catch (err) {

    }
  };

  useEffect(() => {


    load();
  }, []);

  const line = program => {
    return (
      <tr style={{ cursor: 'pointer' }} onClick={() => setCurrentProgram(state => state === program.id ? '' : program.id)}>
        <td className="hourDiv" >  {`
              ${program.formatted_start_hour}
              ${program.formatted_end_hour && `às ${program.formatted_end_hour}`}`}</td>  <td style={{ width: '100%' }}>

          <span>
            {program.category ? <><strong style={{ fontSize: '14px', fontWeight: 'bold', background: '#1d436f', padding: '2px 5px', display: 'inline-block', margin: '3px 0px', marginBottom: '5px', color: '#fff' }}> {program.category} </strong><br /></> : <></>}
            <strong style={{ color: '#1d436f' }}>
              {' '}

              {program.title}
              {' '}


            </strong>

            {program.description && <div className={"hover"}>
              <div style={{ width: '100%' }}>


                <div className="miniPrograma" dangerouslySetInnerHTML={{ __html: program.description }} />

              </div>


            </div>}
          </span>
        </td>
        {user && <td><div>
          <span >
            {program.room_reference_id_response &&
              program.room_reference_id_response.title}

            {program.link_url && (
              <a href={program.link_url} target="_BLANK">
                <button className="defaultButtonMini buttonBlue" type="button">
                  {' '}
                  <FiChevronRight />
                </button>
              </a>
            )}

            {program.activity_id > 0 && (

              <button onClick={() => checkUser(program.activity_id)} className="defaultButtonMini buttonBlue" type="button">
                {' '}
                <FiChevronRight />
              </button>

            )}

            {program.video_id && (
              <div className="optionDiv">
                <button className="defaultButtonMini" type="button"><Favorite type="lesson" subtype="star" id={program.video_id} /></button>
                <Link to={`/dashboard/${program.video_id}`} style={{ margin: '5px' }}>
                  <button className="defaultButtonMini" type="button">
                    {' '}
                    <FiChevronRight style={{ margin: '0px 2px' }} size={24} />
                  </button>
                </Link></div>
            )}
          </span>
        </div>
        </td>}
      </tr>
    );
  };
  const { length } = programs;

  const lineTables = (lines) => {

    /* the array have 5 slots
    [0] = hour
    [1] = else
    [2] = Seminário de internacionalização
    [3] = Simpósio de pós-graduação
    [4] = conect ou Encontro de Jovens Cientistas
    */

    const linesGroup: Array<Array<JSX.Element>> = [];

    let arrayLine: Array<JSX.Element> = [];
    let current_date = '';

    lines?.map((program, index) => {

      if (index === 0) {
        current_date = program.formatted_start_hour;
        arrayLine[0] = <td>{`${program.formatted_start_hour}
      ${program.formatted_end_hour && `às ${program.formatted_end_hour}`}`}</td>;
      }
      else {

        if (current_date === program.formatted_start_hour) {

        }
        else {
          linesGroup.push([arrayLine?.[0], <td>{arrayLine?.[1]}</td>, <td>{arrayLine?.[2]}</td>, <td>{arrayLine?.[3]}</td>, <td>{arrayLine?.[4]}</td>]);
          arrayLine = [];
          arrayLine[0] = <td>{`${program.formatted_start_hour}
        ${program.formatted_end_hour && `às ${program.formatted_end_hour}`}`}</td>;
          current_date = program.formatted_start_hour
        }


      }


      if (program?.category === 'VI Seminário de Internacionalização da UFCSPA' || program?.title === 'Seminário de internacionalização - Palestra Tina Purnat') {
        arrayLine[2] = <>{arrayLine?.[2] ? <>{arrayLine?.[2]}<br /></> : <></>} <span>
          {program.category ? <><strong style={{ fontSize: '14px', fontWeight: 'bold', background: '#1d436f', padding: '2px 5px', display: 'inline-block', margin: '3px 0px', marginBottom: '5px', color: '#fff' }}> {program.category} </strong><br /></> : <></>}
          <strong style={{ color: '#1d436f' }}>
            {' '}

            {program.title}
            {' '}


          </strong>

          {program.description && <div className={"hover"}>
            <div style={{ width: '100%' }}>


              <div className="miniPrograma" dangerouslySetInnerHTML={{ __html: program.description }} />

            </div>


          </div>}
        </span> </>
      }
      else if (program?.category === 'I Seminário da Pós-Graduação UFCSPA') {

        arrayLine[3] = <>{arrayLine?.[3] ? <>{arrayLine?.[3]}<br /></> : <></>}<span>
          {program.category ? <><strong style={{ fontSize: '14px', fontWeight: 'bold', background: '#1d436f', padding: '2px 5px', display: 'inline-block', margin: '3px 0px', marginBottom: '5px', color: '#fff' }}> {program.category} </strong><br /></> : <></>}
          <strong style={{ color: '#1d436f' }}>
            {' '}

            {program.title}
            {' '}


          </strong>

          {program.description && <div className={"hover"}>
            <div style={{ width: '100%' }}>


              <div className="miniPrograma" dangerouslySetInnerHTML={{ __html: program.description }} />

            </div>


          </div>}
        </span> </>
      }
      else if (program?.title === 'Simpósio Satélite Irmandade Santa Casa de Misericórdia de Porto Alegre - A Cirurgia do Futuro' || program?.title === 'Encontro de Jovens Cientistas' || program?.category === '2º CONECt UFCSPA' || program?.category === '2º CONECt') {

        arrayLine[4] = <>{arrayLine?.[4] ? <>{arrayLine?.[4]}<br /></> : <></>} <span>
          {program.category ? <><strong style={{ fontSize: '14px', fontWeight: 'bold', background: '#1d436f', padding: '2px 5px', display: 'inline-block', margin: '3px 0px', marginBottom: '5px', color: '#fff' }}> {program.category} </strong><br /></> : <></>}
          <strong style={{ color: '#1d436f' }}>
            {' '}

            {program.title}
            {' '}


          </strong>

          {program.description && <div className={"hover"}>
            <div style={{ width: '100%' }}>


              <div className="miniPrograma" dangerouslySetInnerHTML={{ __html: program.description }} />

            </div>


          </div>}
        </span> </>
      }

      else {

        arrayLine[1] = <>{arrayLine?.[1] ? <>{arrayLine?.[1]}<br /></> : <></>} <span>
          {program.category ? <><strong style={{ fontSize: '14px', fontWeight: 'bold', background: '#1d436f', padding: '2px 5px', display: 'inline-block', margin: '3px 0px', marginBottom: '5px', color: '#fff' }}> {program.category} </strong><br /></> : <></>}
          <strong style={{ color: '#1d436f' }}>
            {' '}

            {program.title}
            {' '}


          </strong>

          {program.description && <div className={"hover"}>
            <div style={{ width: '100%' }}>


              <div className="miniPrograma" dangerouslySetInnerHTML={{ __html: program.description }} />

            </div>


          </div>}
        </span> </>
      }
      if (index === (lines?.length - 1)) {
        linesGroup.push([arrayLine?.[0], <td>{arrayLine?.[1]}</td>, <td>{arrayLine?.[2]}</td>, <td>{arrayLine?.[3]}</td>, <td>{arrayLine?.[4]}</td>]);

      }
    }

    )

    return linesGroup?.map(group => {
      return <tr>{group}</tr>;
    })


  }


  return (
    <Container id="programacao" style={{ background: '#fff' }}>
      <h1 style={{ color: '#1d436f' }}>{translate('Programação')} </h1>

      <p style={{ textAlign: 'center', width: '100%', marginBottom: '20px', color: '#1d436f' }}>Horário de Brasilia</p>





      {referenceProgram.map(program => {
        return (program.program.length > 0 ? <div>
          <div className="programDayHeader" >


            {program.formatted_date && <h2 style={{ background: '#1d436f', color: '#fff', marginTop: '30px', padding: '10px', marginLeft: '7px' }}>{translate(program.formatted_date)}</h2>}

          </div>
          <table>

            <tbody>{lineTables(program.program)}</tbody>
          </table>

        </div> : <></>)
      })}

    </Container>
  );
};
export default ProgramTable;
