import React from 'react';

import {
  SearchComponent,
  ISearchComponent,
} from '../../core/components/SearchComponent';

import lineModules from '../../core/components/lineModules'
import { Container } from './styles';
import { useParams } from 'react-router-dom';
import { urlWebsite } from '../../../../config';
import CopyToClipboard from '../../core/components/CopyToClipboard';
import exportListSubscriptions from './modules/ExportListSubscriptions';
interface ValidationRules {
  type: 'email' | 'required' | 'min' | 'max' | 'length';
  message?: string;
  quantity?: number;
}

interface ValidationSchema {
  name: string;
  type: 'string' | 'number' | 'boolean';
  label: string;
  rules: Array<ValidationRules>;
}

interface ILine {
  [key: string]: string;
}
interface IParams {
  projectHash: string;
  eventHash: string;
}

interface IParams {
  projectHash: string;
  eventHash: string;
}

const VisitationCalendarSubscription: React.FC = () => {
  const endpoint = '/visitation-calendar-subscriptions';
  const title = 'Visitas agendadas';
  const { projectHash, eventHash } = useParams<IParams>();

  const schema: Record<string, any> = {
    _id: { ref: '_id', column: '_id', label: 'ID' },
    project_id: { ref: 'project_id', column: 'project_id', label: 'Projeto' },
    client_id: {
      ref: 'client_id',
      column: 'client_id_response',
      includeColumn: 'name',
      label: 'Cliente',
    },
    date: {
      ref: 'date',
      column: 'date',

      label: 'Data',
    },
    quantity: {
      ref: 'quantity',
      column: 'quantity',

      label: 'Alunos',
    },
    name: {
      ref: 'name',
      column: 'name',

      label: 'Nome',
    },
    email: {
      ref: 'email',
      column: 'email',

      label: 'E-mail',
    },
    document_number: {
      ref: 'document_number',
      column: 'document_number',

      label: 'CPF',
    },
    company: {
      ref: 'company',
      column: 'company',

      label: 'Instituição',
    },
    position: {
      ref: 'position',
      column: 'position',

      label: 'Cargo',
    },
    visitation_id: {
      ref: 'visitation_id',
      column: 'visitation_id_response',
      includeColumn: 'title',
      label: 'Atividade',
    },
    confirmed: { ref: 'confirmed', column: 'confirmed', label: 'Credenciado' },

    createdAt: {
      ref: 'created_at',
      column: 'createdAt',
      label: 'Data de Criação',
      mask: 'date',
    },

  };
  const formSchema = {
    client_id: {
      alias: 'client_id',
      model: 'selectApi',
      name: 'client_id',
      label: 'Cliente',
      endpoint,
    },
    course_product_id: {
      alias: 'course_product_id',
      model: 'selectApi',
      name: 'course_product_id',
      label: 'Atividade',
      endpoint,
    },
    confirmed: {
      model: 'selectSimple',
      name: 'confirmed',
      label: 'Credenciado',
      defaultValue: 'yes',
      options: [
        { label: 'Sim', value: 'yes' },
        { label: 'Não', value: 'no' },
      ]
    },
  };

  const formSchemaUpdate = {

    ...formSchema
  };



  const formValidation: Record<string, ValidationSchema> = {

  };



  const config: ISearchComponent = {
    idKey: `${endpoint}-${projectHash}-${eventHash}`,
    title,
    endpoint,
    schema,
    autoStart: true,
    headModules: [
      () =>
        lineModules.create({
          title,
          formSchema,
          validationSchema: formValidation,
          endpoint,
        }),
      () => exportListSubscriptions()
    ],
    bodyModules: [

      (lineData: Record<string, any>) =>
        lineModules.updateUnique({
          lineData,
          title,
          formSchema: formSchemaUpdate,
          validationSchema: {},
          endpoint,
        }),
      (lineData: Record<string, any>) =>
        lineModules.delete({
          lineData,
          title,
          formSchema,
          validationSchema: formValidation,
          endpoint,
        }),
    ],
  };

  return (
    <Container>
      <SearchComponent {...config} />
    </Container>
  );
};

export default VisitationCalendarSubscription;
