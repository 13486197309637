import React, { useState } from "react"
import { Container } from "./styles";


interface IProps {
    image: string;
    setCallBack: Function;
}

const VisualizePDF: React.FC<IProps> = ({ image, setCallBack }) => {

    return <Container>

        <div>

            <img src={image} />
            <button className="buttonBigGreen" onClick={() => setCallBack()}>Fechar</button>
        </div>

    </Container>


}

export default VisualizePDF;