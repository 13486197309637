import React, { useEffect, useState } from 'react';

import {
  SearchComponent,
  ISearchComponent,
} from '../../../../core/components/SearchComponent';

import lineModules from '../../../../core/components/lineModules'
import { Container } from './styles';
import { useSocket } from '../../../../../../hooks/Socket';
import { Button } from '../../../../core/components/Forms/Button/styles';

import review from './modules/Review';

import { areas } from '../../../../../Cientific/Pages/AvaliatorSubscription/areas';
import api from '../../../../../../services/api';
import { apiUrl, background } from '../../../../../../config';
import { downloaditPost } from '../../../../../../utils/download';
import { useToast } from '../../../../../../hooks/Toast';


interface ICreateModule {
  lineData: Record<string, any>;



}

interface ValidationRules {
  type: 'email' | 'required' | 'min' | 'max' | 'length';
  message?: string;
  quantity?: number;
}

interface ValidationSchema {
  name: string;
  type: 'string' | 'number' | 'boolean';
  label: string;
  rules: Array<ValidationRules>;
}

interface ILine {
  [key: string]: string;
}

interface IContent {
  content: Record<string, any>;
  id: string;
  padding: number;
}

const CientificGridLine: React.FC<IContent> = ({ content, id, padding }) => {

  const [active, setActive] = useState(false)

  return <> <tr style={{ cursor: 'pointer', background: content.avaliators.length < 2 ? 'orange' : '#fff' }} onClick={() => setActive(!active)} key={id}><td style={{ paddingLeft: `${padding}px` }}>{content?.title}</td><td>{content?.avaliators?.length > 0 ? content.avaliators.length : '0'}</td><td>{content?.resumes?.length > 0 ? content.resumes.length : '0'}</td></tr>

    {active && content?.avaliators?.length > 0 && <tr><td colSpan={2}><table>{content?.avaliators.map(av => <tr key={`${id}-${av?.email}`}><td colSpan={2}>{av?.name} ({av?.email})</td><td>{av?.category}</td></tr>)}</table></td></tr>}





  </>

}

function compare(a, b) {
  if (a.name.toLowerCase().trim() < b.name.toLowerCase().trim()) {
    return -1;
  }
  if (a.name.toLowerCase().trim() > b.name.toLowerCase().trim()) {
    return 1;
  }
  return 0;
}



const CientificGrid: React.FC = () => {


  const [areaList, setAreaList] = useState<Array<Record<string, any>>>([]);


  const addLine = (simulation, avaliators, resumes) => {




    let x = 0;
    const avLength = avaliators.length - 1;
    resumes.map(resume => {

      if (avaliators[x].document_number === resume.orientator_document_number) {
        x = (x + 1) > avLength ? 0 : x + 1;
      }

      simulation.push({
        project_id: 'neonatologia-2022',
        event_id: 'neonatologia-2022',
        key: `${resume._id}-${avaliators[x]._id}`,
        reference: 'Avaliação de Trabalho',
        category: resume.category,
        area: resume.area,
        subarea: resume.subarea,
        title: resume.title,
        orientator: resume.orientator,
        author_institution: resume.author_institution,
        author: resume.author,
        avaliator_id: avaliators[x]._id,
        avaliator_id_response: { _id: avaliators[x]._id, name: avaliators[x].name },
        avaliator_email: avaliators[x].email,
        paper_id: resume._id,
        paper_id_response: { _id: resume._id, title: resume.title }
      })

      x = (x + 1) > avLength ? 0 : x + 1;

      if (avaliators[x].document_number === resume.orientator_document_number) {
        x = (x + 1) > avLength ? 0 : x + 1;
      }

      simulation.push({
        project_id: 'neonatologia-2022',
        event_id: 'neonatologia-2022',
        key: `${resume._id}-${avaliators[x]._id}`,
        reference: 'Avaliação de Trabalho',
        category: resume.category,
        area: resume.area,
        subarea: resume.subarea,
        title: resume.title,
        orientator: resume.orientator,
        author_institution: resume.author_institution,
        author: resume.author,
        avaliator_id: avaliators[x]._id,
        avaliator_id_response: { _id: avaliators[x]._id, name: avaliators[x].name },
        avaliator_email: avaliators[x].email,
        paper_id: resume._id,
        paper_id_response: { _id: resume._id, title: resume.title }
      })

    })

    return simulation;
  }

  const [simulationList, setSimulationList] = useState<Array<Record<string, any>>>([]);

  const simulate = (list) => {


    let simulation: Array<Record<string, any>> = [];

    list.map(category => {
      const { avaliators, resumes } = category;

      if (category.lists.length > 0) {

        category?.lists?.map(area => {


          if (area.lists.length > 0) {

            area?.lists?.map(subarea => {

              simulation = addLine(simulation, subarea.avaliators, subarea.resumes);

            })


          }
          else {
            simulation = addLine(simulation, area.avaliators, area.resumes);
          }


        });



      }
      else {
        simulation = addLine(simulation, avaliators, resumes);
      }


    })


    setSimulationList(simulation);


  }

  const load = async () => {
    const response = await api.get('/cientific-avaliators?limitItems=1000000');

    const responseSubmissions = await api.get('/cientific-submissions?limitItems=1000000');

    const resumes = responseSubmissions.data.rows;

    const getAreas = [...areas];
    const errorList: Array<string> = [];

    getAreas.map(category => {

      const catResumes = resumes.filter(c => c.category === category.title);


      category.avaliators = [];
      category.resumes = catResumes.length > 0 ? [...catResumes] : [];

      category?.lists && category.lists.map(area => {

        const catAreas = resumes.filter(c => c.category === category.title && c.area === area.title);

        area.avaliators = [];
        area.resumes = catAreas.length > 0 ? [...catAreas] : [];

        area?.lists && area.lists.map(subarea => {

          const catSubareas = resumes.filter(c => c.category === category.title && c.area === area.title && c.subarea === subarea.title);

          subarea.avaliators = [];
          subarea.resumes = catSubareas.length > 0 ? [...catSubareas] : [];

        })

      })

    })







    if (response) {
      /* items são os avaliadores */
      const avaliadores = response.data.rows;


      avaliadores.map(i => {

        i.document_number = i.document_number.toString().replace(/[^0-9]/g, '');

        /* Área de atuação dos avaliadores */
        const themes = i.themes;



        themes.map(area => {



          const indexCategory = getAreas.findIndex(cat => cat.title === area.category);

          const indexMeCategory = getAreas[indexCategory].avaliators.findIndex(a => a.email === i.email);

          if (indexMeCategory < 0) {
            getAreas[indexCategory].avaliators.push(i);
          }

          const indexArea = getAreas[indexCategory].lists.findIndex(areaList => areaList.title === area.area);
          if (indexArea >= 0) {
            const indexMeArea = getAreas[indexCategory].lists[indexArea].avaliators.findIndex(a => a.email === i.email);
            if (indexMeArea < 0) {
              getAreas[indexCategory].lists[indexArea].avaliators.push(i);
            }


            const indexSubarea = getAreas[indexCategory].lists[indexArea].lists.findIndex(subareaList => subareaList.title === area.subarea);
            if (indexSubarea >= 0) {
              const indexMeSubarea = getAreas[indexCategory].lists[indexArea].lists[indexSubarea].avaliators.findIndex(a => a.email === i.email);

              if (indexMeSubarea < 0) {
                getAreas[indexCategory].lists[indexArea].lists[indexSubarea].avaliators.push(i);
              }
            }

          }

        })

      })


      getAreas.map(category => {


        category.avaliators.sort((a, b) => a.name > b.name ? 1 : a.name < b.name ? -1 : 0)
        category.resumes.sort((a, b) => a.name > b.name ? 1 : a.name < b.name ? -1 : 0)

        category?.lists && category.lists.map(area => {

          area.avaliators.sort((a, b) => a.name > b.name ? 1 : a.name < b.name ? -1 : 0)
          area.resumes.sort((a, b) => a.name > b.name ? 1 : a.name < b.name ? -1 : 0)

          area?.lists && area.lists.map(subarea => {

            subarea.avaliators.sort((a, b) => a.name > b.name ? 1 : a.name < b.name ? -1 : 0)
            subarea.resumes.sort((a, b) => a.name > b.name ? 1 : a.name < b.name ? -1 : 0)

          })

        })

      })




    }




    simulate(getAreas);

    setAreaList([...getAreas])
  }

  useEffect(() => {
    load();
  }, [])

  const renderList = () => {

    return <table className='table' style={{ width: '100%', margin: '0px auto' }}>
      <tbody>
        {areaList && areaList.length > 0 && areaList.map((category, indexCategory) => {


          return <> <CientificGridLine padding={10} content={category} id={`category${category.title}-${indexCategory}`} />

            {category?.lists?.length > 0 && category?.lists.map((area, indexArea) => {

              return <>
                <CientificGridLine padding={25} content={area} id={`area${category.title}-${area.title}-${indexArea}`} />

                {area?.lists?.length > 0 && area?.lists.map((subarea, indexSubarea) => {

                  return <CientificGridLine padding={40} content={subarea} id={`subarea${category.title}-${area.title}-${subarea.title}-${indexSubarea}`} />
                })}
              </>



            })}



          </>
        })}
      </tbody>
    </table>

  }

  const downloadFile = () => {

    const newFile: Array<Record<string, any>> = [];
    newFile.push({
      category: 'Categoria',
      area: 'Área',
      subarea: 'Subárea',
      title: 'Título',
      author: 'Autor',
      orientator: 'Orientador',
      avaliator: 'Avaliador',

    })

    simulationList.map(item => {

      newFile.push({
        reference: 'Avaliação de Trabalho',
        category: item.category,
        area: item.area,
        subarea: item.subarea,
        title: item.title.toUpperCase(),
        author: `${item.author} (${item.author_institution})`,
        orientator: item.orientator,
        avaliator: item.avaliator_id_response.name,


      })

    })

    downloaditPost({ url: `${apiUrl}/converter/csv`, data: newFile, type: 'csv' })



  }

  const { addToast } = useToast();

  const liberarAvaliacoes = async () => {
    try {
      const response = await api.post('/upload-avaliations', { avaliations: simulationList })
      addToast({ type: 'success', title: 'Adicionados com sucesso' })
    }
    catch (err) {
      addToast({ type: 'error', title: 'Erro ao adicionar avaliações' })
    }
  }

  return (
    <Container>

      <h2>Lista de Avaliadores / Áreas</h2>
      <p>Clique para visualizar os Avaliadores</p>




      {renderList()}

      <h2 style={{ margin: '30px auto' }}>Simulação de distribuiçao</h2>
      <button onClick={() => downloadFile()}>Download CSV</button>

      <table className='table' style={{ width: '100%', margin: '0px auto' }}>
        <thead>
          <td style={{ background: '#333', color: '#fff' }}>Categoria</td>
          <td style={{ background: '#333', color: '#fff' }}>Área</td>
          <td style={{ background: '#333', color: '#fff' }}>Subárea</td>
          <td style={{ background: '#333', color: '#fff' }}>Resumo</td>
          <td style={{ background: '#333', color: '#fff' }}>Autor</td>
          <td style={{ background: '#333', color: '#fff' }}>Orientador</td>
          <td style={{ background: '#333', color: '#fff' }}>Avaliador</td>

        </thead>
        <tbody>

          {simulationList.map((item, index) => {

            return <tr key={item.key}>
              <td>{item.category}</td>
              <td>{item.area}</td>
              <td>{item.subarea}</td>
              <td>{item.title.toUpperCase()}</td>
              <td>{`${item.author} (${item.author_institution})`}</td>
              <td>{item.orientator}</td>
              <td>{item.avaliator_id_response.name}</td>

            </tr>

          })}


        </tbody>
      </table>

      <Button onClick={() => liberarAvaliacoes()}>Liberar Avaliações</Button>
    </Container>
  );
};

export default CientificGrid;
