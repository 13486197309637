import React, { useEffect, useRef, useState } from 'react';
import { Form } from '@unform/web';
import { FiMail, FiUser, FiLock, FiMap, FiArrowRight } from 'react-icons/fi';
import { Link, useParams } from 'react-router-dom';
import {
  FaWhatsapp,
  FaFileMedical,
  FaNotesMedical,
  FaHospital,
  FaSimplybuilt,
  FaBuilding,
  FaIdBadge,
  FaClinicMedical,
} from 'react-icons/fa';
import { ISigla, readSigla, siglas as siglasRef } from './siglas';
import { MdError, MdDateRange, MdChat } from 'react-icons/md';

import * as Yup from 'yup';
import ReCAPTCHA from 'react-google-recaptcha';
import { FormHandles } from '@unform/core';
import Input from '../../../components/Forms/Input';
import Button from '../../../components/Forms/Button';
import Loader from '../../../components/Loader';
import MaskedInput from '../../../components/Forms/MaskedInput';
import { ResponseMessage } from '../../../components/ResponseMessage';
import getValidationErrors from '../../../utils/getValidationErrors';
import { Error } from './styles';
import SelectSimple from '../../../components/Forms/SelectSimple';
import Whatsapp from '../../../components/WhatsAppCompartilhar';

import api from '../../../services/api';
import { authTitle, hash, recaptchaKey, urlWebsite } from '../../../config';
import { countries } from './countries';
import TextareaInput from '../../../components/Forms/TextareaInput';
import { useToast } from '../../../hooks/Toast';
import CheckboxInput from '../../../components/Forms/CheckboxInput';
import getCep from '../../../services/cep';
import Axios from 'axios';
import states from './states';
import { useLanguage } from '../../../hooks/Language';
import CompleteSaleFields from './CompleteSaleFields';
import testCPF from '../../../utils/testCPF';
import { addYears, isValid } from 'date-fns';
import { useConfiguration } from '../../../hooks/Configuration';
import { loadFromJson } from '../../../utils/loadFromJson';
import CourseProductsSubscriptionModule from '../../CourseProductSubscription';
import { useAuth } from '../../../hooks/Auth';

import { useLoading } from '../../../hooks/LoadingHook';
import { useModal } from '../../../hooks/Modal';

interface IRecoverFormData {
  [key: string]: any;
}


interface IRead {
  [key: string]: any;
}

interface IOption {
  value: string;
  label: string;
}
interface IParams {
  projectHash: string;
  eventHash: string;
  productId: string;
  hashcode: string;
}

interface ICaptcha {
  getValue(): string;
}

declare var window: any;

const FormContainer = ({ context = {} }) => {

  const [checked, setChecked] = useState(false);
  const { user } = useAuth();

  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [otherCountry, setOtherCountry] = useState(false);
  const [errorMessage, setErrorMessage] = useState<Array<string>>([]);
  const [withPassword, setWithPassword] = useState(true);
  const formRefConfirmationOK = useRef<FormHandles>(null);
  const formRefConfirmation = useRef<FormHandles>(null);
  const formRef = useRef<FormHandles>(null);
  const recaptchaRef = useRef(null);
  const { projectHash, eventHash, productId, hashcode } = useParams<IParams>();
  const { configuration } = useConfiguration();
  const [emailRecord, setEmailRecord] = useState('');
  const { signIn } = useAuth();
  const getCaptchaValue = (elem: ICaptcha): string => {
    return elem.getValue();
  };
  const siglas = readSigla();
  const { addToast } = useToast();
  const { translate } = useLanguage();
  const [global, setGlobal] = useState<Record<string, any>>({});
  const [countryISO, setCountryISO] = useState('BR');
  const [position, setPosition] = useState('');
  const [otherCompany, setOtherCompany] = useState('');
  const [product, setProduct] = useState<Record<string, any>>({});
  const [cities, setCities] = useState<Array<IOption>>([]);
  const [stateValue, setStateValue] = useState('');

  const [category, setCategory] = useState('');
  const [currentEmail, setCurrentEmail] = useState('');
  const [ready, setReady] = useState(false);
  const [statusConfirmation, setStatusConfirmation] = useState('');
  const { addModal } = useModal();
  const { addLoading, removeLoading } = useLoading()


  const [info, setInfo] = useState({
    nome: '',
    fantasia: '',
    uf_empresa: '',
    municipio_empresa: ''
  })

  const [errorRequestMessage, setErrorRequestMessage] = useState('');

  useEffect(() => {
    if (configuration?.have_password === 'no') {
      setWithPassword(false);
    }

  }, [configuration])

  const loadConfig = async () => {
    let data: Record<string, any> = {};

    const item = await loadFromJson({ hash: hash, project_id: 'global', table: 'Global' })


    if (!item) {
      const config = await api.get('/get_platform');

      data = config.data;
    }
    else {
      data = item?.[0] || {}
    }

    setGlobal(data);
  }

  useEffect(() => {
    loadConfig()
  }, [])

  const callCityApi = async (value) => {

    const response = await Axios.get(`https://servicodados.ibge.gov.br/api/v1/localidades/estados/${value}/municipios`);

    if (response) {

      const values: Array<IOption> = [];

      response.data.map(city => {
        values.push({ value: city.nome, label: city.nome });
      })

      setCities(values);
    }


  }

  useEffect(() => {
    callCityApi(stateValue);
  }, [stateValue])



  const getCepFromApi = async (value: string): Promise<void> => {
    const cepResponse = await getCep(value);

    if (!cepResponse.error) {
      formRef?.current?.setFieldValue('address', cepResponse.logradouro);
      formRef?.current?.setFieldValue('neighborhood', cepResponse.bairro);
      formRef?.current?.setFieldValue('city', cepResponse.localidade);
      formRef?.current?.setFieldValue('state', cepResponse.uf);
    }
  };


  const verifyCNPJ = async (cnpj) => {

    const check = cnpj.toString().replace(/[^0-9]/g, '');

    if (check?.length < 14) {
      return;
    }

    try {
      const checkCNPJ = await api.post('/check-cnpj', { cnpj: cnpj });

      setInfo({
        nome: checkCNPJ?.data?.nome,
        fantasia: checkCNPJ?.data?.fantasia,
        uf_empresa: checkCNPJ?.data?.uf_empresa,
        municipio_empresa: checkCNPJ?.data?.municipio_empresa
      })
    }
    catch (err) {

    }


  }


  const handleNewLogin = async (data: IRecoverFormData) => {
    addLoading({ title: 'Preparando seu cadastro', key: 'subscription' })


    data.document_number = emailRecord;


    const fieldsValues: Record<string, any> = {};
    let fieldsText = '';

    if (countryISO === 'BR') {
      data.document_type = 'cpf';
    }


    if (data.company_document_number) {
      // data.company_document_number = data?.company_document_number?.toString()?.replace(/[^0-9]/g, '');
    }

    let razao_social = '';
    let fantasia = '';
    let uf_empresa = '';
    let municipio_empresa = '';

    try {
      const checkCNPJ = await api.post('/check-cnpj', { cnpj: data?.company_document_number });
      razao_social = checkCNPJ?.data?.nome;
      fantasia = checkCNPJ?.data?.fantasia;
      uf_empresa = checkCNPJ?.data?.uf_empresa;
      municipio_empresa = checkCNPJ?.data?.municipio_empresa;
    }
    catch (err) {

      addModal({
        title: '', content: <><h2>CNPJ não identificado</h2>
        </>, theme: 'whiteModalMini'
      })
      removeLoading('subscription')
      return;
    }

    let eventFields = configuration?.current_event_id_response?.sign_up_fields || [];

    if (eventFields?.length < 1) {


      if (global?.sign_up_fields?.length > 0) {
        eventFields = [...global?.sign_up_fields];
      }
    }

    try {
      formRef.current?.setErrors({});


      const shape = Yup.object().shape({
        name: Yup.string().required('Nome obrigatório'),
        email: Yup.string()
          .required('Email obrigatório')
          .email('Preencha um email válido'),

        phone: Yup.string()
          .required('Telefone com DDD é obrigatório'),
        category_id: Yup.string().required('A área é obrigatório'),
        position: Yup.string().required('O Profissão é obrigatório'),
        company: Yup.string().required('Campo Empresa é obrigatório'),
        document_number: Yup.string().required('Campo CPF é obrigatório'),
        company_document_number: Yup.string().required('Campo CNPJ é obrigatório'),

      });
      data.phone = data.phone.toString().replace(/[^0-9]/g, '');
      data.document_number.toString().replace(/[^0-9]/g, '');






      eventFields?.map(field => {

        if (field?.mandatory === 'yes') {
          shape[field?.fields] = Yup.string().required(field?.title);
        }

      })


      if (product?.fields && product?.fields.length > 0) {


        const fieldsKeys: Array<string> = [];


        let x = 1;
        product?.fields.map((f: Record<string, any>) => {
          if (f.mandatory === 'yes') {
            shape[f.field] = Yup.string().required(`Campo ${translate(f.label)} é obrigatório`);
          }
          fieldsKeys.push(f.field);
          fieldsValues[f.field] = data?.[f.field];

          if (x > 1) {
            fieldsText = `${fieldsText} | `;
          }

          fieldsText = `${fieldsText}${data?.[f.field]}`;

          x++;

        })


      }


      const schema = shape;
      if (data.phone) {
        data.phone = data?.phone?.toString()?.replace(/[^0-9]/g, '');
      }

      if (data.company_document_number) {
        data.company_document_number = data?.company_document_number?.toString()?.replace(/[^0-9]/g, '');
      }

      /*    if (countryISO === 'BR') {
           const validCPF = testCPF(data?.document_number);
   
           if (!validCPF) {
             addToast({ type: 'error', title: 'Preencha um CPF válido' })
             setLoading(false);
             return;
           }
         }
   
         */









      data.email = data?.email?.trim()?.toLowerCase() || '';

      await schema.validate(data, { abortEarly: false });




      const captchaValue =
        getCaptchaValue(recaptchaRef?.current || { getValue: () => '' }) ||
        false;

      const content: Record<string, any> = {
        ...data,
        project_id: projectHash,
        event_id: eventHash ? eventHash : '',
        captcha: captchaValue,
        term: ['Aceito'],
        fields: { ...fieldsValues },
        fields_text: fieldsText,
        extra_1: razao_social,
        extra_2: fantasia,
        extra_4: uf_empresa,
        extra_5: municipio_empresa,
      };

      if (configuration?.current_event_id_response?.product_id) {
        // content.product = configuration?.current_event_id_response?.product_id;
      }


      const newUser = await api
        .post('/signup', content)
        .catch(error => {
          if (error instanceof Yup.ValidationError) {
            const errors = getValidationErrors(error);
            formRef.current?.setErrors(errors);

            removeLoading('subscription');
          }

          addToast({
            type: 'error',
            title: error?.response?.data?.message || error?.response?.data?.error || 'Erro ao cadastrar',
          });
          removeLoading('subscription');
          return false;
        });

      if (!newUser) {
        removeLoading('subscription');
        return false;
      }

      if (window?.fbq) {
        window.fbq('track', 'CompleteRegistration');
      }
      const session = await signIn({ email: configuration?.sign_in_type === 'cpf' ? data?.document_number : data?.email, password: data?.password, project_id: projectHash })
      removeLoading('subscription');
      await applyCourtesy(configuration?.sign_in_type === 'cpf' ? data.document_number : data.email, hashcode);



      return false;
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const errors = getValidationErrors(err);

        formRef.current?.setErrors(errors);

        const errorInfo = err.errors.reduce((error, prev) => {
          prev = `${prev}, ${error}`;
          return prev;
        });
        removeLoading('subscription');
        addToast({
          type: 'error',
          title: 'Erro de validação',
          description: errorInfo,
        });
      }
      removeLoading('subscription');
    }
  }


  const checkCourtesy = async (cortesyHash) => {

    addLoading({ title: 'Validando cortesia', key: 'cortesia' })

    try {
      const confirm = await api.get(`/v3/validate-cortesy-hash/${cortesyHash}/${projectHash}`);

      setChecked(true)


      removeLoading('cortesia')
    }
    catch (err) {
      setErrorRequestMessage(err?.response?.data?.message || err?.response?.data?.error || 'Erro ao validar cortesia');
      removeLoading('cortesia')
    }


  }

  useEffect(() => {

    if (hashcode) {
      checkCourtesy(hashcode)
    }

  }, [hashcode])


  const applyCourtesy = async (email, hashValue) => {
    addLoading({ title: 'Aplicando Cortesia', key: 'aplicar-cortesia' })

    try {
      const checkCourtesy = await api.post(configuration?.sign_in_type === 'cpf' ? `/v3/confirm-courtesy-by-cpf/${hashValue}` : `/v3/confirm-courtesy/${hashValue}`, { email: email });

      if (checkCourtesy?.data?.confirmed === 'yes') {
        setSuccess(true);
      }
      else if (checkCourtesy?.data?.used === 'yes') {
        setErrorRequestMessage('Esta cortesia já foi utilizada');
      }
      else {
        setErrorRequestMessage('Erro ao aplicar cortesia');
      }
      removeLoading('aplicar-cortesia')

    }
    catch (err) {

      setErrorRequestMessage('Erro ao aplicar cortesia');
      removeLoading('aplicar-cortesia')
    }


  }

  const handleConfirmation = async (data) => {
    addLoading({ title: 'Verificando...', key: 'check' })
    data.email = data?.email?.trim()?.toLowerCase() || '';

    if (configuration?.sign_in_type === 'cpf') {
      const test = testCPF(data?.email);
      if (!test) {
        addModal({
          title: '', content: <><h2>Informe um CPF válido</h2>
          </>, theme: 'whiteModalMini'
        })
        removeLoading('check')
        return;
      }


    }

    try {
      const checkUser = await api.post(configuration?.sign_in_type === 'cpf' ? `/v3/check-user-by-cpf/${hashcode}` : `/v3/check-user/${hashcode}`, { email: data.email, hash: hashcode });

      if (checkUser.data.status === 'no') {


        setEmailRecord(data.email);
        setReady(true);
      }
      else if (checkUser.data.status === 'yes' && checkUser.data.used === 'yes') {
        setErrorRequestMessage('Você já utilizou esta cortesia para este cadastro.');
      }
      else if (checkUser.data.status === 'yes' && checkUser.data.used === 'no') {

        applyCourtesy(data.email, hashcode)

      }
      removeLoading('check')


    }
    catch (err) {
      addToast({ type: 'error', title: err?.response?.data?.message || 'Erro ao checar usuário, por favor, tente novamente' });
      removeLoading('check')
      return
    }


  }

  if (errorRequestMessage) {
    return <>
      <h1 style={{ color: 'rgb(0,15,64)', marginBottom: '20px', textAlign: 'center', fontSize: '18px', marginTop: '20px' }}>Verificamos um problema</h1>

      <p style={{ color: 'rgb(0,15,64)', marginBottom: '20px', textAlign: 'center' }}>{errorRequestMessage}</p>

      <Link to={`/`}><button className="defaultButton" type="button">
        Voltar
      </button></Link>
    </>
  }

  if (success) {
    return <ResponseMessage
      active
      type="clean"
      title=""
      description={
        <p style={{ color: 'rgb(0, 14, 64)', width: '100%' }}>
          <h2 className="titleH2" style={{ color: 'rgb(0, 14, 64)' }}> Participação confirmada com sucesso </h2>

          <br />
          <Link to="/" style={{ width: '100%', display: 'flex', justifyContent: 'center' }}><button className='greenButton2'>Voltar para o site</button></Link>
        </p>
      }
    />
  }


  if (!checked) {
    return <></>
  }


  if (!ready) {

    return (
      <Form style={{ width: '100%', maxWidth: '350px' }} ref={formRefConfirmation} onSubmit={handleConfirmation} initialData={configuration?.sign_in_type === 'cpf' ? { country: 'BR', document_number: currentEmail } : { country: 'BR', email: currentEmail }}>
        <h1 className='color2' style={{ fontSize: '18px', marginBottom: '10px' }}>Confirmar participação</h1>
        <p style={{ color: 'rgb(0,15,64)', fontSize: '14px', marginBottom: '20px' }}>Para iniciar, informe seu {configuration?.sign_in_type === 'cpf' ? 'CPF' : 'e-mail'}</p>
        {configuration?.sign_in_type === 'cpf' ? otherCountry ? <Input name="email" placeholder="Documento de identificação" /> : <MaskedInput mask={'999.999.999-99'} name="email" placeholder="" /> : <Input name="email" placeholder="" />}

        {configuration?.sign_in_type === 'cpf' ? !otherCountry ? <p onClick={() => setOtherCountry(true)} style={{ color: '#333', margin: '10px auto', cursor: 'pointer' }}>Sou de outra nacionalidade</p> : <p onClick={() => setOtherCountry(false)} style={{ color: '#333', margin: '10px auto', cursor: 'pointer' }}>Sou brasileiro</p> : <></>}
        <aside style={{ width: '100%', display: 'flex', justifyContent: 'center' }}> <button className="buttonBigGreen" type="submit">
          Continuar
        </button></aside>
      </Form>)
  }



  return (
    <>

      <aside>




        <Form className='column column-start' name={projectHash} ref={formRef} onSubmit={handleNewLogin} initialData={{ country: 'BR' }}>
          <h3 style={{ color: 'rgb(28, 146, 132)', textAlign: 'left', marginBottom: '20px' }}>Confirme seus dados</h3>
          <p style={{ textAlign: 'left', marginBottom: '20px', color: 'rgb(8, 40, 81)', fontSize: '16px' }}>
            Preencha os dados abaixo<br />

            para confirmar sua presença.
          </p>

          <Input name="name" placeholder="Nome completo" />
          <Input name="email" placeholder="E-mail" />
          {countryISO === 'BR' ? <MaskedInput
            mask="(99) 999999999"
            name="phone"
            placeholder="Telefone com DDD"
          /> : <Input name="phone" placeholder={'Telefone com DDD'} />}




          <Input
            name="company"
            placeholder="Empresa"
          />

          <SelectSimple
            name="category_id"
            label="Área"
            options={[


              { label: "Administrativo", value: "Administrativo", text: "Administrativo" },
              { label: "Comercial", value: "Comercial", text: "Comercial" },
              { label: "Departamento de pessoas", value: "Departamento de pessoas", text: "Departamento de pessoas" },
              { label: "Diretor(a)/Sócio(a)", value: "Diretor(a)/Sócio(a)", text: "Diretor(a)/Sócio(a)" },

              { label: "Educação", value: "Educação", text: "Educação" },
              { label: "Financeiro", value: "Financeiro", text: "Financeiro" },
              { label: "Governo", value: "Governo", text: "Governo" },

              { label: "Jurídico", value: "Jurídico", text: "Jurídico" },
              { label: "Marketing", value: "Marketing", text: "Marketing" },
              { label: "Operacional/Produção", value: "Operacional/Produção", text: "Operacional/Produção" },

              { label: "Recursos humanos", value: "Recursos humanos", text: "Recursos humanos" },
              { label: "Segurança do Trabalho", value: "Segurança do Trabalho", text: "Segurança do Trabalho" },

              { label: "TI", value: "TI", text: "TI" },

              { label: "Outros", value: "Outros", text: "Outros" },


            ]}
          />

          <SelectSimple
            name="position"
            label="profissão"
            options={[

              { label: "Advogado(a)", value: "Advogado(a)", text: "Advogado(a)" },
              { label: "Analista/Consultor(a)", value: "Analista/Consultor(a)", text: "Analista/Consultor(a)" },
              { label: "Assistente/Auxiliar", value: "Assistente/Auxiliar", text: "Assistente/Auxiliar" },

              { label: "Auditor(a)/Fiscal", value: "Auditor(a)/Fiscal", text: "Auditor(a)/Fiscal" },

              { label: "Dentista", value: "Dentista", text: "Dentista" },

              { label: "Diretor(a)/Sócio(a)", value: "Diretor(a)/Sócio(a)", text: "Diretor(a)/Sócio(a)" },

              { label: "Enfermeiro(a)", value: "Enfermeiro(a)", text: "Enfermeiro(a)" },
              { label: "Engenheiro(a)", value: "Engenheiro(a)", text: "Engenheiro(a)" },
              { label: "Estagiário(a)", value: "Estagiário(a)", text: "Estagiário(a)" },

              { label: "Estudante", value: "Estudante", text: "Estudante" },

              { label: "Farmacêutico(a)", value: "Farmacêutico(a)", text: "Farmacêutico(a)" },
              { label: "Fisioterapeuta", value: "Fisioterapeuta", text: "Fisioterapeuta" },
              { label: "Fonoaudiólogo(a)", value: "Fonoaudiólogo(a)", text: "Fonoaudiólogo(a)" },


              { label: "Gestor(a)/Gerente", value: "Gestor(a)/Gerente", text: "Gestor(a)/Gerente" },

              { label: "Jornalista", value: "Jornalista", text: "Jornalista" },
              { label: "Médico(a)", value: "Médico(a)", text: "Médico(a)" },
              { label: "Nutricionista", value: "Nutricionista", text: "Nutricionista" },
              { label: "Professor(a)/Pedagogo(a)", value: "Professor(a)/Pedagogo(a)", text: "Professor(a)/Pedagogo(a)" },

              { label: "Psicólogo(a)", value: "Psicólogo(a)", text: "Psicólogo(a)" },

              { label: "Servidor(a) Público(a)", value: "Servidor(a) Público(a)", text: "Servidor(a) Público(a)" },
              { label: "Supervisor(a)/Especialista", value: "Supervisor(a)/Especialista", text: "Supervisor(a)/Especialista" },

              { label: "Técnico(a) Operacional", value: "Técnico(a) Operacional", text: "Técnico(a) Operacional" },

              { label: "Vendedor(a)", value: "Vendedor(a)", text: "Vendedor(a)" },



              { label: "Outros", value: "Outros", text: "Outros" },


            ]}
          />






          <SelectSimple setCallback={(value) => setCountryISO(value)} options={countries} name="country" label={'Pais'} />

          {countryISO === 'BR' ? <SelectSimple setCallback={(value) => {
            setStateValue(value);
          }} options={states} name="state" label="Estado" /> :

            <Input name="state" placeholder={'Província / Região'} />}








          {product && product?.fields && product?.fields.length > 0 ? <aside style={{ display: 'flex', width: '100%', justifyContent: 'center', alignContent: 'center', flexDirection: 'column', margin: '30px 0px', border: '2px 0px #ddd' }}> <h2 style={{ fontSize: '12px', color: '#333', margin: '10px 0px' }}>Dados específicos do evento</h2>  <CompleteSaleFields fields={product.fields || []} /></aside> : <> </>}


          {countryISO === 'BR' ? <MaskedInput
            setCallback={(value) => verifyCNPJ(value)}
            mask="99.999.999/9999-99"
            name="company_document_number"
            placeholder={`${translate('CNPJ')}`}
          /> :
            <Input name="company_document_number"
              placeholder={`${translate('Nº de identificação')}`} />}

          {info?.nome ? <div style={{
            width: '100%', background: '#F1F1F1', color: '#333', display: 'flex', flexDirection: 'column',
            alignItems: 'flex-start', justifyContent: 'flex-start', padding: '15px'
          }}>
            <p style={{ fontSize: '12px', color: '#333' }}>Razão social:</p>
            <p style={{ fontWeight: 'bold', textAlign: 'left', fontSize: '12px' }} className="color2">{info?.nome}</p>
          </div> : <></>}

          {info?.fantasia ? <div style={{
            marginTop: '10px',
            width: '100%', background: '#F1F1F1', color: '#333', display: 'flex', flexDirection: 'column',
            alignItems: 'flex-start', justifyContent: 'flex-start', padding: '15px'
          }}>
            <p style={{ fontSize: '12px', color: '#333' }}>Nome fantasia:</p>
            <p style={{ fontWeight: 'bold', textAlign: 'left', fontSize: '12px' }} className="color2">{info?.fantasia}</p>
          </div> : <></>}

          {info?.uf_empresa ? <div style={{
            marginTop: '10px',
            width: '100%', background: '#F1F1F1', color: '#333', display: 'flex', flexDirection: 'column',
            alignItems: 'flex-start', justifyContent: 'flex-start', padding: '15px'
          }}>
            <p style={{ fontSize: '12px', color: '#333' }}>Cidade / Estado</p>
            <p style={{ fontWeight: 'bold', textAlign: 'left', fontSize: '12px' }} className="color2">{info?.municipio_empresa} / {info?.uf_empresa}</p>
          </div> : <></>}

          <div style={{ marginBottom: '10px', padding: '15px', borderRadius: '10px' }}>


            <p
              style={{
                padding: '10px',
                background: 'none',
                margin: '5px auto',
                color: '#333',
                fontSize: '12px',
                textAlign: 'left',
              }}
            >

              Fico ciente de que, ao realizar a inscrição no X Mostra de Ensino, Pesquisa e Extensão da UFCSPA, estarei autorizando ao SESI a utilizar meus dados pessoais para o cumprimento da finalidade da coleta, ou seja, viabilizar a participação no evento, conforme sua Política de Privacidade, disponível neste site, bem como para gestão de relacionamento, marketing e divulgação do X Mostra de Ensino, Pesquisa e Extensão da UFCSPA.
              <br /><br />
              Autorizo ainda o uso da minha imagem, captada durante a realização do evento, exclusivamente para a divulgação da repercussão o X Mostra de Ensino, Pesquisa e Extensão da UFCSPA, e campanhas promocionais da edição de 2025.  <br /><br />

              <br /><br />
              {'Ao me cadastrar, afirmo que li a'}
              {' '}
              <strong>
                <Link
                  style={{ display: 'inline', color: '#333' }}
                  to="/termos"
                  target="_BLANK"
                >
                  {'Política de Privacidade'}
                  {' '}
                </Link>
                {' '}
              </strong>
              {'desta plataforma.'}
            </p>
          </div>
          <aside className='full-width' style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <ReCAPTCHA ref={recaptchaRef} sitekey={recaptchaKey} />

          </aside>  <aside className='full-width' style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <button className="buttonBigGreen" type="submit">
              QUERO ME INSCREVER


            </button>
          </aside>
        </Form>


      </aside>
    </>
  );
};

export default FormContainer;
