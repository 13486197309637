import React, {
  InputHTMLAttributes,
  useEffect,
  useRef,
  useState,
  useCallback,
} from 'react';

import { useField, FormHandles } from '@unform/core';
import { IconBaseProps } from 'react-icons';
import { FiAlertCircle } from 'react-icons/fi';

import CKEditor from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { Container } from './styles';
import { Error } from '../styles';

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  name: string;
  icon?: React.ComponentType<IconBaseProps>;
  setCallback?: Function;
  formRef?: React.RefObject<FormHandles>;
  limit?: number;
}

const Input: React.FC<InputProps> = ({
  name,
  limit = 2200,
  icon: Icon,
  formRef,
  setCallback = () => { },
  ...rest
}) => {
  const { fieldName, defaultValue, error, registerField } = useField(name);
  const inputRef = useRef<HTMLInputElement>(null);
  const [isFocus, setIsFocus] = useState(false);
  const [isFilled, setIsFilled] = useState(!!defaultValue);

  const selectRef = useRef<Record<string, any>>();


  const [currentValue, setCurrentValue] = useState(defaultValue || '');

  useEffect(() => {
    if (defaultValue) {
      if (selectRef?.current?.props) {
        setCurrentValue(defaultValue);
      }
    }
  }, [defaultValue])

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: selectRef.current,
      path: undefined,

      getValue: (ref: any) => {
        return ref.props.data;
      },
      setValue: (ref: any, value) => {
        ref.props.data = value;
      },
    });
  }, [fieldName, registerField, currentValue]);

  const handleInputBlur = useCallback(() => {
    setIsFocus(false);
    setIsFilled(!!inputRef.current?.value);
  }, []);

  const handleInputFocus = useCallback(() => {
    setIsFocus(true);
  }, []);

  useEffect(() => {
    registerField({ name: fieldName, ref: inputRef.current, path: 'value' });
  }, [fieldName, registerField]);

  const cleanData = (string) => {

    if (!string) { return '0'; }

    let newString = string;

    const tagsAndNbspToReplace = /<\/?(p|i|strong|h2|h1|h3)>|&nbsp;/gi;
    const modifiedText = string.replace(tagsAndNbspToReplace, '');



    return modifiedText?.length;

  }

  return (<>
    <Container
      {...rest}
      isErrored={!!error}
      isFilled={isFilled}
      isFocused={isFocus}
    >
      <span>{rest.placeholder}</span>
      {Icon && <Icon />}
      <CKEditor
        ref={selectRef}
        editor={ClassicEditor}
        config={{
          toolbar: {
            items: ['bold', 'italic'], // Include only 'bold' and 'italic' options
          },
        }}
        data={currentValue}
        onInit={editor => { }}
        onChange={(event, editor) => {
          const data = editor.getData();

          setCurrentValue(data);
        }}
        onBlur={(event, editor) => {
          console.log('Blur.', editor);
        }}
        onFocus={(event, editor) => {
          console.log('Focus.', editor);
        }}
      />

      {error && (
        <Error title={error}>
          <FiAlertCircle />
        </Error>
      )}
    </Container>
    <p style={{ color: cleanData(currentValue) > limit ? 'red' : '#000' }}>{cleanData(currentValue) || '0'} / {limit} caracteres </p>
  </>
  );
};

export default Input;
