import React, { useCallback, useRef, useEffect, useState } from 'react';

import { IconType } from 'react-icons/lib';
import { Link, useParams } from 'react-router-dom';
import { FiChevronRight, FiChevronLeft } from 'react-icons/fi';
import api from '../../services/api';
import { date, hour, dateLanguage, simpleDate } from '../../utils/date';
import { Container } from './styles';
import { addHours } from 'date-fns';
import { useToast } from '../../hooks/Toast';
import { Form } from '@unform/web';

import { string } from 'yup';

import { useLanguage } from '../../hooks/Language';
import { FaArrowDown, FaArrowUp } from 'react-icons/fa';
import { MdKeyboardArrowDown, MdKeyboardArrowUp } from 'react-icons/md';
import Favorite from '../../components/ClassRoomContainer/modules/Favorite';
import { useAuth } from '../../hooks/Auth';
import { useConfiguration } from '../../hooks/Configuration';
import { defaultProjectHash, hash } from '../../config';
import { loadFromJson } from '../../utils/loadFromJson';

interface IOption {
  value: string;
  label: string;
}

interface IProgram {
  date: Date;
  formatted_date?: string;
  start_hour: Date;
  formatted_start_hour?: string;
  end_date: Date;
  formatted_end_date?: string;
  title: string;
  category: string;
  room_reference_id_response: Record<string, any>;
  description: string;
}

interface IProgramFilter {
  date: Date;
  formatted_date: string;
  program: Array<IProgram>;
  simpleDate: string;
  day_reference?: string;
  category?: string;
  room_name?: string;
}

interface IProps {
  filterCategory?: string;
  removeCategory?: string;
}
const AvaliatorSelection: React.FC<IProps> = () => {
  const { projectHash = defaultProjectHash } = useParams<Record<string, any>>();
  const [selectiveList, setSelectiveList] = useState<Array<Record<string, any>>>([]);

  const load = async () => {

    const projectReport = await api.get(`/list-submission-selective/${projectHash}`);

    if (projectReport?.data?.rows) {
      setSelectiveList(projectReport?.data?.rows);
    }

  }



  useEffect(() => {

    window.location.href = `/app/mostra-2024/cadastro-de-avaliador/x-mostra-ensino-pesquisa-e-extensao-ufcspa`;
    // load()
  }, [])

  return (


    <Container id="avaliador" style={{ background: '#fff' }}>
      <h1 style={{ color: '#333', fontSize: '24px' }}>{'Cadastro de avaliadores'} </h1>
      <p style={{ color: '#333', width: '100%', textAlign: 'center' }}>Selecione o projeto abaixo</p>


      <div style={{ width: '100%', marginTop: '30px' }}>
        <table className='table'>
          <tbody>
            {selectiveList?.length > 0 && selectiveList?.map(submissionItem => {
              return <tr key={submissionItem?._id?.toString()}>
                <td>
                  {submissionItem?.title}</td>

                <td><a href={`/app/${projectHash}/cadastro-de-avaliador/${submissionItem?.hash}`} style={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'center'
                }}><button className='defaultButtonMini'>Acessar</button></a></td>
              </tr>
            })}


          </tbody>
        </table>
      </div>



    </Container >
  );
};
export default AvaliatorSelection;
