import React from 'react';
import { FaEye, FaIntercom, FaRadiationAlt } from 'react-icons/fa';
import CopyToClipboard from '../../../../../core/components/CopyToClipboard';
import { urlWebsite } from '../../../../../../../config';
import { useSocket } from '../../../../../../../hooks/Socket';
import { useModal } from '../../../../../../../hooks/Modal';
import Review from '../../../modules/Review';
import api from '../../../../../../../services/api';

interface ICreateModule {
  lineData: Record<string, any>;
}

export default function ReviewModule({
  lineData,
}: ICreateModule): JSX.Element {

  const { addModal } = useModal();

  const openReview = async (line) => {

    const submission = await api.get(`/get-submission-selection-by-id/${line.project_id}/${line.cientific_selective_id_response?._id}`);
    const mypaper = await api.get(`/get-paper/${line._id?.toString()}`);

    addModal({
      theme: 'whiteModalMedium', title: 'Trabalho submetido', content: <>
        {lineData?.orientator_correction ? <p style={{ textAlign: 'center', marginTop: '30px', border: '1px solid #ddd', borderRadius: '5px', padding: '15px', color: '#333', width: '100%', maxWidth: '800px', marginBottom: '30px' }}><strong style={{ color: '#333' }}>Última correção solicitada:</strong><br />{lineData?.orientator_correction}</p> : <></>} <Review submission={submission?.data} data={mypaper?.data} /> </>
    });
  }


  const moduleCreate = (): JSX.Element => {

    return <div onClick={() => openReview(lineData)} style={{ cursor: 'pointer', width: '30px', height: '30px', display: 'flex', alignItems: 'center', justifyContent: 'center', borderRadius: '50%', border: '1px solid #333', margin: '5px' }}>
      <FaEye className="hover" style={{ cursor: 'pointer' }} size={20} /></div>;
  }



  return moduleCreate();
}
